import { useGenGridCol } from '../../../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/Table';

const useTableColumns = () => {
  const columns = [
    useGenGridCol(TableColumnKey.Month),
    useGenGridCol(TableColumnKey.ProgramName, { minWidth: 180 }),
    useGenGridCol(TableColumnKey.DistributorAgentId),
    useGenGridCol(TableColumnKey.PfhNo, { minWidth: 150 }),
    useGenGridCol(TableColumnKey.Currency),
    useGenGridCol(TableColumnKey.RebateTransactionAmount, { minWidth: 150 }),
    useGenGridCol(TableColumnKey.ProgramAgentRebateAmount, { minWidth: 150 }),
    useGenGridCol(TableColumnKey.RebateMode, { minWidth: 150 }),
    useGenGridCol(TableColumnKey.DistributorAgentRebateRate, { minWidth: 150 }),
    useGenGridCol(TableColumnKey.ClientRebateRate, { minWidth: 150 }),
    useGenGridCol(TableColumnKey.DistributorAgentRebateAmount, { minWidth: 150 }),
    useGenGridCol(TableColumnKey.ClientRebateAmount, { minWidth: 150 }),
    useGenGridCol(TableColumnKey.NetDistributorAgentRebateAmount, { minWidth: 150 }),
  ];

  return columns;
};

export default useTableColumns;
