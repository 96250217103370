import { ReactNode } from 'react';

import { Box } from '../../../components/MuiGenerals';
import { customSx } from '../../../utils/styling';
import { useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';

const FilterTableLayoutContainer = ({ children }: { children: ReactNode }) => {
  const isMobile = useAppSelector(selectIsMobileView);

  return (
    <Box
      sx={{
        ...customSx.layoutBox,
        ...(isMobile && { padding: '1rem' }),
      }}
    >
      {children}
    </Box>
  );
};

export default FilterTableLayoutContainer;
