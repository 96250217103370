import { Currency } from '../../../../common/types/Currency';
import { SystemCurrency } from '../../../../reducer/systemCurrencySlice';

export enum FilterFieldsKeys {
  createdDateFrom = 'createdDateFrom',
  createdDateTo = 'createdDateTo',
  lastModifiedTimeFrom = 'lastModifiedTimeFrom',
  lastModifiedTimeTo = 'lastModifiedTimeTo',

  OrderId = 'orderId',
  DistributorAgentId = 'distributorAgentId',
  Currency = 'currency',
}

export interface FilterFields {
  [FilterFieldsKeys.createdDateFrom]: string;
  [FilterFieldsKeys.createdDateTo]: string;
  [FilterFieldsKeys.lastModifiedTimeFrom]: string;
  [FilterFieldsKeys.lastModifiedTimeTo]: string;
  [FilterFieldsKeys.OrderId]: string;
  [FilterFieldsKeys.DistributorAgentId]: string;
  [FilterFieldsKeys.Currency]: SystemCurrency;
}
