import React from 'react';

import { Box } from '../../../components/MuiGenerals';
import { customSx } from '../../../utils/styling';

const FilterTopActionRow = ({ children }: { children: React.ReactNode }) => {
  return <Box sx={customSx.filterCmdBar}>{children} </Box>;
};

export default FilterTopActionRow;
