import React from 'react';
import backBtnIcon from '../../../../assets/icons/login-page-back-button.png';
import emailIconSrc from '../../../../assets/icons/login-page-email-icon.png';
import { PALETTE } from '../../../../style/colorTheme';

interface IProps {
  email: string;
  onBack: () => void;
}

const EmailBarWithBackBtn = (props: IProps) => {
  return (
    <div
      style={{
        width: '100%',
        height: '55px',
        padding: '0px 12px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '12px',
        background: PALETTE.Primary,
        boxSizing: 'border-box',
      }}
    >
      <div style={{ marginRight: '12px', width: '20px', height: '20px', cursor: 'pointer' }}>
        <img
          src={backBtnIcon}
          alt="back"
          onClick={props.onBack}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      <div style={{ marginRight: '12px', width: '18px', height: '18px' }}>
        <img src={emailIconSrc} alt="email" style={{ width: '100%', height: '100%' }} />
      </div>
      <div>{props.email}</div>
    </div>
  );
};

export default EmailBarWithBackBtn;
