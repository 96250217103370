import { CSSProperties } from 'react';

import { useAlerting, useTranslation } from '../hooks';
import { COLOR_THEME } from '../style/colorTheme';
import { copyToClipboard } from '../utils';
import { Box, Tooltip } from './MuiGenerals';

interface Iprops {
  text: string;
  sx?: CSSProperties;
  noToolTip?: boolean;
}

export default function CopyableText({ text, sx, noToolTip }: Iprops) {
  const { tc } = useTranslation();
  const { alerting } = useAlerting();
  const handleCopyText = () => {
    copyToClipboard(text);
    alerting('success', tc('copied'));
  };
  return (
    <Tooltip title={!noToolTip && text}>
      <Box
        sx={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          color: COLOR_THEME.CustomSx.ComponentHelper.AddressCell.Text,
          cursor: 'pointer',
          textDecoration: 'underline',
          ...sx,
        }}
        onClick={handleCopyText}
      >
        {text}
      </Box>
    </Tooltip>
  );
}
