import BigNumber from 'bignumber.js';

import {
  EnumDistributorAgentProgramStatus,
  EnumRebateMode,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

import { IdateFilter, Ipage } from '../../../../api/types';
import { useListMappingTransform, useTranslation } from '../../../../hooks';
import { isStrictNaN, toDisplayTime } from '../../../../utils';
import { initZusParams } from '../../../../utils/config';
import { createZusInstance } from '../../../../zustand/store';
import { filterTableTranslateKey as TK } from '../mappings/translate';
import { IapiGetAllRes } from '../types';

export interface IsearchParams extends IdateFilter {
  distributorAgentId: string;
  programName: string;
  rebateMode: string;
  status: string;
}

type IapiParams = Ipage & Partial<IsearchParams>;

const useZusParams = createZusInstance<IapiParams>(initZusParams);

const useListMapping = (mode: 'export' | 'table') => {
  const { t, tc } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);

  const listMapping = (array: IapiGetAllRes[], omitKeys: string[] = []) => {
    const res = array.map((item) => {
      const displayRebateRateToDistributorAgentAndClient = (daRebateRate: string) => {
        if (!daRebateRate) {
          return '';
        }
        const daRebateRateBigInt = new BigNumber(daRebateRate || 0);
        const result = daRebateRateBigInt.multipliedBy(100);

        if (isStrictNaN(result)) {
          return '';
        }
        return `${result.toString()}%`;
      };

      const displayPercentageToDistributorAgent = (
        daRebateRate: string,
        customerRebateRate: string
      ) => {
        if (!daRebateRate || !customerRebateRate) {
          return '';
        }

        // daRebateRate == Rate to client and da,
        // customerRebateRate == % in daRebateRate of client
        const daRebateRateBigInt = new BigNumber(daRebateRate || 0);
        const customerRebateRateBigInt = new BigNumber(customerRebateRate || 0);
        const daRate = new BigNumber(1).minus(customerRebateRateBigInt);
        const result = daRebateRateBigInt.multipliedBy(daRate).multipliedBy(100);

        const displayDaRebateRate = daRate.multipliedBy(100);

        if (isStrictNaN(result)) {
          return `${displayDaRebateRate}%`;
        }

        let formattedResult = result.toString();
        return `${formattedResult}% (${displayDaRebateRate}%)`;
      };

      const displayPercentageToClient = (daRebateRate: string, customerRebateRate: string) => {
        if (!daRebateRate || !customerRebateRate) {
          return '';
        }

        const daRebateRateBigInt = new BigNumber(daRebateRate || 0);
        const customerRebateRateBigInt = new BigNumber(customerRebateRate || 0);
        const result = daRebateRateBigInt.multipliedBy(customerRebateRateBigInt).multipliedBy(100);
        const displayCustomerRebateRate = customerRebateRateBigInt.multipliedBy(100);

        if (isStrictNaN(result)) {
          return `${displayCustomerRebateRate}%`;
        }
        const formattedResult = result.toString();
        return `${formattedResult}% (${displayCustomerRebateRate}%)`;
      };

      const mapping: Array<[string, IapiGetAllRes | string | number | null]> = [
        ['rawData', item],
        [TK.distributorAgentId, item.distributorAgentId],
        [TK.programDisplayName, item.agentProgram?.programDisplayName],
        [TK.programName, item.programName],
        [TK.rebateMode, t(EnumRebateMode[item.rebateMode])],
        [
          TK.rebateRateToDistributorAgentAndClient,
          displayRebateRateToDistributorAgentAndClient(item.daRebateRate),
        ],
        [
          TK.percentageToDistributorAgent,
          displayPercentageToDistributorAgent(item.daRebateRate, item.customerRebateRate),
        ],
        [
          TK.percentageToClient,
          displayPercentageToClient(item.daRebateRate, item.customerRebateRate),
        ],
        [TK.status, t(EnumDistributorAgentProgramStatus[item.status])],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedBy, item.lastModifiedBy],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ];

      const mappedResult = mapping.filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export { useZusParams, useListMapping };
