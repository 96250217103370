import { Currency } from '../../../../common/types/Currency';

export enum FilterFieldsKeys {
  createdDateFrom = 'createdDateFrom',
  createdDateTo = 'createdDateTo',
  ProgramName = 'programName',
  QuestionType = 'questionType',
  KycIdvMethod = 'kycIdvMethod',
}

export interface FilterFields {
  [FilterFieldsKeys.createdDateFrom]: string;
  [FilterFieldsKeys.createdDateTo]: string;
  [FilterFieldsKeys.ProgramName]: string;
  [FilterFieldsKeys.QuestionType]: string;
  [FilterFieldsKeys.KycIdvMethod]: string;
}
