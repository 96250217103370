import { TcspApplicationAnswer } from '../';
import Column from '../../../../../../../../common/approval/components/DetailView/Column';
import RowItem from '../../../../../../../../common/approval/components/DetailView/RowItem';
import Section from '../../../../../../../../common/approval/components/DetailView/Section';
import { useTranslation } from '../../../../../../../../hooks';

const DeclarationTermsAndConditions = ({ data }: { data: TcspApplicationAnswer }) => {
  const { t, tb } = useTranslation('tcspAccountApplication');

  const wishToEnableAdvancePayment = tb(!data.enableAdvancePayment);

  const wishToEnablePrePayment = tb(!data.enablePrePayment);

  return (
    <Section>
      <Column withDivider>
        <RowItem title={t('wishToEnableAdvancePayment')}>{wishToEnableAdvancePayment}</RowItem>
        <RowItem title={t('applicationSignature')}>
          {/* <div style={{ width: '260px', height: '160px', backgroundColor: 'white' }}></div> */}
          <img
            src={data.signature}
            style={{ width: '250px', backgroundColor: '#fff' }}
            alt="signature"
          />
        </RowItem>
      </Column>

      <Column>
        <RowItem title={t('wishToEnablePrePayment')}>{wishToEnablePrePayment}</RowItem>
        <RowItem title={t('signatureDate')}>{data.signatureDate}</RowItem>
      </Column>
    </Section>
  );
};

export default DeclarationTermsAndConditions;
