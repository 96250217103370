import React from 'react';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { EnumAgentUserStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

interface IProps {
  value: number;
  onChange: (e: React.ChangeEvent<any>) => void;
}

const StatusRadio = (props: IProps) => {
  const { value, onChange } = props;

  return (
    <>
      <RadioGroup row value={value} onChange={onChange}>
        {/* Enable */}
        <FormControlLabel value={1} control={<Radio />} label={EnumAgentUserStatus[1]} />
        {/* Disable */}
        <FormControlLabel value={0} control={<Radio />} label={EnumAgentUserStatus[0]} />
      </RadioGroup>
    </>
  );
};

export default StatusRadio;
