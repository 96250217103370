import React from "react";

import { Box } from "../../../MuiGenerals";
import { useTranslation } from "../../../../hooks";

const NoRecordContent = () => {
    const { tc } = useTranslation()
    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
        }}>
            <span style={{
                fontSize: "1.5rem",
            }}>{tc("noRecord")}</span>
        </Box>
    );
}

export default NoRecordContent;
