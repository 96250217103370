import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import APIs from '../../../api';
import { ApiResult } from '../../../api/types';
import Table from '../../../common/filterTable/components/Table';
import FilterContainer from '../../../common/filterTable/layout/FilterContainer';
import FilterTableLayoutContainer from '../../../common/filterTable/layout/FilterTableLayoutContainer';
import TableContainer from '../../../common/filterTable/layout/TableContainer';
import useNewFilterTablePage from '../../../common/page/hooks/useNewFilterTablePage';
import { Currency } from '../../../common/types/Currency';
import { ExportBtn } from '../../../components/Button';
import { useDatePicker } from '../../../components/DatePicker';
import { DivideLine } from '../../../components/Layout';
import { Iprefix as IPrefix } from '../../../hooks/useTranslation';
import FilterSection from './components/FilterSection';
import useGetListParams from './helpers/useGetListParams';
import useTableColumns from './helpers/useTableColumns';
import useTableRows from './helpers/useTableRows';
import { FilterFields, FilterFieldsKeys } from './types/FilterFields';
import { GetListRes } from './types/GetListRes';

const initFields = {
    [FilterFieldsKeys.CreationTimeFrom]: '',
    [FilterFieldsKeys.CreationTimeTo]: '',
    [FilterFieldsKeys.MonthFrom]: '',
    [FilterFieldsKeys.MonthTo]: '',
    [FilterFieldsKeys.TaskId]: '',
    [FilterFieldsKeys.OrderId]: '',
    [FilterFieldsKeys.From]: '',
    [FilterFieldsKeys.To]: '',
    [FilterFieldsKeys.ProgramName]: '',
    [FilterFieldsKeys.Status]: '',
};

const translatePrefix = 'rebateRecord' as IPrefix;
const getTableDataApiFunc = APIs.rebate.rebateRecord.getAll;
const exportTableApiFunc = APIs.rebate.rebateRecord.getExport;

const exportBtnPermission =
    AgentPortalFeatureCode.Rebate.RebateRecord.Export;

const CustodyWallet = () => {
    const { getListParams } = useGetListParams();

    const DateObj = {
        CreationTime: useDatePicker(),
        LastModifiedTime: useDatePicker(),
    };

    const pageConfig = {
        initFields,
        DateObj,
        translatePrefix,
        getListParams,
        getTableDataApiFunc,
        exportTableApiFunc,
    };

    const {
        page,
        onPageChange,
        fields,
        setFields,
        list,
        onFilterSearch,
        onFilterReset,
        onExport,
        getIsHavePermission,
        Tabs,
    } = useNewFilterTablePage<FilterFields, ApiResult<GetListRes>>(pageConfig);

    const { rows, totalCounts, convertToTableRows } = useTableRows({ apiList: list });
    const columns = useTableColumns();

    const isShowExportBtn = getIsHavePermission(exportBtnPermission);

    const handleExport = async () => {
        await onExport('Distributor Agent Wallet', totalCounts, convertToTableRows);
    };

    return (
        <>
            <FilterTableLayoutContainer>
                <FilterContainer>
                    <FilterSection
                        fields={fields}
                        setFields={setFields}
                        onSearch={onFilterSearch}
                        onReset={onFilterReset}
                        DateObj={DateObj}
                    />
                </FilterContainer>

                <DivideLine />

                <TableContainer>
                    <ExportBtn onExport={handleExport} isShow={isShowExportBtn} />
                    <Tabs>
                        <Table
                            columns={columns}
                            rows={rows}
                            rowCount={totalCounts}
                            page={page}
                            onPageChange={onPageChange}
                        />
                    </Tabs>
                </TableContainer>
            </FilterTableLayoutContainer>
        </>
    );
};

export default CustodyWallet;
