import { ValidationResult, ValidatorResponseWithKey } from '../types';

export const normalizeValidationResult = (result: ValidatorResponseWithKey[]) => {
  const validationResult = result.reduce((acc: ValidationResult, curr) => {
    acc[curr.key] = curr;
    return acc;
  }, {});

  return validationResult;
};
