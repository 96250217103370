import React, { useEffect } from 'react';

interface TabInfo {
  id: string;
  name: string;
}

interface IProps {
  tabsInfo?: TabInfo[];
  defaultActiveTabId?: string;
  onClickTab: (tabId: string) => void;
}

const Button = ({
  id,
  name,
  isActive,
  onClick,
}: {
  id?: string;
  name: string;
  isActive?: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '32px',
        height: '32px',
        color: '#FFFFFF',
        cursor: 'pointer',
        fontSize: '12px',
        background: isActive
          ? 'linear-gradient(144.39deg, #4F7CD8 7.57%, #63A3D5 53.85%, #7ED7D2 87.35%, #7ED7D2 95.73%)'
          : '#838DA4',
      }}
      onClick={onClick}
    >
      {name}
    </div>
  );
};

const ActiveTabPicker = (props: IProps) => {
  const { defaultActiveTabId, tabsInfo, onClickTab } = props;

  const [currentActiveTab, setCurrentActiveTab] = React.useState<string>('');

  const handleTabClick = (tabId: string) => {
    setCurrentActiveTab(tabId);
    onClickTab && onClickTab(tabId);
  };

  useEffect(() => {
    if (currentActiveTab) return;

    if (defaultActiveTabId) {
      setCurrentActiveTab(defaultActiveTabId);
    }

    if (!defaultActiveTabId && tabsInfo?.length) {
      setCurrentActiveTab(tabsInfo[0].id);
    }
  }, [currentActiveTab, tabsInfo, defaultActiveTabId]);

  return (
    <div style={{ display: 'flex', overflow: 'hidden', width: '100%', borderRadius: '8px' }}>
      {tabsInfo?.map((tabInfo) => (
        <Button
          key={tabInfo.id}
          id={tabInfo.id}
          name={tabInfo.name}
          isActive={currentActiveTab === tabInfo.id}
          onClick={() => handleTabClick(tabInfo.id)}
        />
      ))}
    </div>
  );
};

export default ActiveTabPicker;
