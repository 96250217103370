import { Box } from '../../../components/MuiGenerals';
import { useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';

export const Container = ({ children, sx }: { children: React.ReactNode; sx?: any }) => {
  return (
    <Box
      sx={{
        '.MuiInputLabel-shrink': {
          display: 'none',
        },
        '.MuiFormControl-root': {
          width: '100%',
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export const Row = ({ children, sx }: { children: React.ReactNode; sx?: any }) => {
  const isMobile = useAppSelector(selectIsMobileView);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        boxSizing: 'border-box',
        marginBottom: '24px',
        flexDirection: isMobile ? 'column' : 'row',
        gap: '8px',
        ...sx,
      }}
    >
      {children}
    </div>
  );
};

export const RowHeader = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useAppSelector(selectIsMobileView);

  return (
    <div
      style={{
        width: '160px',
        minWidth: '160px',
        marginRight: '32px',
        wordBreak: 'break-word',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        justifyContent: 'flex-start',
        fontSize: isMobile ? '12px' : '16px',
        color: '#E0E0E0',
      }}
    >
      {children}
    </div>
  );
};

export const RowContent = ({ children }: { children: React.ReactNode }) => {
  return <div style={{ fontWeight: 'bold', width: '100%' }}>{children}</div>;
};
