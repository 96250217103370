import React from 'react';
import Header from './Header';
import { useTranslation } from '../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../../../../../reducer/mediaSlice';

interface IProps {
  acceptorCountry: string;
  acquirerCurrency: string;
}

const HeaderRow = (props: IProps) => {
  const { acceptorCountry, acquirerCurrency } = props;

  const { t } = useTranslation('feeAdjustmentRate');
  const isMobile = useAppSelector(selectIsMobileView);

  return (
    <div style={{ display: ' flex', alignItems: 'center', padding: '8px 0' }}>
      <div style={{ marginRight: isMobile ? '12px' : '32px' }}>
        <Header title={t('cardAcceptorCountry')} value={acceptorCountry} />
      </div>
      <div>
        <Header title={t('acquirerCurrency')} value={acquirerCurrency} />
      </div>
    </div>
  );
};

export default HeaderRow;
