import { styled } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { CustomPagination, NoRowsOverlay } from '../../../../components/Layout';
import { dataGridDefaults } from '../../../../utils/constant';

interface TableProps {
  rows: any[];
  columns: GridColDef[];
  page: number;
  onPageChange: (page: number) => void;
  rowCount: number;
  customNoRowOverlay?: React.ComponentType<any>;
  respectHeaderLineBreaks?: boolean;
}

const DataGridWithLineBreak = styled(DataGrid)(
  () => `
  .MuiDataGrid-columnHeaderTitle {
    line-height: 1.3; 
    white-space: pre-line;
  }
  `
);

const Table = (props: TableProps) => {
  const { rows, columns, page, onPageChange, rowCount, customNoRowOverlay } = props;

  const DataGridComponent = props.respectHeaderLineBreaks ? DataGridWithLineBreak : DataGrid;

  return (
    <DataGridComponent
      {...dataGridDefaults}
      rows={rows}
      rowCount={rowCount}
      columns={columns}
      page={page}
      onPageChange={onPageChange}
      components={{
        NoRowsOverlay: customNoRowOverlay ? customNoRowOverlay : NoRowsOverlay,
        Footer: CustomPagination,
      }}
      componentsProps={{
        footer: { totalRecords: rowCount },
      }}
    />
  );
};

export default Table;
