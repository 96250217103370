import errorCode from '../../utils/errorCode.json';
import COUNTRY_CODE_LIST from './countryCodeList.json';

const json = {
  noPrefix: {}, // for notice no prefix set when use zustand translate prefix
  errorCode: errorCode.en,
  countryCodes: COUNTRY_CODE_LIST, // Reference: https://github.com/joonhocho/country-calling-code

  menu: {
    version: 'Version',
    homepage: 'Home Page',
    accountSetting: 'Account Setting',
    changePassword: 'Change Password',
    toggleFilterButtonCollapse: 'Collapse',
    toggleFilterButtonExpand: 'Expand',
    toggleBtnCollapse: 'Collapse',
    toggleBtnExpand: 'Expand',
    dashboard: 'Dashboard',

    // category
    setting: 'Setting',
    programAgentManagement: 'Program Agent Management',
    distributorAgentManagement: 'Distributor Agent Management',
    clientManagement: 'Client Management',
    reportAms: 'Report',
    rebate: 'Rebate',
    getWalletAddress: 'Get Wallet Address',
    // sub-category
    requestFund: 'Request Fund',
    exchangeRateAms: 'Exchange Rate',
    exchangeRateConfiguration: 'Exchange Rate Configuration',
    creditAllocation: 'Credit Allocation',
    userSetting: 'User Setting',
    programAgentDeposit: 'Program Agent Deposit',
    programAgentWallet: 'Program Agent Wallet',

    distributorAgentList: 'Distributor Agent List',
    distributorAgentWallet: 'Distributor Agent Wallet',
    distributorAgentProgram: 'Distributor Agent Program',
    custodyWallet: 'Custody Wallet',
    custodyRecord: 'Custody Record',
    creditSwapRecord: 'Credit Swap Record',
    creditAllocationRecord: 'Credit Allocation Record',
    roleSetting: 'Role Setting',

    clientApplication: 'Client Application',
    clientList: 'Client List',

    programAgentReportDetail: 'Program Agent Report (Detail)',
    distributorAgentReportDetail: 'Distributor Agent Report (Detail)',
    clientReportDetail: 'Client Report (Detail)',
    programAgentReport: 'Program Agent Report',
    distributorAgentReport: 'Distributor Agent Report',
    clientReport: 'Client Report',

    rebateDistributionTask: 'Rebate Distribution Task',
    rebateRecord: 'Rebate Record',
    monthlyRebateReport: 'Monthly Rebate Report',
    customerSpendingReport: 'Client Spending Report',
    feeAdjustmentRate: 'Fee Adjustment Rate',
    programList: 'Program List',
    programAgentRebateWallet: 'Program Agent Rebate Wallet',

    downloadAppButton: 'Download App',
  },

  common: {
    welcome: 'Welcome',
    logout: 'Logout',
    confirm: 'Confirm',
    edit: 'Edit',
    cancel: 'Cancel',
    start_date: 'Start Date',
    end_date: 'End Date',
    no_data_export: 'No data for export',
    phSelection: 'Please select %{fieldName}',
    phInputField: 'Please enter %{fieldName}',
    input_field_check_number: 'Please enter valid %{fieldName}',
    phSelectionOptional: 'Please select %{fieldName} (Optional)',
    phInputFieldOptional: 'Please enter %{fieldName} (Optional)',
    search: 'Search',
    reset: 'Reset',
    export: 'Export',
    table: 'Table',
    successCreate: 'Created %{target} Successfully',
    autoLogoutAlert:
      'Automatically logged out because another account is already logged in from same browser',
    logoutAlert: 'You have been logged out',
    alert_no_permission: 'No relevant permission',
    programName: 'Program Name',
    program_name: 'Program Name',
    question_type: 'Question Type',
    idv_method: 'IDV Method',
    customerId: 'Customer Id',
    status: 'Status',
    customerNumber: 'Customer Number',
    merchantId: 'Merchant Id',
    createdBy: 'Created By',
    creationDate: 'Created Date',
    lastModifiedBy: 'Last Modified By',
    lastModifiedTime: 'Last Modified Time',
    lastModifiedDate: 'Last Modified Date',
    latestVersion: 'Latest Version',
    applicationNumber: 'Application Number',
    tcspCustomerApplicationType: 'TCSP Customer Application Type',
    txHash: 'Transaction Hash',
    referenceNumber: 'Reference Number',
    currency: 'Currency',
    approvalProgressDialogTitle: 'Approval Progress',
    no_relevant_permission: 'No relevant permission',
    no_same_operator_creator: 'Operator and creator cannot be the same',
    no_same_approver: 'Approver and operator cannot be the same',
    request_approved: 'Request Approved',
    approve_successful: 'Approve Successful',
    request_rejected: 'Request Rejected',
    remarks: 'Remarks',
    confirm_btn: 'Confirm',
    approve_btn: 'Approve',
    reject_btn: 'Reject',
    approve_reject_request_btn: 'Approve/Reject',
    viewDetail: 'View Detail',
    approve_reject_dialog_title: 'Approve/Reject ',
    chainName: 'Chain Name',
    selectSearchLabel: 'Type to search',
    orderId: 'Order Id',
    transactionType: 'Transaction Type',
    amount: 'Amount',
    sendingAddress: 'Sending Address',
    receivingAddress: 'Receiving Address',
    creditAmount: 'Credit Amount',
    exchangeRate: 'Exchange Rate',
    transactionHash: 'Transaction Hash',
    creationTime: 'Creation Time',
    cancel_btn: 'Cancel',
    approveReject: 'Approve/Reject',
    applicationDetails: 'Application Details',
    close: 'Close',
    no_data_found: 'No Data in Database',
    auto: 'Auto',
    create_request: 'Create Request',
    not_allow_to_pick: 'Not allow to pick %{object}',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    create_request_success: 'Created request successfully',
    create_record_success: 'Created record successfully',
    copied: 'Copied!',
    clientId: 'Client Id',
    create_record: 'Create Record',
    adjustmentAmount: 'Adjustment Amount',
    ph_program_name_add_on: 'for viewing options in Asset',
    createRecord: 'Create Record',
    validate_below_zero: '%{fieldName} must be larger than 0',
    validate_zero: '%{fieldName} must not be 0',
    no_changes_made_warning: 'Please modify',
    partnerName: 'Partner Name',
    homeCurrency: 'Home Currency',
    adjustmentMode: 'Adjustment Mode',
    customer_number_not_found: 'Customer No. does not exist',
    successful_edit: 'Edited successfully',
    repaymentCurrency: 'Repayment Currency',
    customerLevel: 'Customer Level',
    rewardRate: 'Reward Rate',
    editRecord: 'Edit Record',
    edit_btn: 'Edit',
    request_record_created: 'Request record created',
    network: 'Network',
    yes: 'Yes',
    no: 'No',
    next: 'next',
    optional_field: '%{fieldName} (Optional)',
    //
    ledgerClientId: 'Ledger Client Id',
    pfhNumber: 'PFH Number',
    lastLedgerTransactionId: 'Last Ledger Transaction Id',
    fromCurrency: 'From Currency',
    toCurrency: 'To Currency',
    distributorAgentId: 'Distributor Agent Id',
    agentType: 'Agent Type',
    agentRole: 'Agent Role',
    agentStatus: 'Agent Status',
    agentLoginEmail: 'Login Email',
    roles: 'Roles',
    noRecord: 'No Record',
    month: 'Month',
    transactionAmount: 'Transaction Amount',
    cannot_exceed_warning: '%{fieldName} cannot exceed %{value}',
    agentName: 'Name',

    rebateTaskStatus: 'Rebate Status',
    rebateStatus: 'Rebate Status',

    taskId: 'Task ID',

    to: 'To',
    from: 'From',
    rebateMode: 'Rebate Mode',
    download: 'Download',

    am: 'AM',
    pm: 'PM',
    programNameDisplay: 'Program Name (Display)',
    tokenNotFound: 'Token not found',
    display_records_limit: 'Display max. %{limit} records',

    programDisplayName: 'Program Display Name',
    questionType: 'Question Type',
    kycIdvMethod: 'IDV Method',
    programCurrency: 'Program Currency',
    assignedTo: 'Assigned To',
  },

  dashboard: {
    network: 'Network',
    qrCode: 'QR Code',
    walletAddress: 'Wallet Address',
    hkdmBalanceTitle: 'HKDM Balance of Program Agent',
    hkdm: 'HKDM',
    hkd: 'HKD',
    distributionOverview: 'Distribution Overview',
    totalNoOfDistributorAgent: 'Total No. of Distributor Agent',
    totalNoOfClient: 'Total No. of Client',
    totalNoOfActiveAgent: 'Total No. of Active Agent',
    totalNoOfActiveAgentSubtitle:
      'Display Total No. of Agent That Created At Least One Client Application Within 30 Days',
    totalNoOfActiveClient: 'Total No. of Active Client',
    totalNoOfActiveClientSubtitle:
      'Display Total No. of Client That Created At Least one Card Transaction Within 30 Days',
    programAgentOverview: 'Program Agent Overview',
    totalCustodyAssetTop5: 'Total Custody Asset (Top 5)',
    totalRebateAmount: 'Total Rebate Amount',
    totalPurchaseAmount: 'Total Purchase Amount',
    SevenDay: 'Last 7 Days',
    FourteenDay: 'Last 14 Days',
    ThirtyDay: 'Last 30 Days',
    assetOverview: 'Asset Overview',
    totalProgramNumber: 'Total No. of Program',
    viewDetails: 'View Details',
  },

  programAgentDeposit: {
    programName: 'Program Name',
    currency: 'Currency',
    qrCode: 'QR Code',
    walletAddress: 'Wallet Address',
  },

  login: {
    email: 'Email',
    password: 'Password',
    send: 'Send',
    resend: 'Resend',
    emailNotExistError: 'Email Address does not exist',
    programAgentId: 'Program Agent Id',
    enterEmail: {
      email: 'Email Address',
    },
    emailVerification: {
      verificationCode: 'Verification Code',
      enterVerificationCode: 'Enter Verification Code',
    },
    resetPassword: {
      successResetAlert: 'Successfully reset password',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      characterLimit: '8-40 characters',
      requiredUpperCase: 'At least 1 uppercase letter',
      requiredSymbol: 'At least 1 symbol',
      requiredNumber: 'At least 1 number',
    },
    enterPassword: {
      password: 'Password',
    },
    enterPasswordWithVerify: {
      trustDevice: 'Trust this device',
      verificationCodeCardTitle: 'Verifying Your Device',
      verificationCodeCardSubtitle: 'Click “Send” to send verification code to your email address',
    },
    forgotPassword: 'Forgot Password?',
    enterForgotPasswordEmail: {
      title: 'Forgot Password?',
      email: 'Email Address',
      enterVerifiedEmail: 'Please enter the registered email to receive email verification code',
      emailNotFound: 'Email Address does not exist',
    },
  },

  validation: {
    selectRequired: 'Please select %{fieldName}',
    inputRequired: 'Please enter %{fieldName}',
    shouldBeLargerThanZero: '%{fieldName} must be larger than 0',
    requiredLength: '%{fieldName} must be between %{min} to %{max} characters',
    requiredUpperCase: '%{fieldName} must contain at least %{min} uppercase letter',
    requiredSymbol: 'Password must contain at least 1 symbol',
    requiredContainNumber: '%{fieldName} must contain at least %{min} number',
    confirmPassword: 'Password and Confirm Password must be the same',
    loginEmailInvalid: 'Invalid email address format',
  },

  component: {
    typeToSearch: 'Type to Search',
    noRecords: 'No relevant record exists',
    itemTotalStart: '',
    itemTotalEnd: ' item(s) in total',
    clickSearchToViewRecords: 'Click search to view records',
    startMonth: 'Start Month',
    endMonth: 'End Month',
  },

  accountSetting: {
    email: 'Login Email',
    timezone: 'Time Zone',
    language: 'Language',
    password: 'Password',
    changePassword: 'Change Password',

    creationTime: 'Creation Time',
    programName: 'Program Name',
    programDisplayName: 'Program Display Name',
    kycIdvMethod: 'IDV Method',
    questionType: 'Question Type',
    programCurrency: 'Program Currency',
    assignedTo: 'Assigned To',
    lastModifiedTime: 'Last Modified Time',
  },
  changePassword: {
    changePassword: 'Change Password',
    confirm: 'Confirm',
    cancel: 'Cancel',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    confirmPassword: 'Confirm New Password',
    changedSuccess: 'Password changed',
    passwordRequirements:
      'Password must contain 8-40 characters, at least 1 uppercase letter, at least 1 symbol, and at least 1 number',
    characterLimit: '8-40 characters',
    requiredUpperCase: 'At least 1 uppercase letter',
    requiredSymbol: 'At least 1 symbol',
    requiredNumber: 'At least 1 number',
  },

  enumConstants: {
    en: 'English',
    zh_cn: '繁中',
    timezone0: '+0',
    timezone1: '+1',
    timezone2: '+2',
    timezone3: '+3',
    timezone4: '+4',
    timezone5: '+5',
    timezone6: '+6',
    timezone7: '+7',
    timezone8: '+8',
    timezone9: '+9',
    timezone10: '+10',
    timezone11: '+11',
    timezone12: '+12',
    timezone13: '-11',
    timezone14: '-10',
    timezone15: '-9',
    timezone16: '-8',
    timezone17: '-7',
    timezone18: '-6',
    timezone19: '-5',
    timezone20: '-4',
    timezone21: '-3',
    timezone22: '-2',
    timezone23: '-1',
    Rejected: 'Rejected',
    Pending: 'Pending',
    Approved: 'Approved',
    Executing: 'Executing',
    onHold: 'On Hold',
    ExecutedSuccess: 'Executed Success',
    ExecutedFailed: 'Executed Failed',
    CustomerWallet: 'Customer Wallet',
    MerchantWallet: 'Merchant Wallet',
    AdjustCredit: 'Adjust Credit',
    Repayment: 'Repayment',
    SetCredit: 'Set Credit',
    CreditIn: 'Credit In',
    DebitOut: 'Debit Out',
    Adjustment: 'Adjustment',
    SetValue: 'Set Value',
    MerchantTx: 'Merchant Transaction',
    CustomerTx: 'Customer Transaction',
    Auto: 'Auto',
    ByRequest: 'By Request',
    assigned: 'Assigned',
    created: 'Created',
    deleted: 'Deleted',
    Failed: 'Failed',
    Enabled: 'Enabled',
    Disabled: 'Disabled',
    Enable: 'Enabled',
    Disable: 'Disabled',
    Blacklisted: 'Blacklisted',
    yes: 'Yes',
    no: 'No',
    Yes: 'Yes',
    No: 'No',
    Mr: 'Mr',
    Mrs: 'Mrs',
    Ms: 'Ms',
    Male: 'Male',
    Female: 'Female',
    SelfOwned: 'Self-Owned',
    Mortgaged: 'Mortgaged - Monthly Mortgage Repayment',
    Rented: 'Rented - Monthly Rent',
    LivingWithRelative: 'Living with Relative',
    Others: 'Others',
    Work: 'For work',
    Expenses: 'For expenses',
    Investment: 'For investment',
    Insurance: 'For insurance payment',
    Single: 'Single',
    Married: 'Married',
    Divorced: 'Divorced',
    Widowed: 'Widowed',
    SeniorManagement: 'Senior Management',
    Vp: 'VP / AVP',
    Management: 'Management Grading',
    Officer: 'Officer Grading',
    Clerk: 'Clerk',
    Admin: 'Office Administration',
    SelfEmployed: 'Self-Employed',
    Fulltime: 'Full-Time Employed',
    Parttime: 'Part-Time Employed',
    Unemployed: 'Unemployed',
    Student: 'Student',
    Housewife: 'Housewife',
    Retired: 'Retired',
    University: 'University or above',
    College: 'Post-Secondary / College',
    Secondary: 'Secondary',
    IdCard: 'ID Card',
    Passport: 'Passport',
    Transport: 'Aerospace or Transport',
    Computer: 'Computer',
    It: 'IT or Telecommunication',
    AgricultureOrFisheries: 'Agriculture & Fisheries',
    Education: 'Education',
    Pharmaceutical: 'Pharmaceutical',
    FoodBeverage: 'Food & Beverage Restaurant',
    HealthCare: 'Health Care',
    Hospitality: 'Hospitality',
    Media: 'News & Media',
    Energy: 'Energy',
    Manufacturing: 'Manufacturing',
    Music: 'Music',
    Banking: 'Banking',
    Finance: 'Insurance or Finance',
    Electronics: 'Electronics',
    ProfessionalServices: 'Professional Services',
    Construction: 'Properties & Construction',
    GovEntities: 'Government Entities',
    Regulations: 'Regulations',
    Bars: 'Bars',
    SnackStore: 'Snack Stores',
    Jewelries: 'Jewelries Retails',
    Retails: 'Precious Metal Retails or Dealing',
    Beauty: 'Beauty Shop',
    Liquor: 'Liquor Stores',
    Cigarette: 'Cigarette Distribution',
    NonGovCharitable: 'Non-Government Charitable Organization',
    WetMarket: 'Wet Market',
    FlowerMarket: 'Flower Market',
    Market: 'Market',
    None: 'None',
    Retry: 'Retry',
    Success: 'Success',
    Onfido: 'Onfido',
    Manual: 'Manual',
    Skip: 'Skip',
    Skipped: 'Skipped',
    Registration: 'Registration',
    CreateAccount: 'Create Account',
    UpdateInfo: 'Update Info',
    UpdateEmailAddress: 'Update Email Address',
    UpdatePhoneNo: 'Update Phone No.',
    Submitted: 'Submitted',
    OnChainDeposit: 'On Chain Deposit',
    API: 'API',
    DeductBalance: 'Deduct Balance',
    DeductBalanceSuccess: 'Deduct Balance Success',
    CreateRepayment: 'Create Repayment',
    RepaymentSuccess: 'Repayment Success',
    ExcecuteedFailed: 'Excecuted Failed',
    Cancelled: 'Cancelled',
    Init: 'Initial',
    Excecuting: 'Excecuting',
    ExcecutedSuccess: 'Excecuted Success',
    FastConfirmedFail: 'Fast Confirmed Fail',
    ConfirmedFail: 'Confirmed Fail',
    Unconfirmed: 'Unconfirmed',
    ConfirmedSuccess: 'Confirmed Success',
    Invalid: 'Invalid',
    FastConfirmedSuccess: 'Fast Confirmed Success',
    NoData: '-2 No Data',
    NoAssessment: '-1 No Assessment',
    NoRiskDetected: '0 No Risk Detected',
    Info: '1 Info',
    Caution: '2 Caution',
    Classic: 'Classic',
    TCSP: 'TCSP',
    AssetCustody: 'AssetCustody',
    ManualReview: 'Manual Review',
    ManualSuccess: 'Manual Success',
    ByScheduler: 'By Scheduler',
    Reward: 'Reward',
    RejectedAndReleased: 'Rejected and Released',
    Percentage: 'Percentage',
    AbsoluteValue: 'Absolute Value',
    OverwriteValue: 'Overwrite Value',
    IdentityCard: 'Hong Kong Identity Card',
    DrivingLicence: 'Driving Licence',
    ShareCode: 'Share Code',
    VoterId: 'Voter ID',
    Ssn: 'SSN',
    SocialInsurance: 'Social Insurance',
    TaxId: 'Tax ID',
    InitialCredit: 'Initial Credit',
    UserRequest: 'User Request',
    Verified: 'Verified',
    repayment: 'Repayment',
    reward: 'Reward',
    Completed: 'Completed',
    InProcess: 'In Process',
    Unknown: 'Unknown',
    Critical: '5 Critical',
    High: '4 High',
    Medium: '3 Medium',
    SystemBlock: 'System Block',
    BackofficeBlock: 'Back Office Block',
    CustomerBlock: 'Customer Block',
    LostCard: 'Lost Card',
    blocked: 'Blocked',
    pending: 'Pending',
    released: 'Released',
    replaced: 'Replaced',
    reissued: 'Reissued',
    unknown: 'Unknown',
    Lost: 'Lost',
    Expired: 'Expired',
    Damaged: 'Damaged',
    Replace: 'Replace',
    suspended: 'Suspended',
    fraud: 'Fraud',
    Fraud: 'Fraud',
    lostStolen: 'Lost/Stolen',
    OperationFailed: 'Operation Failed',
    RequestFailed: 'Request Failed',
    Submitting: 'Submitting',
    Unassigned: 'Unassigned',
    loststolen: 'Lost/Stolen',
    Redeem: 'Redeem',
    Stucking: 'Stucking',
    IncreaseCreditSuccess: 'Increase Credit Success',
    QueryProgramCurrencyAndExhangeRate: 'Query Program Currency And Exchange Rate',
    RequestTransferOut: 'Request Transfer Out',
    TransferOutSuccess: 'Transfer Out Success',
    RequestIncreaseCredit: 'Request Increase Credit',
    enable: 'Enable',
    disable: 'Disable',
    PercentageByProfit: 'Percentage By Rebate',
    PercentageByTxAmount: 'Percentage By Transaction Amount',
    CreditSwapAutoAllocation: 'Auto Swap',
    ManualAllocation: 'Manual Allocate',
    PaAutoSwapAllocation: 'PA Auto Swap',
    ClientAutoSwap: 'Client Auto Swap',
    PaAutoSwap: 'PA Auto Swap',
    ExcutedSuccess: 'Excuted Success',
    CreateCustomer: 'Create Customer',
    SubmitCustomerApplication: 'Submit Customer Application',
    AssignCustomerNumber: 'Assign Customer Number',
    SubmitTcspApplication: 'Submit TCSP Application',
    UploadFrontDocument: 'Upload Document (Front)',
    UploadBackDocument: 'Upload Document (Back)',
    ExecuteFailed: 'Executed Failed',
    Allocation: 'Allocation',
    Deposit: 'Deposit',
    FreezeBalance: 'Freeze Balance',
    Rebate: 'Rebate',
    SwapCredit: 'Swap Credit',
    Transfer: 'Transfer',
    TransferFee: 'Transfer Fee',
    UnFreezeBalance: 'Unfreeze Balance',
    Withdraw: 'Withdraw',
    WithdrawFee: 'Withdraw Fee',
    WithdrawFeeRefund: 'Withdraw Fee Refund',
    WithdrawRefund: 'Withdraw Refund',
    Other: 'Other',
  },

  EnumCreditAllocationStatus: {
    ExecuteFailed: 'Executed Failed',
    Rejected: 'Rejected',
    Pending: 'Pending',
    Approved: 'Approved',
    Executing: 'Executing',
    ExecutedSuccess: 'Executed Success',
    Stucking: 'Stucking',
  },
  EnumRebateTaskStatus: {
    Failed: 'Failed',
    Rejected: 'Rejected',
    InsufficientFund: 'Insufficient Fund',
    ReportGenerating: 'Report Generating',
    ReportReady: 'Report Ready',
    Approved: 'Approved',
    Init: 'Initial',
    Executing: 'Executing',
    Completed: 'Completed',
    Stucking: 'Stucking',
  },
  EnumRebateStep: {
    Start: 'Start',
    GeneratingCustomerMonthlyRebate: 'Generating Customer Monthly Rebate',
    GeneratingDaMonthlyRebate: 'Generating DA Monthly Rebate',
    GeneratingRebates: 'Generating Rebates',
    Completed: 'Completed',
  },
  EnumRebateStatus: {
    Init: 'Initial',
    Executing: 'Executing',
    Completed: 'Completed',
    Stucking: 'Stucking',
    Skipped: 'Skipped',
  },
  EnumRebateType: {
    FreezeRebateFund: 'Freeze Rebate Fund',
    DaRebate: 'Distributor Agent Rebate',
    CustomerRebate: 'Customer Rebate',
  },
  EnumAgentType: {
    ProgramAgent: 'Program Agent',
    DistributorAgent: 'Distributor Agent',
    Unknown: 'Unknown',
  },
  EnumQuestionType: {
    Long: 'Retail',
    Short: 'Agent',
    Retail: 'Retail',
    Agent: 'Agent',
  },
  EnumIdvMethod: {
    Onfido: 'Onfido',
    Manual: 'Manual',
    Skip: 'Skip',
  },

  EnumRebateMode: {
    PercentageByTxAmount: 'Percentage By Transaction Amount',
    PercentageByProfit: 'Percentage By Rebate',
  },
  custodyWallet: {
    balanceTab: {
      title: 'Balance',
      creationTime: 'Creation Time',
      lastModifiedTime: 'Last Modified Time',
      ledgerClientId: 'Ledger Client ID',
      clientId: 'ClientId',
      pfhNumber: 'PFH Number',
      currency: 'Currency',
      balance: 'Balance',
      frozenBalance: 'Frozen Balance',
      lastLedgerTransactionId: 'Last Ledger Transaction ID',
    },
    historyTab: {
      title: 'History',
      creation_time: 'Creation Time',
      last_modified_time: 'Last Modified Time',
      ledger_client_id: 'Ledger Client ID',
      pfh_number: 'PFH No.',
      currency: 'Currency',
      sending_address: 'Sending Address',
      receiving_address: 'Receiving Address',
      transaction_hash: 'Transaction Hash',
      reference_number: 'Reference No.',
      last_ledger_transaction_id: 'Last Ledger Transaction ID',
      amount: 'Amount',
      post_balance: 'Post Balance',
      frozen_balance: 'Frozen Balance',
      risk_level: 'Risk Level',
      transaction_status: 'Transaction Status',
      transaction_type: 'Transaction Type',
      sending_client_id: 'Sending Client ID',
      receiving_client_id: 'Receiving Client ID',
    },
  },

  programAgentWallet: {
    balanceTab: {
      title: 'Balance',
      history_button: 'History',
      operation: 'Operation',
      creationTime: 'Creation Time',
      lastModifiedTime: 'Last Modified Time',
      ledgerClientId: 'Ledger Client ID',
      clientId: 'ClientId',
      pfhNumber: 'PFH Number',
      currency: 'Currency',
      balance: 'Balance',
      frozenBalance: 'Frozen Balance',
      lastLedgerTransactionId: 'Last Ledger Transaction ID',
    },
    historyTab: {
      title: 'History',
      order_id: 'Order ID',
      order_type: 'Order Type',
      from: 'From',
      to: 'To',
      currency: 'Currency',
      transaction_amount: 'Transaction Amount',
      post_balance: 'Post Balance',
      ledger_transaction_id: 'Ledger Transaction ID',
      remarks: 'Remarks',
      creation_time: 'Creation Time',
    },
  },

  programAgentRebateWallet: {
    balanceTab: {
      title: 'Balance',
      history_button: 'History',
      operation: 'Operation',
      creationTime: 'Creation Time',
      lastModifiedTime: 'Last Modified Time',
      ledgerClientId: 'Ledger Client ID',
      clientId: 'ClientId',
      pfhNumber: 'PFH Number',
      currency: 'Currency',
      balance: 'Balance',
      frozenBalance: 'Frozen Balance',
      lastLedgerTransactionId: 'Last Ledger Transaction ID',
    },
    historyTab: {
      title: 'History',
      order_id: 'Order ID',
      order_type: 'Order Type',
      from: 'From',
      to: 'To',
      currency: 'Currency',
      transaction_amount: 'Transaction Amount',
      post_balance: 'Post Balance',
      ledger_transaction_id: 'Ledger Transaction ID',
      remarks: 'Remarks',
      creation_time: 'Creation Time',
    },
  },

  exchangeRate: {
    createExchangeRate: 'Create Exchange Rate',
    currentExchangeRate: 'Current Exchange Rate',
    nextExchangeRate: 'Next Exchange Rate',
    startFrom: 'Start From',
    infinity: 'Infinity',
    editSuccessAlert: 'Edited successfully',
    noChangeAlert: 'Please modify',
    fromCurrency: 'From Currency',
    toCurrency: 'To Currency',
    earlierThanApiStartFromError: 'The selected time must not be earlier than current time',
  },

  userSetting: {
    distributorAgentId: 'Distributor Agent Id',
    agentType: 'Agent Type',
    loginEmail: 'Login Email',
    role: 'Role',
    roles: 'Roles',
    status: 'Status',
    remarks: 'Remarks',
    createdBy: 'Created By',
    creationTime: 'Creation Time',
    lastModifiedBy: 'Last Modified By',
    lastModifiedTime: 'Last Modified Time',
    operations: 'Operations',
    createUser: 'Create User',
    createUserSuccessAlert: 'Created APP user successfully',
    edit: 'Edit',
    editUser: 'Edit User',
    editUserSuccessAlert: 'Edited successfully',
    operation: 'Operations',
    name: 'Name',
  },
  clientReport: {
    dailyReportTab: {
      title: 'Daily Report',
      settlementDate: 'Settlement Date',
      distributorAgentId: 'Distributor Agent Id',
      distributorAgentName: 'Distributor Agent Name',
      programName: 'Program Name (Display)',
      pfhNo: 'PFH No.',
      totalTransactionAmount: 'Total Transaction Amount (HKD)',
      repayment: 'Repayment (HKD)',
      payment: 'Payment (HKD)',
      paMarkup: 'PA Markup (HKD)',
      visaRebate: 'Visa Rebate (HKD)',
      paRebate: 'PA Rebate (HKD)',
      annualFee: 'Annual Fee (HKD)',
    },
    monthlyReportTab: {
      title: 'Monthly Report',
      month: 'Month',
      distributorAgentId: 'Distributor Agent Id',
      programName: 'Program Name (Display)',
      distributorAgentName: 'Distributor Agent Name',
      pfhNo: 'PFH No.',
      totalTransactionAmount: 'Total Transaction Amount (HKD)',
      payment: 'Payment (HKD)',
      repayment: 'Repayment (HKD)',
      paMarkup: 'PA Markup (HKD)',
      visaRebate: 'Visa Rebate (HKD)',
      paRebate: 'PA Rebate (HKD)',
      annualFee: 'Annual Fee (HKD)',
    },
  },
  clientReportDetail: {
    dailyReportTab: {
      title: 'Daily Report',
      settlementDate: 'Settlement Date',
      distributorAgentId: 'Distributor Agent Id',
      programName: 'Program Name',
      pfhNo: 'PFH No.',
      currency: 'Currency',
      purchase: 'Purchase',
      refund: 'Refund',
      accountFunding: 'Account Funding',
      repayment: 'Repayment',
      fundingAdjustment: 'Funding Adjustment',
      waivedLateCharge: 'Waived Late Charge',
      waivedInterest: 'Waived Interest',
      reward: 'Reward',
      withdraw: 'Withdraw',
      chargeback: 'Chargeback',
      transfer: 'Transfer',
      repaymentAdjustment: 'Repayment Adjustment',
      vipServiceFee: 'VIP Service Fee',
      withholding: 'Withholding',
      interest: 'Interest',
      cashback: 'Cashback',
      fee: 'Fee',
      feeAdjustment: 'Fee Adjustment',
      withdrawFeeAdjustment: 'Withdraw Fee Adjustment',
      agentMarkupWithdrawFeeAdjustment: 'Agent Markup Withdraw Fee Adjustment',
      overseaFeeAdjustment: 'Oversea Fee Adjustment',
      agentMarkupOverseaFeeAdjustment: 'Agent Markup Oversea Fee Adjustment',
      ukFeeAdjustment: 'UK Fee Adjustment',
      e6Fee: 'E6 Fee',
      annualFee: 'Annual Fee',
      fastFund: 'Fast Fund',
      loadReversal: 'Load Reversal',
      latePaymentFee: 'Late Payment Fee',
      others: 'Others',
      totalIn: 'Total IN',
      totalOut: 'Total OUT',
      totalRebateTransactionAmount: 'Total Rebate Transaction Amount',
      totalVisaFee: 'Total VISA Fee',
      totalSwapCost: 'Total Swap Cost',
      totalAgentRebate: 'Total Agent Rebate',
      totalSystemCost: 'Total System Cost',
      totalProfit: 'Total Profit',
    },
    monthlyReportTab: {
      title: 'Monthly Report',
      monthly: 'Month',
      month: 'Month',
      distributorAgentId: 'Distributor Agent Id',
      programName: 'Program Name',
      pfhNo: 'PFH No.',
      currency: 'Currency',
      purchase: 'Purchase',
      refund: 'Refund',
      accountFunding: 'Account Funding',
      repayment: 'Repayment',
      fundingAdjustment: 'Funding Adjustment',
      waivedLateCharge: 'Waived Late Charge',
      waivedInterest: 'Waived Interest',
      reward: 'Reward',
      withdraw: 'Withdraw',
      chargeback: 'Chargeback',
      transfer: 'Transfer',
      repaymentAdjustment: 'Repayment Adjustment',
      vipServiceFee: 'VIP Service Fee',
      withholding: 'Withholding',
      interest: 'Interest',
      cashback: 'Cashback',
      fee: 'Fee',
      feeAdjustment: 'Fee Adjustment',
      withdrawFeeAdjustment: 'Withdraw Fee Adjustment',
      agentMarkupWithdrawFeeAdjustment: 'Agent Markup Withdraw Fee Adjustment',
      overseaFeeAdjustment: 'Oversea Fee Adjustment',
      agentMarkupOverseaFeeAdjustment: 'Agent Markup Oversea Fee Adjustment',
      ukFeeAdjustment: 'UK Fee Adjustment',
      e6Fee: 'E6 Fee',
      annualFee: 'Annual Fee',
      fastFund: 'Fast Fund',
      loadReversal: 'Load Reversal',
      latePaymentFee: 'Late Payment Fee',
      others: 'Others',
      totalIn: 'Total IN',
      totalOut: 'Total OUT',
      totalRebateTransactionAmount: 'Total Rebate Transaction Amount',
      totalVisaFee: 'Total VISA Fee',
      totalSwapCost: 'Total Swap Cost',
      totalAgentRebate: 'Total Agent Rebate',
      totalSystemCost: 'Total System Cost',
      totalProfit: 'Total Profit',
    },
  },
  programAgentReportDetail: {
    dailyReportTab: {
      title: 'Daily Report',
      settlementDate: 'Settlement Date',
      distributorAgentId: 'Distributor Agent Id',
      programName: 'Program Name',
      pfhNo: 'PFH No.',
      currency: 'Currency',
      purchase: 'Purchase',
      refund: 'Refund',
      accountFunding: 'Account Funding',
      repayment: 'Repayment',
      fundingAdjustment: 'Funding Adjustment',
      waivedLateCharge: 'Waived Late Charge',
      waivedInterest: 'Waived Interest',
      reward: 'Reward',
      withdraw: 'Withdraw',
      chargeback: 'Chargeback',
      transfer: 'Transfer',
      repaymentAdjustment: 'Repayment Adjustment',
      vipServiceFee: 'VIP Service Fee',
      withholding: 'Withholding',
      interest: 'Interest',
      cashback: 'Cashback',
      fee: 'Fee',
      feeAdjustment: 'Fee Adjustment',
      withdrawFeeAdjustment: 'Withdraw Fee Adjustment',
      agentMarkupWithdrawFeeAdjustment: 'Agent Markup Withdraw Fee Adjustment',
      overseaFeeAdjustment: 'Oversea Fee Adjustment',
      agentMarkupOverseaFeeAdjustment: 'Agent Markup Oversea Fee Adjustment',
      ukFeeAdjustment: 'UK Fee Adjustment',
      e6Fee: 'E6 Fee',
      annualFee: 'Annual Fee',
      fastFund: 'Fast Fund',
      loadReversal: 'Load Reversal',
      latePaymentFee: 'Late Payment Fee',
      others: 'Others',
      totalIn: 'Total IN',
      totalOut: 'Total OUT',
      totalRebateTransactionAmount: 'Total Rebate Transaction Amount',
      totalVisaFee: 'Total VISA Fee',
      totalSwapCost: 'Total Swap Cost',
      totalAgentRebate: 'Total Agent Rebate',
      totalSystemCost: 'Total System Cost',
      totalProfit: 'Total Profit',
      totalRebateTxAmount: 'Total Rebate Transaction Amount',
    },
    monthlyReportTab: {
      title: 'Monthly Report',
      monthly: 'Month',
      month: 'Month',
      distributorAgentId: 'Distributor Agent Id',
      programName: 'Program Name',
      pfhNo: 'PFH No.',
      currency: 'Currency',
      purchase: 'Purchase',
      refund: 'Refund',
      accountFunding: 'Account Funding',
      repayment: 'Repayment',
      fundingAdjustment: 'Funding Adjustment',
      waivedLateCharge: 'Waived Late Charge',
      waivedInterest: 'Waived Interest',
      reward: 'Reward',
      withdraw: 'Withdraw',
      chargeback: 'Chargeback',
      transfer: 'Transfer',
      repaymentAdjustment: 'Repayment Adjustment',
      vipServiceFee: 'VIP Service Fee',
      withholding: 'Withholding',
      interest: 'Interest',
      cashback: 'Cashback',
      fee: 'Fee',
      feeAdjustment: 'Fee Adjustment',
      withdrawFeeAdjustment: 'Withdraw Fee Adjustment',
      agentMarkupWithdrawFeeAdjustment: 'Agent Markup Withdraw Fee Adjustment',
      overseaFeeAdjustment: 'Oversea Fee Adjustment',
      agentMarkupOverseaFeeAdjustment: 'Agent Markup Oversea Fee Adjustment',
      ukFeeAdjustment: 'UK Fee Adjustment',
      e6Fee: 'E6 Fee',
      annualFee: 'Annual Fee',
      fastFund: 'Fast Fund',
      loadReversal: 'Load Reversal',
      latePaymentFee: 'Late Payment Fee',
      others: 'Others',
      totalIn: 'Total IN',
      totalOut: 'Total OUT',
      totalRebateTransactionAmount: 'Total Rebate Transaction Amount',
      totalVisaFee: 'Total VISA Fee',
      totalSwapCost: 'Total Swap Cost',
      totalAgentRebate: 'Total Agent Rebate',
      totalSystemCost: 'Total System Cost',
      totalProfit: 'Total Profit',
    },
  },
  programAgentReport: {
    dailyReportTab: {
      title: 'Daily Report',
      settlementDate: 'Settlement Date',
      programName: 'Program Name (Display)',
      repayment: 'Repayment (HKD)',
      totalTransactionAmount: 'Total Transaction Amount (HKD) ',
      payment: 'Payment (HKD)',
      paMarkup: 'PA Markup (HKD)',
      annualFee: 'Annual Fee (HKD)',
      visaRebate: 'Visa Rebate (HKD)',
      paRebate: 'PA Rebate (HKD)',
      currency: 'Currency',
    },
    monthlyReportTab: {
      title: 'Monthly Report',
      month: 'Month',
      settlementDate: 'Settlement Date',
      programName: 'Program Name (Display)',
      repayment: 'Repayment (HKD)',
      totalTransactionAmount: 'Total Transaction Amount (HKD) ',
      payment: 'Payment (HKD)',
      paMarkup: 'PA Markup (HKD)',
      annualFee: 'Annual Fee (HKD)',
      visaRebate: 'Visa Rebate (HKD)',
      paRebate: 'PA Rebate',
      currency: 'Currency',
    },
  },
  distributorAgentReportDetail: {
    dailyReportTab: {
      title: 'Daily Report',
      settlementDate: 'Settlement Date',
      distributorAgentId: 'Distributor Agent Id',
      programName: 'Program Name',
      pfhNo: 'PFH No.',
      currency: 'Currency',
      purchase: 'Purchase',
      refund: 'Refund',
      accountFunding: 'Account Funding',
      repayment: 'Repayment',
      fundingAdjustment: 'Funding Adjustment',
      waivedLateCharge: 'Waived Late Charge',
      waivedInterest: 'Waived Interest',
      reward: 'Reward',
      withdraw: 'Withdraw',
      chargeback: 'Chargeback',
      transfer: 'Transfer',
      repaymentAdjustment: 'Repayment Adjustment',
      vipServiceFee: 'VIP Service Fee',
      withholding: 'Withholding',
      interest: 'Interest',
      cashback: 'Cashback',
      fee: 'Fee',
      feeAdjustment: 'Fee Adjustment',
      withdrawFeeAdjustment: 'Withdraw Fee Adjustment',
      agentMarkupWithdrawFeeAdjustment: 'Agent Markup Withdraw Fee Adjustment',
      overseaFeeAdjustment: 'Oversea Fee Adjustment',
      agentMarkupOverseaFeeAdjustment: 'Agent Markup Oversea Fee Adjustment',
      ukFeeAdjustment: 'UK Fee Adjustment',
      e6Fee: 'E6 Fee',
      annualFee: 'Annual Fee',
      fastFund: 'Fast Fund',
      loadReversal: 'Load Reversal',
      latePaymentFee: 'Late Payment Fee',
      others: 'Others',
      totalIn: 'Total IN',
      totalOut: 'Total OUT',
      totalRebateTransactionAmount: 'Total Rebate Transaction Amount',
      totalVisaFee: 'Total VISA Fee',
      totalSwapCost: 'Total Swap Cost',
      totalAgentRebate: 'Total Agent Rebate',
      totalSystemCost: 'Total System Cost',
      totalProfit: 'Total Profit',
    },
    monthlyReportTab: {
      title: 'Monthly Report',
      monthly: 'Month',
      month: 'Month',
      distributorAgentId: 'Distributor Agent Id',
      programName: 'Program Name',
      pfhNo: 'PFH No.',
      currency: 'Currency',
      purchase: 'Purchase',
      refund: 'Refund',
      accountFunding: 'Account Funding',
      repayment: 'Repayment',
      fundingAdjustment: 'Funding Adjustment',
      waivedLateCharge: 'Waived Late Charge',
      waivedInterest: 'Waived Interest',
      reward: 'Reward',
      withdraw: 'Withdraw',
      chargeback: 'Chargeback',
      transfer: 'Transfer',
      repaymentAdjustment: 'Repayment Adjustment',
      vipServiceFee: 'VIP Service Fee',
      withholding: 'Withholding',
      interest: 'Interest',
      cashback: 'Cashback',
      fee: 'Fee',
      feeAdjustment: 'Fee Adjustment',
      withdrawFeeAdjustment: 'Withdraw Fee Adjustment',
      agentMarkupWithdrawFeeAdjustment: 'Agent Markup Withdraw Fee Adjustment',
      overseaFeeAdjustment: 'Oversea Fee Adjustment',
      agentMarkupOverseaFeeAdjustment: 'Agent Markup Oversea Fee Adjustment',
      ukFeeAdjustment: 'UK Fee Adjustment',
      e6Fee: 'E6 Fee',
      annualFee: 'Annual Fee',
      fastFund: 'Fast Fund',
      loadReversal: 'Load Reversal',
      latePaymentFee: 'Late Payment Fee',
      others: 'Others',
      totalIn: 'Total IN',
      totalOut: 'Total OUT',
      totalRebateTransactionAmount: 'Total Rebate Transaction Amount',
      totalVisaFee: 'Total VISA Fee',
      totalSwapCost: 'Total Swap Cost',
      totalAgentRebate: 'Total Agent Rebate',
      totalSystemCost: 'Total System Cost',
      totalProfit: 'Total Profit',
    },
  },
  distributorAgentReport: {
    dailyReportTab: {
      title: 'Daily Report',
      settlementDate: 'Settlement Date',
      distributorAgentId: 'Distributor Agent Id',
      distributorAgentName: 'Distributor Agent Name',
      programName: 'Program Name (Display)',
      currency: 'Currency',
      repayment: 'Repayment',
      totalTxAmount: 'Total Transaction Amount',
      payment: 'Payment',
      paMarkup: 'PA Markup(HKD)',
      annualFee: 'Annual Fee(HKD)',
      visaRebate: 'VISA Rebate(HKD)',
      paRebate: 'PA Rebate(HKD)',
    },
    monthlyReportTab: {
      title: 'Monthly Report',
      monthly: 'Month',
      month: 'Month',
      distributorAgentId: 'Distributor Agent Id',
      distributorAgentName: 'Distributor Agent Name',
      programName: 'Program Name (Display)',
      currency: 'Currency',
      repayment: 'Repayment(HKD)',
      totalTxAmount: 'Total Transaction Amount(HKD)',
      payment: 'Payment(HKD)',
      paMarkup: 'PA Markup(HKD)',
      annualFee: 'Annual Fee(HKD)',
      visaRebate: 'VISA Rebate(HKD)',
      paRebate: 'PA Rebate(HKD)',
    },
  },
  monthlyRebateReport: {
    clientTab: {
      title: 'Client',
      // Filter
      month: 'Month',
      distributorAgentId: 'Distributor Agent ID',
      programName: 'Program Name',
      pfhNo: 'PFH No.',
      rebateMode: 'Rebate Mode',

      // Table
      currency: 'Currency',
      rebateTransactionAmount: 'Rebate Transaction Amount',
      programAgentRebateAmount: 'Program Agent Rebate Amount',
      distributorAgentRebateRate: 'Distribute Agent Rebate Rate',
      clientRebateRate: 'Client Rebate Rate',
      distributorAgentRebateAmount: 'Distributor Agent Rebate Amount',
      clientRebateAmount: 'Client Rebate Amount',
      netDistributorAgentRebateAmount: 'Net Distributor Agent Rebate Amount',
    },
    distributorAgentTab: {
      title: 'Distributor Agent',
      // Filter
      month: 'Month',
      distributorAgentId: 'Distributor Agent ID',
      programName: 'Program Name',
      rebateMode: 'Rebate Mode',

      // Table
      currency: 'Currency',
      distributorAgentRebateAmount: 'Distributor Agent Rebate Amount',
      clientRebateAmount: 'Client Rebate Amount',
      netDistributorAgentRebateAmount: 'Net Distributor Agent Rebate Amount',
    },
  },

  customerSpendingReport: {
    dailyReportTab: {
      title: 'Daily Report',
      month: 'Month',
      settlementDate: 'Settlement Date',

      distributorAgentId: 'Distributor Agent ID',
      distributorAgentName: 'Distributor Agent Name',
      pfhNumber: 'PFH No.',
      programName: 'Program Name (Display)',
      currency: 'Currency',
      repayment: 'Repayment',
      totalTransactionAmount: 'Total Transaction Amount',

      localPayment: 'Local Payment',
      localPaymentPfhCost: 'Local Payment\n(PFH Cost)',
      localPaymentPaMarkUp: 'Local Payment\n(PA Markup)',

      localWithdrawal: 'Local Withdraw',
      localWithdrawalPfhCost: 'Local Withdraw\n(PFH Cost)',
      localWithdrawalPaMarkup: 'Local Withdraw\n(PA Markup)',

      localRefund: 'Local Refund',
      localRefundPfhCost: 'Local Refund\n(PFH Cost)',
      localRefundPaMarkup: 'Local Refund\n(PA Markup)',

      overseasPayment: 'Overseas Payment',
      overseasPaymentPfhCost: 'Overseas Payment\n(PFH Cost)',
      overseasPaymentPaMarkUp: 'Overseas Payment\n(PA Markup)',

      overseasWithdraw: 'Overseas Withdraw',
      overseasWithdrawPfhCost: 'Overseas Withdraw\n(PFH Cost)',
      overseasWithdrawPaMarkup: 'Overseas Withdraw\n(PA Markup)',

      overseasRefund: 'Overseas Refund',
      overseasRefundPfhCost: 'Overseas Refund\n(PFH Cost)',
      overseasRefundPaMarkup: 'Overseas Refund\n(PA Markup)',

      totalPfhCost: 'Total PFH Cost',
      totalPaMarkup: 'Total PA Markup',

      annualFee: 'Annual Fee',
      visaRebate: 'Visa Rebate',
      paTotalRebate: 'PA Total Rebate',
    },
    monthlyReportTab: {
      title: 'Monthly Report',
      month: 'Month',
      settlementDate: 'Settlement Date',

      distributorAgentId: 'Distributor Agent ID',
      distributorAgentName: 'Distributor Agent Name',
      pfhNumber: 'PFH No.',
      programName: 'Program Name (Display)',
      currency: 'Currency',
      repayment: 'Repayment',
      totalTransactionAmount: 'Total Transaction Amount',

      localPayment: 'Local Payment',
      localPaymentPfhCost: 'Local Payment\n(PFH Cost)',
      localPaymentPaMarkUp: 'Local Payment\n(PA Markup)',

      localWithdrawal: 'Local Withdraw',
      localWithdrawalPfhCost: 'Local Withdraw\n(PFH Cost)',
      localWithdrawalPaMarkup: 'Local Withdraw\n(PA Markup)',

      localRefund: 'Local Refund',
      localRefundPfhCost: 'Local Refund\n(PFH Cost)',
      localRefundPaMarkup: 'Local Refund\n(PA Markup)',

      overseasPayment: 'Overseas Payment',
      overseasPaymentPfhCost: 'Overseas Payment\n(PFH Cost)',
      overseasPaymentPaMarkUp: 'Overseas Payment\n(PA Markup)',

      overseasWithdraw: 'Overseas Withdraw',
      overseasWithdrawPfhCost: 'Overseas Withdraw\n(PFH Cost)',
      overseasWithdrawPaMarkup: 'Overseas Withdraw\n(PA Markup)',

      overseasRefund: 'Overseas Refund',
      overseasRefundPfhCost: 'Overseas Refund\n(PFH Cost)',
      overseasRefundPaMarkup: 'Overseas Refund\n(PA Markup)',

      totalPfhCost: 'Total PFH Cost',
      totalPaMarkup: 'Total PA Markup',

      annualFee: 'Annual Fee',
      visaRebate: 'Visa Rebate',
      paTotalRebate: 'PA Total Rebate',
    },
  },

  distributorAgentList: {
    // commonTranlslateKey
    distributor_name: 'Distributor Name',
    distributor_agent_id: 'Distributor Agent ID',

    // createAgentDialogTranlslateKey
    create_agent_btn: 'Create Agent',
    created_agent_success: 'Created distributor agent successfully',
    create_agent_dulplicate_warning: 'Duplicated Distributor Agent ID',

    // assignProgramDialog
    assign_program_btn: 'Assign Program',
    selected_program: 'Selected Program',
    unassigned_program: 'Unassigned Program',
    assign_btn: 'Assign',
    confirm_assign_program_message:
      'Confirm to assign %{programName1}, %{programName2} to %{distributorAgentID} (%{distributorName})?',
    assign_program_success: 'Assigned successfully',

    // programNameAndEditDialogTranlslateKey
    edit_btn: 'Edit',
    rebate_mode: 'Rebate Mode',
    da_rebate_rate: 'DA Rebate Rate',
    client_rebate_rate: 'Client Rebate Rate',
    status: 'Status',

    // filterTableTranslateKey
    operation: 'Operation',
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
    program_name: 'Program Name',
    created_by: 'Created By',
    last_modified_by: 'Last Modified By',
  },

  custodyRecord: {
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
    ledger_client_id: 'Ledger Client ID',
    pfh_number: 'PFH No.',
    currency: 'Currency',
    sending_address: 'Sending Address',
    receiving_address: 'Receiving Address',
    transaction_hash: 'Transaction Hash',
    reference_number: 'Reference No.',
    last_ledger_transaction_id: 'Last Ledger Transaction ID',
    amount: 'Amount',
    post_balance: 'Post Balance',
    frozen_balance: 'Frozen Balance',
    risk_level: 'Risk Level',
    transaction_status: 'Transaction Status',
    transaction_type: 'Transaction Type',
    sending_client_id: 'Sending Client ID',
    receiving_client_id: 'Receiving Client ID',
  },

  creditSwapRecord: {
    creation_time: 'Creation Time',
    last_modified_by: 'Last Modified By',
    last_modified_time: 'Last Modified Time',
    pfh_number: 'PFH No.',
    asset: 'Asset',
    asset_amount: 'Asset Amount',
    status: 'Status',
    reference_number: 'Reference No.',
    exchange_rate: 'Exchange Rate',
    credit_currency: 'Credit Currency',
    credit_amount: 'Credit Amount',
    step: 'Step',
    source_transfer_order_id: 'Source Transfer Order ID',
    target_transfer_order_id: 'Target Transfer Order ID',
    created_by: 'Created By',
    operation: 'Operation',
    confirm_to_retry_the_record: 'Confirm to retry the record?',
    retry: 'Retry',
    retry_successfully: 'Retry Successfully',
    swap_type: 'Swap Type'
  },

  creditAllocationRecord: {
    operation: 'Operation',
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
    transfer_order_id: 'Transfer Order ID',
    allocation_type: 'Allocation Type',
    from: 'From',
    to: 'To',
    credit_currency: 'Credit Currency',
    credit_amount: 'Credit Amount',
    status: 'Status',
    created_by: 'Created By',
    last_modified_by: 'Last Modified By',
    retry: 'Retry',
    confirm_to_retry_the_record: 'Confirm to retry the record?',
    retry_successfully: 'Retry Successfully',
  },

  distributorAgentProgram: {
    // commonTranslateKey
    distributor_name: 'Distributor Name',
    distributor_agent_id: 'Distributor Agent ID',
    program_name: 'Program Name',
    program_display_name: 'Program Display Name',
    rebate_mode: 'Rebate Mode',
    rebate_rate_to_distributor_agent: 'Rebate Rate to Distributor Agent',
    rebate_rate_to_distributor_agent_and_client: 'Rebate Rate to Distributor Agent & Client',
    percentage_to_distributor_agent: 'Percentage of Distributor Agent',
    percentage_to_client: 'Percentage of Clients',
    status: 'Status',

    // assignProgramDialogTranslateKey
    assign_program_btn: 'Assign Program',
    assign_btn: 'Assign',
    assign_program_success: 'Assigned Successfully',

    // editDialogTranslateKey
    edit_btn: 'Edit',
    edit_success: 'Edited successfully',

    // filterTableTranslateKey
    operation: 'Operation',
    rebate_rate_to_client: 'Rebate Rate to Client',
    rebate: 'Rebate',
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
    created_by: 'Created By',
    last_modified_by: 'Last Modified By',
  },

  creditAllocation: {
    creationTime: 'Creation Time',
    lastModifiedTime: 'Last Modified Time',
    orderId: 'Order Id',
    approvalProgress: 'Approval Progress',
    distributorAgentId: 'Distributor Agent Id',
    currency: 'Currency',
    status: 'Status',
    remarks: 'Remarks',
    createdBy: 'Created By',
    createRequest: 'Create Request',
    createTransferRequestSuccessAlert: 'Created request successfully',
    transactionAmount: 'Transaction Amount',
    approval_sequence: 'Approval Sequence',
    permission: 'Permission',
    operation_result: 'Operation Result',
    operated_by: 'Operated By',
    operation_time: 'Operation Time',
    operations: 'Operations',
    availableBalance: 'Available Balance: %{balance}',
    insufficientAvailableBalance: 'Insufficient available balance',
  },

  clientApplication: {
    // commonTranslateKey
    distributor_name: 'Distributor Name',
    aml_details: 'AML Details',
    uploaded_documents: 'Uploaded Documents',

    // filterTableTranslateKey
    distributor_agent_id: 'Distributor Agent ID',
    program_name: 'Program Name',
    application_status: 'Application Status',
    referral_code: 'Referral Code',
    client_name: 'Client Name',
    email_address: 'Email Address',
    phone_country_code: 'Phone Country Code',
    phone_number: 'Phone No.',
    pfh_number: 'PFH No.',
    identification_document_type: 'Identification Document Type',
    identification_number: 'Identification No.',
    date_of_birth: 'Date of Birth',
    pmp_customer_id: 'PMP Customer ID',
    application_step: 'Application Step',
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
    created_by: 'Created By',
    last_modified_by: 'Last Modified By',

    // uploadedDocumentsDialogTranslateKey
    upload_time: 'Upload Time: %{time}',
  },
  clientList: {
    // commonTranslateKey
    details: 'Details',
    available_credit: 'Available Credit',
    max_credit_limit: 'Max Credit Limit',
    overall_credit_limit: 'Overall Credit Limit',
    total_balance: 'Total Balance',
    resident_address: 'Resident Address',
    first_name: 'First Name',
    last_name: 'Last Name',
    email_address: 'Email Address',
    phone_no: 'Phone No.',
    identification_document_type: 'Identification Document Type',
    identification_no: 'Identification No.',
    pfh_no: 'PFH No.',

    uploaded_document: 'Uploaded Document',

    address_line_1: 'Address Line 1',
    address_line_2: 'Address Line 2',
    address_line_3: 'Address Line 3',
    postal_code: 'Postal Code',

    delivery_address: 'Delivery Address',

    // filterTableTranslateKey
    distributor_agent_id: 'Distributor Agent ID',
    program_name: 'Program Name',
    status: 'Status',
    client_name: 'Client Name',
    phone_country_code: 'Phone Country Code',
    phone_number: 'Phone No.',
    pfh_number: 'PFH No.',
    pmp_customer_id: 'PMP Customer ID',
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
    created_by: 'Created By',
    last_modified_by: 'Last Modified By',
    detailsTab: {
      title: 'Details',
    },
    financialTab: {
      title: 'Financials',
      balances: 'Balances',
      totalBalance: 'Total Balance',
      creditLimit: 'Credit Limit',
      availableBalance: 'Available Balance',
      excessCreditOf: 'Excess Credit of',
      overLimitAllowance: 'Over Limit Allowance',
      enabled: 'Enabled',
      disabled: 'Disabled',
      maxCreditLimit: 'Max Credit Limit',
    },
  },

  rebateDistributionTask: {
    operations: 'Operations',
    creationTime: 'Creation Time',
    lastModifiedTime: 'Last Modified Time',
    currency: 'Currency',

    taskId: 'Task ID',
    month: 'Month',
    programName: 'Program Name',
    status: 'Status',
    rebateCurrency: 'Rebate Currency',
    rebateExchangeRate: 'Rebate Exchange Rate',
    totalDistributorAgentRebateAmount: 'Total Distributor Agent Rebate Amount',
    totalClientRebateAmount: 'Total Client Rebate Amount',
    netTotalDistributorAgentRebateAmount: 'Net Total Distributor Agent Rebate Amount',
    step: 'Step',
    approvalProgress: 'Approval Progress',
    approval_sequence: 'Approval Sequence',
    permission: 'Permission',
    operation_result: 'Operation Result',
    operated_by: 'Operated By',
    operation_time: 'Operation Time',
    remarks: 'Remarks',
    reject_success_alert: 'Rejected successfully',
    approve_success_alert: 'Approved successfully',
    same_approver_alert: 'The approvers must not be the same',
  },
  rebateRecord: {
    creationTime: 'Creation Time',
    month: 'Month',
    taskId: 'Task ID',
    orderId: 'Order ID',
    from: 'From',
    to: 'To',
    programName: 'Program Name',
    status: 'Status',
    sequence: 'Sequence',
    rebateType: 'Rebate Type',
    currency: 'Currency',
    amount: 'Amount',
    rebateCurrency: 'Rebate Currency',
    rebateExchangeRate: 'Rebate Exchange Rate',
    rebateAmount: 'Rebate Amount',
  },
  tcspAccountApplication: {
    merchantId: 'Merchant Id',
    customerId: 'Customer Id',
    programName: 'Program Name',
    customerNumber: 'Customer Number',
    status: 'Status',
    createdBy: 'Created By',
    creationDate: 'Creation Date',
    lastModifiedBy: 'Last Modified By',
    lastModifiedTime: 'Last Modified Time',
    lastModifiedDate: 'Last Modified Date',
    creationTime: 'Creation Time',
    latestVersion: 'Latest Version',
    tcspCustomerApplicationType: 'TCSP Customer Application Type',
    customerApplicationVersion: 'Customer Application Version',
    version: 'Version',
    isLatestVersion: 'Is Latest Version',
    questionVersion: 'Question Version',
    applicationNumber: 'Application Number',
    approvalProgress: 'Approval Progress',
    operations: 'Operations',
    // approval
    approval_sequence: 'Approval Sequence',
    permission: 'Permission',
    operation_result: 'Operation Result',
    operated_by: 'Operated By',
    operation_time: 'Operation Time',
    remarks: 'Remarks',
    // application details
    personalInfoTitle: ' Personal Information %{index}',
    title: 'Title',
    firstNameInEng: 'First Name in English',
    lastNameInEng: 'Last Name in English',
    chineseName: 'Chinese Name',
    aliasInEng: 'Alias in English',
    phoneNumber: 'Phone Number',
    email: 'Email',
    identificationDocumentType: 'Identification Document Type',
    identificationNumber: 'Identification Number',
    dateOfIssue: 'Date of Issue',
    dateOfExpiry: 'Date of Expiry',
    dateOfBirth: 'Date of Birth',
    usCitizenship: 'U.S. Citizenship',

    reasonForSettingUpTrustAcc: 'Reason for setting up a trust account in Hong Kong',
    personPurportingToActOn: 'Person purporting to act on behalf of you - PPTA',

    seniorPublicFigureDeclarationTitle: 'Senior Public Figure Declaration',
    seniorPublicFigureDeclaration:
      'I or my immediate family member currently hold(s) / has / have held a position as a senior public figure (individual who is working / has worked in any local or foreign government including judiciary / armed forces / embassies etc.)',
    noSeniorPublicFigureDeclaration:
      'I or my immediate family member currently does /do not hold / have not held any position as senior public figure.',
    member: 'Member %{index}',
    firstName: 'First Name',
    lastName: 'Last Name',
    countryOfGov: 'Country of Government',
    department: 'Department',
    position: 'Position',
    relationshipWithCustomer: 'Source and Origins of Funds to be used in the relationship (SoF)',

    levelOfActivityAnticipatedHeader: 'Level of Activity Anticipated',
    initialAndOngoingSOurceOfCustomerWealth:
      'Initial and Ongoing Sources of Customer’s Wealth or income(SoW)',
    sourceAndOriginOfFunds: ' Source and Origins of Funds to be used in the relationship (SoF)',
    totalVolumeOfAsset: 'Total Volume of Asset in HKD',
    totalVolumeOfAsset2: 'Total Volume of Asset in (HKD)*',
    levelOfActivityAnticipatedTitle: 'Level of Activity Anticipated',
    expectedTransactionAmount: 'Expected Transaction Amount Per Month',
    applicantSignature: 'Applicant’s Signature',
    declarationTermsAndConditions: 'Declaration, and Terms and Conditions',
    wishToEnableAdvancePayment: 'I do not wish to enable Advance Payment.',
    applicationSignature: "Applicant's Signature",
    wishToEnablePrePayment: 'I do not wish to enable Pre-Payment.',
    signatureDate: 'Signature Date',

    reasonForTrustAccount:
      'Placing certain type of assets as collateral for the purpose of PFH’s imagination credit card bills payment or increasing credit limit',

    others: 'Others',
    sourceOfWealth: {
      Others: 'Others',
      Employment: 'Employment',
      SelfEmployment: 'Self-Employment',
      BusinessOwnership: 'Business Ownership',
      LoansGrant: 'Loans/Grant',
      SpouseParentPartner: 'Spouse/Parent/Partner',
      Inheritance: 'Inheritance',
      TrustFund: 'Trust Fund',
      Investment: 'Investment',
      SalesOfVirtualAssets: 'Sales of Virtual Assets',
      PersonalRealEstate: 'Personal Real Estate',
    },

    sourceOfFunds: {
      Others: 'Others',
      BusinessIncome: 'Business Income',
      PersonalSaving: 'Personal Savings',
      Inheritance: 'Inheritance',
      RentalIncome: 'Rental Income',
      Investment: 'Investment',
      SalesOfProperty: 'Sales of Property',
      SalesOfVirtualAssets: 'Sales of Virtual Assets',
      Pension: 'Pension',
      WagesDepositedFromEmployer: 'Wages deposited from employer',
    },

    tcspTotalVolumeOfAsset: {
      Below50000: 'Below 50,000',
      '50000To99999': '50,000 - 99,999',
      '100000To199999': '100,000 - 199,999',
      '200000To499999': '200,000 - 499,999',
      '500000To999999': '500,000 - 999,999',
      '1000000OrAbove': '1,000,000 or Above',
    },

    tcspExpectedTxPerMonth: {
      '10OrBelow': '10 or Below',
      '11To20': '11 - 20',
      '21to30': '21 - 30',
      '31To40': '31 - 40',
      '41To50': '41 - 50',
      '51To60': '51 - 60',
      '61To70': '61 - 70',
      '71To80': '71 - 80',
      '81To90': '81 - 90',
      '91OrAbove': '91 or Above',
    },
    noPersonPurportingToActOn: 'No',
    existedTcspApplicationError: 'Application already existed',
  },

  TcspExpectedTxAmountPerMonth: {
    '10000OrBelow': '10,000 or Below',
    '10001To30000': '10,001 - 30,000',
    '30001To50000': '30,001 - 50,000',
    '50001To100000': '50,001 - 100,000',
    '100001To300000': '100,001 - 300,000',
    '300001To500000': '300,001 - 500,000',
    '500001OrAbove': '500,001 or Above',
  },

  distributorAgentWallet: {
    balanceTab: {
      title: 'Balance',
      history_button: 'History',
      operation: 'Operation',
      creationTime: 'Creation Time',
      lastModifiedTime: 'Last Modified Time',
      ledgerClientId: 'Ledger Client ID',
      clientId: 'ClientId',
      currency: 'Currency',
      balance: 'Balance',
      frozenBalance: 'Frozen Balance',
      lastLedgerTransactionId: 'Last Ledger Transaction ID',
      distributorAgentId: 'Distributor Agent ID',
    },
    historyTab: {
      title: 'History',
      order_id: 'Order ID',
      order_type: 'Order Type',
      from: 'From',
      to: 'To',
      distributor_agent: 'Distributor Agent',
      currency: 'Currency',
      transaction_amount: 'Transaction Amount',
      post_balance: 'Post Balance',
      ledger_transaction_id: 'Ledger Transaction ID',
      remarks: 'Remarks',
      creation_time: 'Creation Time',
    },
  },

  exchangeRateConfiguration: {
    operation: 'Operation',
    creationDate: 'Creation Date',
    creationTime: 'Creation Time',
    lastModifiedDate: 'Last Modified Date',
    programName: 'Program Name',
    from: 'From',
    to: 'To',
    currentExchangeRate: 'Current Exchange Rate',
    adjustmentType: 'Adjustment Type',
    adjustmentAmount: 'Adjustment Amount',
    exchangeRateAfterAdjustment: 'Exchange Rate After Adjustment',
    createdBy: 'Created By',
    lastModifiedBy: 'Last Modified By',
    lastModifiedTime: 'Last Modified Time',
    editDialog: {
      successAlert: 'Edited record successfully',
      noChangeAlert: 'Please modify ',
    },
    createRecordDialog: {
      successAlert: 'Created record successfully',
      duplicateAlert: 'The record already exists',
      negativeAdjustedExchangeRateAlert: 'The Exchange Rate After Adjustment must be larger than 0',
    },
  },

  feeAdjustmentRate: {
    selectProgram: 'Select Program',
    programName: 'Program Name',
    result: 'Result',

    transactionType: 'Transaction Type',
    purchase: 'Purchase',
    refund: 'Refund',
    withdraw: 'Withdraw',

    countryRegion: 'Country/Region',
    all: 'All',
    hk: 'HK',
    oversea: 'Overseas',
    gb: 'GB',

    currency: 'Currency',
    hkd: 'HKD',
    nonHkd: 'Non-HKD',

    cardAcceptorCountry: 'Acceptor Country/Region',
    acquirerCurrency: 'Acquirer Currency',

    localFee: 'Local Fee',
    overseasFee: 'Overseas Fee',
    gbFee: 'GB Fee',
    localAgentMarkup: 'Local Agent Markup',
    overseaAgentMarkup: 'Oversea Agent Markup',

    noRecords: 'No relevant record exists',
  },
  programList: {
    creationTime: 'Creation Time',
    programName: 'Program Name',
    programDisplayName: 'Program Display Name',
    questionType: 'Question Type',
    kycIdvMethod: 'IDV Method',
    programCurrency: 'Program Currency',
    assignedTo: 'Assigned To',
    lastModifiedTime: 'Last Modified Time',
    distributorAgentId: 'Distributor Agent ID',
    distributorAgentName: 'Distributor Agent Name',
  },
};

export default json;
