import { GridRenderCellParams } from '@mui/x-data-grid';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import APIs from '../../../../api';
import { ApiResult } from '../../../../api/types';
import { OpView } from '../../../../assets/icons';
import Table from '../../../../common/filterTable/components/Table';
import FilterTableLayoutContainer from '../../../../common/filterTable/layout/FilterTableLayoutContainer';
import TableContainer from '../../../../common/filterTable/layout/TableContainer';
import useNewFilterTablePage from '../../../../common/page/hooks/useNewFilterTablePage';
import { ExportBtn, OpIconButton } from '../../../../components/Button';
import { useDatePicker } from '../../../../components/DatePicker';
import useTranslation from '../../../../hooks/useTranslation';
import { getTabTranslatePrefix } from '../helper';
import useGetListParams from './helpers/useGetListParams';
import useTableColumns from './helpers/useTableColumns';
import useTableRows from './helpers/useTableRows';
import { FilterFields } from './types/FilterFields';
import { GetListRes } from './types/GetListRes';
import { TableColumnKey } from './types/Table';

const initFields = {};

const translatePrefix = getTabTranslatePrefix('balanceTab');
// TODO: CAMS-1116 change api call
const getTableDataApiFunc = APIs.programAgentManagement.programAgentRebateWallet.balanceTab.getAll;
const exportTableApiFunc =
  APIs.programAgentManagement.programAgentRebateWallet.balanceTab.getExport;

const exportBtnPermission =
  AgentPortalFeatureCode.ProgramAgentManagement.ProgramAgentRebateWallet.BalanceTab.Export;

const BalanceTab = ({
  redirectToHistoryTab,
}: {
  redirectToHistoryTab: (currency: string) => void;
}) => {
  const { getListParams } = useGetListParams();

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const pageConfig = {
    initFields,
    DateObj,
    translatePrefix,
    getListParams,
    getTableDataApiFunc,
    exportTableApiFunc,
  };

  const {
    page,
    onPageChange,
    fields,
    setFields,
    list,
    onFilterSearch,
    onFilterReset,
    onExport,
    getIsHavePermission,
    Tabs,
  } = useNewFilterTablePage<FilterFields, ApiResult<GetListRes>>(pageConfig);

  const { rows, totalCounts, convertToTableRows } = useTableRows({ apiList: list });
  const columns = useTableColumns({ renderOperationCell });
  const { t } = useTranslation(translatePrefix);

  function renderOperationCell(params: GridRenderCellParams) {
    const currency = params.row[TableColumnKey.Currency] as string;
    const handleClick = () => {
      redirectToHistoryTab(currency);
    };

    return <OpIconButton title={t('history_button')} svgUrl={OpView} onClick={handleClick} />;
  }

  const isShowExportBtn = getIsHavePermission(exportBtnPermission);

  const handleExport = async () => {
    await onExport('Program Agent Rebate Wallet Balance', totalCounts, convertToTableRows);
  };

  // useEffect(() => () => setForcedTabValue(undefined), []);

  return (
    <>
      <FilterTableLayoutContainer>
        {/* <FilterContainer>
          <FilterSection
            fields={fields}
            setFields={setFields}
            onSearch={onFilterSearch}
            onReset={onFilterReset}
            DateObj={DateObj}
          />
        </FilterContainer> */}

        {/* <DivideLine /> */}

        <TableContainer>
          <ExportBtn onExport={handleExport} isShow={isShowExportBtn} />
          <Tabs>
            <Table
              columns={columns}
              rows={rows}
              rowCount={totalCounts}
              page={page}
              onPageChange={onPageChange}
            />
          </Tabs>
        </TableContainer>
      </FilterTableLayoutContainer>
    </>
  );
};

export default BalanceTab;
