import React from 'react';
import { useTranslation } from '../../../../hooks';
import { Box, Button } from '../../../../components/MuiGenerals';
import { ExportBtn } from '../../../../components/Button';
import { COLOR_THEME } from '../../../../style/colorTheme';

interface IProps {
  title?: string;
  onClick: (e: any) => void;
  isShow?: boolean;
}

const CreateRecordButton = (props: IProps) => {
  const { title, onClick, isShow = true } = props;

  const { tc, t } = useTranslation('exchangeRate');

  if (!isShow) return <></>;

  return (
    <Box
      sx={{
        '& .MuiButton-root': {
          color: COLOR_THEME.CustomSx.DatagridContainer.ButtonText,
          borderColor: COLOR_THEME.CustomSx.DatagridContainer.ButtonBorder,
        },
      }}
    >
      <Button variant="outlined" onClick={onClick}>
        {title || t('createExchangeRate')}
      </Button>
    </Box>
  );
};

export default CreateRecordButton;
