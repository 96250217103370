import { ApiResult } from '../../../../../../api/types';
import { useTranslation } from '../../../../../../hooks';
import useSystemCurrency from '../../../../../../hooks/useSystemCurrency';
import { displayAmountCurrying, toDisplayTime } from '../../../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey, TableRow } from '../types/Table';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { te, tb, tc } = useTranslation();
  const { CurrencyDecimalsMapping } = useSystemCurrency();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      const decimals = CurrencyDecimalsMapping[row.currency] || 0;
      const displayAmount = displayAmountCurrying(decimals, 2);

      return {
        id: index,
        [TableColumnKey.Month]: row.month || '',
        [TableColumnKey.DistributorAgentId]: row.distributorAgentId || '',
        [TableColumnKey.ProgramName]: row.programName || '',
        [TableColumnKey.Currency]: row.currency || '',
        [TableColumnKey.DistributorAgentRebateAmount]: displayAmount(row.daNetRebateAmount) || '',
        [TableColumnKey.ClientRebateAmount]: displayAmount(row.customerRebateAmount) || '',
        [TableColumnKey.NetDistributorAgentRebateAmount]:
          displayAmount(row.daNetRebateAmount) || '',
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
