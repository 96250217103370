import { EnumMerchantExchangeRateAdjustType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { useTranslation } from '../../../../hooks';
import useValidation from '../../../../hooks/useValidation';
import { ValidationConfigProps, ValidationType } from '../../../../hooks/useValidation/types';
import { largerThanZeroValidator } from '../../../../utils/validators/largerThanZeroValidator';
import { requiredValidator } from '../../../../utils/validators/requiredValidator';
import { ConfigFieldsKeys, IConfigFields } from '../components/Dialog/EditConfigDialog/types';

interface IProps {
  fields: IConfigFields;
}

const useExchangeRateConfigEditRecordValidation = (props: IProps) => {
  const { fields } = props;

  const { t, translate } = useTranslation('exchangeRateConfiguration');

  const isEnableAdjustmentAmountLargerThanZeroChecking =
    Number(fields.adjustmentType) === EnumMerchantExchangeRateAdjustType.OverwriteValue ||
    Number(fields.adjustmentType) === EnumMerchantExchangeRateAdjustType.Percentage;

  const validationConfig: ValidationConfigProps = [
    {
      key: 'adjustmentType',
      value: fields[ConfigFieldsKeys.AdjustmentType],
      validators: [
        requiredValidator(
          translate('validation.selectRequired', {
            fieldName: t('adjustmentType'),
          })
        ),
      ],
    },
    {
      key: 'adjustmentAmount',
      value: fields[ConfigFieldsKeys.AdjustmentAmount],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('adjustmentAmount'),
          })
        ),
        largerThanZeroValidator(
          translate('validation.shouldBeLargerThanZero', {
            fieldName: t('adjustmentAmount'),
          }),
          isEnableAdjustmentAmountLargerThanZeroChecking
        ),
      ],
    },
  ];

  const validationTypeSortingKeys = [ValidationType.Required, ValidationType.LargerThanZero];

  return useValidation(validationConfig, validationTypeSortingKeys);
};

export default useExchangeRateConfigEditRecordValidation;
