import React, { useMemo } from 'react';

import DashboardPanel from '../../../../components/Dashboard/DashboardPanel';
import DashboardGrid from '../../../../components/Dashboard/DashboardGrid';
import TopItemsDoughnutChart from '../../../../components/Dashboard/DashboardContent/TopItemsDoughnutChart';
import { useTranslation } from '../../../../hooks';
import { ITotalCustodyAsset } from '../../../../api/types';
import { displayAmountCurrying } from '../../../../utils';
import { useAppSelector } from '../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../reducer/mediaSlice';

interface IAssetOverviewProps {
  data: ITotalCustodyAsset[] | null;
  isLoading: boolean;
}

const AssetOverview: React.FC<IAssetOverviewProps> = ({ data, isLoading }) => {
  const { t } = useTranslation('dashboard');

  const isMobile = useAppSelector(selectIsMobileView);

  const convertedData = data?.map((item) => {
    const temp = {
      name: item.name,
      value: displayAmountCurrying(0, 2)(parseFloat(item.value)),
      label: `≈ ${displayAmountCurrying(0, 2)(parseFloat(item.convertedValue))} ${t('hkdm')}`,
    };
    return temp;
  });

  const graphData = data?.map((item) => {
    const temp = {
      name: item.name,
      value: parseFloat(item.convertedValue),
    };
    return temp;
  });

  const graph = useMemo(
    () => (
      <TopItemsDoughnutChart
        data={graphData || []}
        legendData={convertedData || []}
        dataKey="value"
        width={isMobile ? 200 : 400}
        height={isMobile ? 200 : 300}
      />
    ),
    [data, isMobile]
  );

  return (
    <DashboardPanel title={t('assetOverview')} spacing={2}>
      <DashboardGrid
        height={isMobile ? undefined : 400}
        xs={12}
        title={t('totalCustodyAssetTop5')}
        isLoading={isLoading}
        isNoRecord={(!data || data.length === 0) && !isLoading}
        content={graph}
      />
    </DashboardPanel>
  );
};

export default AssetOverview;
