import { AgentCurrency } from '../../../../reducer/agentCurrencySlice';

export enum FilterFieldsKeys {
  programName = 'programName',
  from = 'from',
  adjustmentType = 'adjustmentType',
}

export interface FilterFields {
  [FilterFieldsKeys.programName]: string;
  [FilterFieldsKeys.from]: AgentCurrency | undefined;
  [FilterFieldsKeys.adjustmentType]: string[];
}
