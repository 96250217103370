import { SingleSelection } from '../../../../components';
import { SelectChangeEvent } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';
import { useGetProgramEnum } from '../../../../hooks/useProgram';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const AgentProgramDisplayNameSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc } = useTranslation();

  const { EnumAllProgramDisplayName } = useGetProgramEnum();

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('programNameDisplay') })}
      value={value}
      clearSelect={onClear}
      onChange={onChange}
      enumData={EnumAllProgramDisplayName}
      nameFn={(item) => item}
    />
  );
};

export default AgentProgramDisplayNameSingleSelection;
