import { SingleSelection } from '../../../../components';
import { SelectChangeEvent } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';

interface IProps {
    label?: string;
    value: any;
    onChange: (e: SelectChangeEvent) => void;
    onClear: () => void;
}

const ReportCurrencySingleSelection = (props: IProps) => {
    const { label, value, onChange, onClear } = props;

    const enumData: Record<string, any> = {
        "HKD": "HKD",
    };
    const displayValue = value

    const { tc } = useTranslation();
    return (
        <SingleSelection
            label={label || tc('phSelection', { fieldName: tc('currency') })}
            value={displayValue}
            clearSelect={onClear}
            onChange={onChange}
            enumData={enumData}
            nameFn={(item) => item}
        />
    );
};

export default ReportCurrencySingleSelection;
