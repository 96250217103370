import { defaultListParams } from '../../../../../../common/filterTable/constants/defaultListParams';
import { cleanParams } from '../../../../../../common/filterTable/helpers/cleanParams';
import { convertDateStringOrDateToYearMonth } from '../../../../../../utils/dateUtils';
import { FilterFields } from '../types/FilterFields';
import { GetListParams } from '../types/GetListParams';

const useGetListParams = () => {
  const getListParams = (fields: FilterFields) => {
    const { settlementMonthFrom, settlementMonthTo, distributorAgentId, pfhNumber, programName } =
      fields;

    const params: GetListParams = {
      ...defaultListParams,
      monthFrom: convertDateStringOrDateToYearMonth(settlementMonthFrom),
      monthTo: convertDateStringOrDateToYearMonth(settlementMonthTo),
      distributorAgentId,
      // pfhNumber,
      customerNumber: pfhNumber,
      programName,
    };

    return cleanParams(params);
  };

  return {
    getListParams,
  };
};

export default useGetListParams;
