import { useQuery } from 'react-query';

import { GridRowsProp } from '@mui/x-data-grid';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import APIs from '../../../../../api';
import TableTemplate from '../../../../../components/Layout/TableTemplate';
import { usePermission } from '../../../../../hooks';
import { useGenTableColumns } from '../../../../../hooks/useTable';
import useTranslation from '../../../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../../../zustand/store';
import { getTabTranslatePrefix } from '../../helper';
import { useListMapping, useZusParams } from '../hooks';
import { filterTableTranslateKey as TableTK } from '../mappings/translate';

const api = {
  table: APIs.distributorAgentManagement.distributorAgentWallet.historyTab.getAll,
  export: APIs.distributorAgentManagement.distributorAgentWallet.historyTab.getExport,
};
export default function Table() {
  const { hasPermission } = usePermission();
  const translatePrefix = getTabTranslatePrefix('historyTab');
  const exportFileName = 'Distributor Agent Wallet History';
  const exportPermissionKey =
    AgentPortalFeatureCode.DistributorAgentManagement.DistributorAgentWallet.HistoryTab.Export;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const { t, tc } = useTranslation(translatePrefix);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const tableMapping = useListMapping('table');
  const exportMapping = useListMapping('export');

  const omitKeys: string[] = [];

  const content: GridRowsProp = tableMapping(rows, omitKeys);

  const { columns } = useGenTableColumns([
    { translateKey: TableTK.orderId, minWidth: 150 },
    { translateKey: TableTK.orderType, minWidth: 150 },
    { translateKey: TableTK.from, minWidth: 150 },
    { translateKey: TableTK.to, minWidth: 150 },
    { translateKey: TableTK.currency },
    { translateKey: TableTK.transactionAmount, minWidth: 150 },
    { translateKey: TableTK.postBalance, minWidth: 150 },
    { translateKey: TableTK.ledgerTransactionId, minWidth: 150 },
    { translateKey: TableTK.remarks },
    { translateKey: TableTK.creationTime },
  ]);

  return (
    <TableTemplate
      exportBtn={{
        fileName: exportFileName,
        permissionKey: exportPermissionKey,
        apiFn: api.export,
        omitKeys: ['rawData'],
        mappingFn: exportMapping,
      }}
      zusParams={zusParams}
      count={count}
      content={content}
      columns={columns}
    />
  );
}
