import React, { ReactNode } from 'react';

import { Theme } from '@mui/system/createTheme';
import { SxProps } from '@mui/system/styleFunctionSx';

import { useTranslation } from '../../hooks';
import { COLOR_THEME } from '../../style/colorTheme';
import { useZusTranslatePrefixStore } from '../../zustand/store';
import GridBox from './GridBox';

export default function GridBoxSections(props: {
  sectionHeaderList: Array<{ headerValue: string; isSubtitle?: boolean; endAdornment?: ReactNode }>;
  labelElePairArrList: Array<Array<[string, JSX.Element]>>;
  columnCount?: number;
  containerSx?: SxProps<Theme>;
  dividingLineColor?: string;
  isAlignColumn?: boolean;
  subSectionList?: Array<{ mainSectionHeaderValue: string; GridBoxSubSections: JSX.Element }>;
}) {
  const {
    labelElePairArrList,
    sectionHeaderList,
    columnCount,
    containerSx,
    dividingLineColor,
    isAlignColumn,
    subSectionList,
  } = props;
  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t } = useTranslation(translatePrefix);

  return (
    // <Container disableGutters maxWidth={false} sx={{ marginBottom: "12px" }}>
    <>
      {sectionHeaderList.map(({ headerValue, isSubtitle, endAdornment }, i) => {
        const GridBoxSubSections = subSectionList?.find(
          ({ mainSectionHeaderValue }) => mainSectionHeaderValue === headerValue
        )?.GridBoxSubSections;
        return (
          <React.Fragment key={i}>
            {isSubtitle ? (
              <h5
                style={{
                  color: COLOR_THEME.Layout.GridBoxSection.Text,
                  marginBottom: '16px',
                  display: 'flex',
                }}
              >
                {t(headerValue)} {endAdornment}
              </h5>
            ) : (
              <h3 style={{ color: COLOR_THEME.Layout.GridBoxSection.Text, display: 'flex' }}>
                {t(headerValue)} {endAdornment}
              </h3>
            )}
            <GridBox
              labelElePairArr={labelElePairArrList[i]}
              columnCount={columnCount}
              containerSx={containerSx}
              dividingLineColor={dividingLineColor}
              isAlignColumn={isAlignColumn}
              subSection={GridBoxSubSections}
            />
          </React.Fragment>
        );
      })}
    </>
    // </Container>
  );
}
