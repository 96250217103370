import React from 'react';

import logo from '../../../../assets/logo/ams-logo.png';
import mainTitle from '../../../../assets/logo/login-page-main-title.png';
import subTitle from '../../../../assets/logo/login-page-sub-title.png';

const Logo = () => {
  return (
    <>
      <div className="main-logo-area">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="main-title">
          <img src={mainTitle} alt="main-title" />
        </div>
      </div>

      <div className="sub-logo-area">
        <div className="sub-title">
          <img src={subTitle} alt="sub-title" />
        </div>
      </div>
    </>
  );
};

export default Logo;
