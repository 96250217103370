import React from "react";
import { useTranslation } from "../hooks";

import MonthPicker from "./MonthPicker";
import { Box } from "./MuiGenerals";
import { customSx } from "../utils/styling";


interface IMonthRangePickerProps {
    startLabel?: string;
    endLabel?: string;
    defaultStartMonth?: any;
    defaultEndMonth?: any;
    startMonth?: any;
    endMonth?: any;
    onChangeStartMonth: (e: any) => void;
    onChangeEndMonth: (e: any) => void;
    minMonth?: any;
    maxMonth?: any;
}

const MonthRangePicker: React.FC<IMonthRangePickerProps> = ({
    startLabel,
    endLabel,
    startMonth,
    endMonth,
    onChangeStartMonth,
    onChangeEndMonth,
    defaultStartMonth,
    defaultEndMonth,
    minMonth,
    maxMonth
}) => {
    const { t } = useTranslation('component');
    return <>
        <Box sx={customSx.monthRangePicker.container}>
            <MonthPicker
                label={startLabel ? startLabel : t("startMonth")}
                value={startMonth}
                defaultMonth={defaultStartMonth}
                minMonth={minMonth}
                maxMonth={!startMonth ? maxMonth : endMonth < maxMonth ? new Date(endMonth) : maxMonth}
                onChange={(value) => {
                    onChangeStartMonth(value);
                }}
            />
            <span style={customSx.monthRangePicker.spanText}>-</span>
            <MonthPicker
                label={endLabel ? endLabel : t("endMonth")}
                value={endMonth}
                defaultMonth={defaultEndMonth}
                minMonth={startMonth > minMonth || !minMonth ? new Date(startMonth) : minMonth}
                maxMonth={maxMonth}
                onChange={(value) => {
                    onChangeEndMonth(value);
                }}
            />
        </Box>
    </>;
};

export default MonthRangePicker;
