import { useAppSelector } from '../reducer/hooks';
import { selectIsMobileView } from '../reducer/mediaSlice';
import { customSx } from '../utils/styling';

const useGridFilterCustomSx = () => {
  const isMobile = useAppSelector(selectIsMobileView);

  const gridFilterSx = {
    ...customSx.gridFilter,
    ...(isMobile
      ? {}
      : {
          gridTemplateColumns: 'minmax(max-content, 5rem) 3fr minmax(max-content, 5rem) 3fr',
        }),
  };

  return {
    gridFilterSx,
  };
};

export default useGridFilterCustomSx;
