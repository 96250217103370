export enum FilterFieldsKeys {
  createdDateFrom = 'createdDateFrom',
  createdDateTo = 'createdDateTo',
  monthFrom = 'monthFrom',
  monthTo = 'monthTo',

  taskId = 'taskId',
  programName = 'programName',
  status = 'status',
}

export interface FilterFields {
  [FilterFieldsKeys.createdDateFrom]: string;
  [FilterFieldsKeys.createdDateTo]: string;
  [FilterFieldsKeys.monthFrom]: string;
  [FilterFieldsKeys.monthTo]: string;
  [FilterFieldsKeys.taskId]: string;
  [FilterFieldsKeys.programName]: string;
  [FilterFieldsKeys.status]: string;
}
