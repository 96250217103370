import CancelButton from '../../../../../common/approval/components/CancelButton';
import { DialogInOne } from '../../../../../components';
import { IdialogInOneProps } from '../../../../../components/DialogInOne';
import { useTranslation } from '../../../../../hooks';
import { Iprefix } from '../../../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../../../zustand/store';
import { filterTableTranslateKey as TK } from '../../mappings/translate';
import { IapiGetAllRes } from '../../types';
import TcspApplicationDetail from './components/TcspApplicationDetail';

export default function AmlDetailsDialog() {
  const translatePrefix: Iprefix = 'clientApplication';

  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();

  const { tcspAnswers } = (zusDialog.meta as IapiGetAllRes | undefined) || {};

  const onClose = async () => {
    await zusDialog.close();
  };

  const dialogConfig: IdialogInOneProps = {
    title: t(TK.amlDetails),
    self: {
      open: zusDialog.match('amlDetailsDialog'),
      onClose: onClose,
    },
    content: <TcspApplicationDetail data={tcspAnswers} />,
    onConfirm: () => {},
    isConfirmHidden: true,
    onCancel: onClose,
    isFullScreen: true,
    actionButtons: (
      <div style={{ marginTop: '4px', marginBottom: '4px' }}>
        <CancelButton onClick={onClose} />
      </div>
    ),
    isCancelHidden: true,
  };

  return <DialogInOne {...dialogConfig} />;
}
