import { isNumber } from 'lodash';
import { useRef } from 'react';

import { useTranslation } from '../../hooks';
import { sortAlphabetically } from '../../utils';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '../MuiGenerals';

const translatePrefix = 'enumConstants';

export default function MultipleSelection(p: {
  label: string;
  value: string[];
  onChange: (arr: string[]) => void;
  enumData: Record<string, any>;
  enumDataSelectable?: number[];
  arrayFn?: (array: string[][]) => string[][];
  nameFn?: (str: string) => string;
  clearSelect?: () => void;
  isNoSorting?: boolean;
  disabled?: boolean;
}) {
  function isSelfFn<T>(arr: T): T {
    return arr;
  }
  const { t } = useTranslation(translatePrefix);
  const selectionRef = useRef<HTMLDivElement>(null);
  const defaultArrayFn = p.arrayFn ? p.arrayFn : isSelfFn;
  const blankFn = () => {};
  const clearSelect = p.clearSelect || blankFn;
  const enumObjEntries = Object.entries(p.enumData);
  const isValueNumber = enumObjEntries.some(([_key, value]) => isNumber(value));
  const displayEnumArr = isValueNumber
    ? enumObjEntries
        .filter(([_key, value]) => isNumber(value))
        .map(([key, value]) => [key, String(value)])
    : enumObjEntries;

  const selectionArray = defaultArrayFn(
    p.isNoSorting ? displayEnumArr : sortAlphabetically(displayEnumArr)
  );

  const onChange = (e: SelectChangeEvent<string[]>) => {
    const result = [...(Array.isArray(e.target.value) ? e.target.value : [e.target.value])];
    p.onChange(result);
  };
  return (
    <FormControl>
      <InputLabel>{p.label}</InputLabel>
      <Select
        ref={selectionRef}
        onClose={() => {
          const focusedDivElement = selectionRef.current?.querySelector('div') as HTMLDivElement;
          setTimeout(() => focusedDivElement.blur(), 150);
        }}
        name={p.label}
        value={p.value}
        onChange={onChange}
        multiple
        disabled={p.disabled}
      >
        {selectionArray.map(([name, value], i) => {
          return (
            <MenuItem
              key={i}
              value={value}
              onClick={clearSelect}
              disabled={
                p.enumDataSelectable ? !p.enumDataSelectable.includes(Number(value)) : undefined
              }
            >
              {p?.nameFn ? p.nameFn(name) : t(name)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
