import React, { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';

import APIs from '../../../../../../api';
import { useTranslation } from '../../../../../../hooks';
import { useAppSelector } from '../../../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../../../reducer/mediaSlice';
import { displayAmountCurrying } from '../../../../../../utils';
import { useZusDialogStore } from '../../../../../../zustand/store';
import Header from './components/Header';
import HkdHeader from './components/HkdHeader';
import SmallText from './components/SmallText';
import Title from './components/Title';
import TitleDivideLine from './components/TitleDivideLine';
import { withHKDollarSign } from './helpers/withHKDollarSign';
import { OfferingRes } from './types';
import useLoading from '../../../../../../hooks/useLoading';

const FinancialsTab = () => {
  const [offeringRes, setOfferingRes] = React.useState<OfferingRes>({} as OfferingRes);

  const { t } = useTranslation('clientList');
  const isMobile = useAppSelector(selectIsMobileView);
  const zusDialog = useZusDialogStore();
  const { showLoading, hideLoading } = useLoading();

  const customerNumber = zusDialog.meta?.rowData?.rawData?.customerNumber;

  const toDisplayAmount = displayAmountCurrying(0, 2);

  const getOverLimitAllowanceDisplayValue = (isHaveOverLimitAllowance: boolean) => {
    if (isHaveOverLimitAllowance) {
      return t('financialTab.enabled');
    }

    return t('financialTab.disabled');
  };

  const totalBalance = toDisplayAmount(offeringRes.totalBalance);
  const availableBalance = toDisplayAmount(offeringRes.availableCredit);
  const excessCreditOf = toDisplayAmount(offeringRes.excessBalance);

  const creditLimit = toDisplayAmount(offeringRes.overallCreditLimit);
  const maxCreditLimit = toDisplayAmount(offeringRes.maxCreditLimit);
  const isHaveOverLimitAllowance = offeringRes.overCreditLimitEnabled;

  const fetchOfferingData = useCallback(async () => {
    if (!customerNumber) return;

    showLoading('getOffering');
    const res = await APIs.clientManagement.clientList.getOffering({ customerNumber });
    hideLoading('getOffering');

    if (!res) return;

    return res;
  }, [customerNumber]);

  useQuery('getOffering', fetchOfferingData, {
    onSuccess: (data: OfferingRes) => {
      setOfferingRes(data);
    },
  });

  return (
    <div style={{ padding: isMobile ? '9px' : '18px' }}>
      <Title value={t('financialTab.balances')} />
      <TitleDivideLine />
      <div style={{ display: 'flex', ...(isMobile && { flexDirection: 'column' }) }}>
        {/* Left */}
        <div style={{ width: isMobile ? '100%' : '50%', marginBottom: '32px' }}>
          <Header value={t('financialTab.totalBalance')} />
          <HkdHeader value={totalBalance} />

          <SmallText
            title={t('financialTab.availableBalance')}
            value={withHKDollarSign(availableBalance)}
          />
          <SmallText
            title={t('financialTab.excessCreditOf')}
            value={withHKDollarSign(excessCreditOf)}
          />
        </div>

        {/* Right */}
        <div>
          <Header value={t('financialTab.creditLimit')} />
          <HkdHeader value={creditLimit} />

          <SmallText
            title={t('financialTab.overLimitAllowance')}
            value={getOverLimitAllowanceDisplayValue(isHaveOverLimitAllowance)}
          />
          <SmallText
            title={t('financialTab.maxCreditLimit')}
            value={withHKDollarSign(maxCreditLimit)}
          />
        </div>
      </div>
    </div>
  );
};

export default FinancialsTab;
