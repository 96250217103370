import { IdateFilter, Ipage } from '../../../../api/types';
import { useListMappingTransform, useTranslation } from '../../../../hooks';
import { toDisplayTime } from '../../../../utils';
import { initZusParams } from '../../../../utils/config';
import { createZusInstance } from '../../../../zustand/store';
import { filterTableTranslateKey as TK } from '../mappings/translate';
import { IapiGetAllRes } from '../types';

export interface IsearchParams extends IdateFilter {
  distributorAgentId: string;
  name: string;
}

type IapiParams = Ipage & Partial<IsearchParams>;

const useZusParams = createZusInstance<IapiParams>(initZusParams);

const useListMapping = (mode: 'export' | 'table') => {
  const { t, tc } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);

  const listMapping = (array: IapiGetAllRes[], omitKeys: string[] = []) => {
    const res = array.map((item) => {
      const mapping: Array<[string, IapiGetAllRes | string | number | null]> = [
        ['rawData', item],
        [TK.distributorAgentId, item.distributorAgentId],
        [TK.distributorName, item.name],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedBy, item.lastModifiedBy],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ];

      const mappedResult = mapping.filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export { useZusParams, useListMapping };
