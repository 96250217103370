import React from 'react';

import { Button } from '../../../components/MuiGenerals';
import { useTranslation } from '../../../hooks';
import { useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';

interface IProps {
  onClick: () => void;
}

const CancelButton = ({ onClick }: IProps) => {
  const { tc } = useTranslation();
  const isMobile = useAppSelector(selectIsMobileView);

  return (
    <Button
      className={'inheritColor'}
      color={'primary'}
      variant={'contained'}
      children={tc('cancel_btn')}
      onClick={onClick}
      style={{
        width: '160px',
        height: '38px',
        marginRight: isMobile ? '0' : '12px',
        backgroundColor: 'rgba(127, 154, 196, 1)',
        color: '#FFFFFF',
        borderRadius: '8px',
      }}
    />
  );
};

export default CancelButton;
