import { useCallback, useEffect, useState } from 'react';

import { SingleSelection } from '../../../../components';
import { SelectChangeEvent } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';
import { EnumAgentUserStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const AgentStatusSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc } = useTranslation();

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('agentStatus') })}
      value={value || ''}
      clearSelect={onClear}
      onChange={onChange}
      enumData={EnumAgentUserStatus}
      nameFn={(item) => item}
    />
  );
};

export default AgentStatusSingleSelection;
