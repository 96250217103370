import { COLUMN_WIDTH } from '../../../../../../constants/ColumnWidth';
import { usePermission } from '../../../../../../hooks';
import { useGenGridCol } from '../../../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/Table';

const useTableColumns = () => {
  const { hasPermission } = usePermission();

  const columns = [
    useGenGridCol(TableColumnKey.Month),
    useGenGridCol(TableColumnKey.ProgramName),
    useGenGridCol(TableColumnKey.Repayment),
    useGenGridCol(TableColumnKey.TotalTransactionAmount),
    useGenGridCol(TableColumnKey.Payment),
    useGenGridCol(TableColumnKey.PaMarkup),
    useGenGridCol(TableColumnKey.AnnualFee),
    useGenGridCol(TableColumnKey.VisaRebate),
    useGenGridCol(TableColumnKey.PaRebate),
  ];

  return columns;
};

export default useTableColumns;
