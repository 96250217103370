import AgentCurrencySingleSelection from '../../../../common/filterTable/components/Filters/AgentCurrencySingleSelection';
import { SelectChangeEvent } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const FromCurrencySingleSelection = (props: IProps) => {
  const { tc } = useTranslation();

  return (
    <AgentCurrencySingleSelection
      label={props.label || tc('phSelection', { fieldName: tc('fromCurrency') })}
      value={props.value}
      onChange={props.onChange}
      onClear={props.onClear}
    />
  );
};

export default FromCurrencySingleSelection;
