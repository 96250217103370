import { FilterTable } from '../../../../components/Layout';
import { getTabTranslatePrefix } from '../helper';
import Filter from './components/Filter';
import Table from './components/Table';
import { useZusParams } from './hooks';

const HistoryTab = ({ defaultCurrencyFilter }: { defaultCurrencyFilter: string }) => {
  const translatePrefix = getTabTranslatePrefix('historyTab');
  const zusParams = useZusParams();
  return (
    <FilterTable
      filter={<Filter defaultCurrencyFilter={defaultCurrencyFilter} />}
      table={<Table />}
      translatePrefix={translatePrefix}
      zusParams={zusParams}
    />
  );
};

export default HistoryTab;
