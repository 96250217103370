import { EnumMerchantExchangeRateAdjustType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

export const getExchangeRateConfigurationAdjustmentTypeDisplayValue = (
  type: number,
  translateFunc: (key: string) => string
) => {
  const value = EnumMerchantExchangeRateAdjustType[type];

  if (!value) return '';

  return translateFunc(`enumConstants.${value}`);
};
