import { COLUMN_WIDTH } from '../../../../../../constants/ColumnWidth';
import { usePermission } from '../../../../../../hooks';
import { useGenGridCol } from '../../../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/Table';

const useTableColumns = () => {
  const { hasPermission } = usePermission();

  const MIN_COLUMN_WIDTH = 180;

  const columns = [
    useGenGridCol(TableColumnKey.Month),
    useGenGridCol(TableColumnKey.DistributorAgentId),
    useGenGridCol(TableColumnKey.DistributorAgentName, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.PfhNumber, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.ProgramName, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.Currency),
    useGenGridCol(TableColumnKey.Repayment),
    useGenGridCol(TableColumnKey.TotalTransactionAmount, { minWidth: MIN_COLUMN_WIDTH }),

    useGenGridCol(TableColumnKey.LocalPayment, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.LocalPaymentPfHCost, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.LocalPaymentPaMarkUp, { minWidth: MIN_COLUMN_WIDTH }),

    useGenGridCol(TableColumnKey.LocalWithdraw, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.LocalWithdrawPfhCost, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.LocalWithdrawPaMarkup, { minWidth: MIN_COLUMN_WIDTH }),

    useGenGridCol(TableColumnKey.LocalRefund, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.LocalRefundPfhCost, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.LocalRefundPaMarkup, { minWidth: MIN_COLUMN_WIDTH }),

    useGenGridCol(TableColumnKey.OverseasPayment, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.OverseasPaymentPfhCost, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.OverseasPaymentPaMarkUp, { minWidth: MIN_COLUMN_WIDTH }),

    useGenGridCol(TableColumnKey.OverseasWithdraw, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.OverseasWithdrawPfhCost, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.OverseasWithdrawPaMarkup, { minWidth: MIN_COLUMN_WIDTH }),

    useGenGridCol(TableColumnKey.OverseasRefund, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.OverseasRefundPfhCost, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.OverseasRefundPaMarkup, { minWidth: MIN_COLUMN_WIDTH }),

    useGenGridCol(TableColumnKey.TotalPfhCost, { minWidth: MIN_COLUMN_WIDTH }),
    useGenGridCol(TableColumnKey.TotalPaMarkup, { minWidth: MIN_COLUMN_WIDTH }),

    useGenGridCol(TableColumnKey.AnnualFee),
    useGenGridCol(TableColumnKey.VisaRebate),
    useGenGridCol(TableColumnKey.PaTotalRebate),
  ];

  return columns;
};

export default useTableColumns;
