import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { usePermission } from '../../../../hooks';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/Table';

interface IProps {
  renderApprovalProgressCell: (props: any) => JSX.Element;
  renderOperationsCell: (props: any) => JSX.Element;
}

const useTableColumns = (props: IProps) => {
  const { renderApprovalProgressCell, renderOperationsCell } = props;

  const { hasPermission } = usePermission();

  const hasApprovePermission = hasPermission(
    AgentPortalFeatureCode.DistributorAgentManagement.CreditAllocation.Approve.prefix
  );
  const hasRejectPermission = hasPermission(
    AgentPortalFeatureCode.DistributorAgentManagement.CreditAllocation.Reject
  );

  const columns = [
    useGenGridCol(TableColumnKey.Operation, {
      renderCell: renderOperationsCell,
      hide: !hasApprovePermission && !hasRejectPermission,
    }),
    useGenGridCol(TableColumnKey.OrderId),
    useGenGridCol(TableColumnKey.ApprovalProgress, {
      minWidth: COLUMN_WIDTH.ApprovalProgress.MinWidth,
      renderCell: renderApprovalProgressCell,
    }),
    useGenGridCol(TableColumnKey.DistributorAgentId, {
      minWidth: COLUMN_WIDTH.DistributorAgentId.MinWidth,
    }),
    useGenGridCol(TableColumnKey.Currency),
    useGenGridCol(TableColumnKey.TransactionAmount),
    useGenGridCol(TableColumnKey.Status, {
      minWidth: 120,
    }),
    useGenGridCol(TableColumnKey.CreatedBy, {
      minWidth: COLUMN_WIDTH.CreatedBy.MinWidth,
    }),
    useGenGridCol(TableColumnKey.CreationTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.LastModifiedTime, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
