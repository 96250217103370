import AssignProgramDialog from './AssignProgramDialog';
import EditDialog from './EditDialog';

export default function Dialog() {
  return (
    <>
      <EditDialog />
      <AssignProgramDialog />
    </>
  );
}
