import React, { useContext, useState } from 'react';

import { EnumVerificationType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Access';

import { LoginContext } from '../';
import APIs from '../../../../api';
import passwordIconSrc from '../../../../assets/icons/login-page-password-icon.png';
import { useAlerting, useTranslation } from '../../../../hooks';
import useLoading from '../../../../hooks/useLoading';
import EmailBarWithBackBtn from '../components/EmailBarWithBackBtn';
import LoginInput from '../components/LoginInput';
import PageWrapper from '../components/PageWrapper';
import TrustDeviceCheckbox from '../components/TrustDeviceCheckbox';
import VerificationCodeCard from '../components/VerificationCodeCard';
import getDeviceId from '../helpers/getDeviceId';
import getDeviceName from '../helpers/getDeviceName';
import useEmailCodeApi from '../helpers/useEmailCodeApi';
import useEnterPasswordWithVerifyValidation from '../helpers/useEnterPasswordWithVerifyValidation';
import useLoginRedirect from '../helpers/useLoginRedirect';
import { LoginFieldsKeys, PageKeys } from '../types';
import ForgotPasswordBtn from '../components/ForgotPasswordBtn';
import { getUrlProgramAgentId } from '../helpers/getUrlProgramAgentId';

const EnterPasswordWithVerify = () => {
  const loginContext = useContext(LoginContext);

  const { fields, setFields, setPage } = loginContext;

  const [isTrustDevice, setIsTrustDevice] = useState(false);

  const { t } = useTranslation('login');
  const { alerting } = useAlerting();
  const { showLoading, hideLoading } = useLoading();
  const { validate } = useEnterPasswordWithVerifyValidation({ fields });
  const { navigateToRedirect } = useLoginRedirect();
  const { requestEmailVerificationCode, verifyEmailVerificationCode } = useEmailCodeApi();

  const onSubmit = async () => {
    // FN validation
    const validationResult = validate();

    if (validationResult.allErrors.length > 0) {
      alerting('error', validationResult.allErrors[0]);
      return;
    }
    const verifyEmailVerificationCodeRes = await verifyEmailVerificationCode(
      fields[LoginFieldsKeys.EmailVerificationCode]
    );

    if (!verifyEmailVerificationCodeRes) return;

    // [API Call] Login
    showLoading('login');
    const res = await APIs.merchantPortal.auth.newDeviceLogin({
      email: fields[LoginFieldsKeys.Email],
      password: fields[LoginFieldsKeys.Password],
      deviceId: getDeviceId(),
      deviceName: getDeviceName(),
      programAgentId: getUrlProgramAgentId(fields[LoginFieldsKeys.ProgramAgentId]),
      rememberDeviceId: isTrustDevice,
      token: verifyEmailVerificationCodeRes.token,
    });
    hideLoading('login');

    if (!res) return;

    navigateToRedirect({
      accessToken: res.accessToken,
      refreshToken: res.refreshToken,
      features: res.features,
    });
  };

  const handleRequestVerificationCode = async () => {
    return await requestEmailVerificationCode({
      email: fields[LoginFieldsKeys.Email],
      type: EnumVerificationType.AgentNewDeviceLogin,
    });
  };

  return (
    <PageWrapper onSubmit={onSubmit}>
      <div className="login-field">
        <EmailBarWithBackBtn
          email={fields[LoginFieldsKeys.Email]}
          onBack={() => {
            setPage(PageKeys.EnterEmail);
          }}
        />
      </div>

      <div style={{ marginBottom: '32px', width: '100%' }}>
        <VerificationCodeCard
          title={t('enterPasswordWithVerify.verificationCodeCardTitle')}
          subtitle={t('enterPasswordWithVerify.verificationCodeCardSubtitle')}
          fields={fields}
          setFields={setFields}
          handleRequestVerificationCode={handleRequestVerificationCode}
        />
      </div>

      <div className="login-field">
        <LoginInput
          label={t('enterPassword.password')}
          value={fields[LoginFieldsKeys.Password]}
          onChange={(e) =>
            setFields((fields) => ({ ...fields, [LoginFieldsKeys.Password]: e.target.value }))
          }
          iconSrc={passwordIconSrc}
          type={'password'}
        />
      </div>
      <div className="forgot-password-btn-container">
        <ForgotPasswordBtn onClick={() => setPage(PageKeys.ForgotPassword)} />
      </div>
      <TrustDeviceCheckbox
        value={isTrustDevice}
        onChange={(e) => setIsTrustDevice(e.target.checked)}
      />
    </PageWrapper>
  );
};

export default EnterPasswordWithVerify;
