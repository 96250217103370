export enum TableColumnKey {
  Month = 'month',
  ProgramName = 'programName',
  PfhNo = 'pfhNo',
  DistributorAgentId = 'distributorAgentId',
  Currency = 'currency',
  RebateTransactionAmount = 'rebateTransactionAmount',
  ProgramAgentRebateAmount = 'programAgentRebateAmount',
  RebateMode = 'rebateMode',
  DistributorAgentRebateRate = 'distributorAgentRebateRate',
  ClientRebateRate = 'clientRebateRate',
  DistributorAgentRebateAmount = 'distributorAgentRebateAmount',
  ClientRebateAmount = 'clientRebateAmount',
  NetDistributorAgentRebateAmount = 'netDistributorAgentRebateAmount',
}

export interface TableRow {
  id: number;
  [TableColumnKey.Month]: string;
  [TableColumnKey.ProgramName]: string;
  [TableColumnKey.PfhNo]: string;
  [TableColumnKey.DistributorAgentId]: string;
  [TableColumnKey.Currency]: string;
  [TableColumnKey.RebateTransactionAmount]: string;
  [TableColumnKey.ProgramAgentRebateAmount]: string;
  [TableColumnKey.RebateMode]: string;
  [TableColumnKey.DistributorAgentRebateRate]: string;
  [TableColumnKey.ClientRebateRate]: string;
  [TableColumnKey.DistributorAgentRebateAmount]: string;
  [TableColumnKey.ClientRebateAmount]: string;
  [TableColumnKey.NetDistributorAgentRebateAmount]: string;
  rawData: any;
}
