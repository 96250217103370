import {
  Container,
  Row,
  RowContent,
  RowHeader
} from '../../../../../common/dialogs/layout/contentLayout';
import { useTranslation } from '../../../../../hooks';
import { Iprefix } from '../../../../../hooks/useTranslation';
import { TableRow } from '../../types/Table';

interface IProps {
  selectedRow: TableRow | null;
}

const ApproveRejectContent = (props: IProps) => {
  const { selectedRow } = props;
  const translatePrefix = 'creditAllocation' as Iprefix;

  const { distributorAgentId, currency, transactionAmount } = selectedRow || {};

  const { t } = useTranslation(translatePrefix);

  return (
    <>
      <Container sx={{ padding: '40px' }}>
        <Row>
          <RowHeader>{t('distributorAgentId')}</RowHeader>
          <RowContent>{distributorAgentId}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t('currency')}</RowHeader>
          <RowContent>{currency}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t('transactionAmount')}</RowHeader>
          <RowContent>{transactionAmount}</RowContent>
        </Row>
      </Container>
    </>
  );
};

export default ApproveRejectContent;
