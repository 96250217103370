import TimePicker from '../components/DatePicker';
import { useTranslation } from '../hooks';

interface IProps {
    label?: string;
    value: any;
    defaultMonth?: any;
    minMonth?: any;
    maxMonth?: any;
    onChange: (e: any) => void;
}

const MonthPicker = (props: IProps) => {
    const { label, value, onChange, defaultMonth, minMonth, maxMonth } = props;
    const { tc } = useTranslation();

    return (
        <TimePicker
            label={label || tc('phSelection', { fieldName: tc('month') })}
            timeValue={value}
            defaultCalendarMonth={defaultMonth}
            views={['year', 'month']}
            setTimeValue={onChange}
            type="date"
            showToolbar={false}
            minEndTime={minMonth ? minMonth : new Date('2010-01-01')}
            maxStartTime={maxMonth ? maxMonth : new Date()}
            customInputFormat={'yyyy-MM'}
        />
    );
};

export default MonthPicker;
