export enum FilterFieldsKeys {
  settlementDateFrom = 'settlementDateFrom',
  settlementDateTo = 'settlementDateTo',
  distributorAgentId = 'distributorAgentId',
  programName = 'programName',
  pfhNo = 'pfhNo',
}

export interface FilterFields {
  [FilterFieldsKeys.settlementDateFrom]: string;
  [FilterFieldsKeys.settlementDateTo]: string;
  [FilterFieldsKeys.distributorAgentId]: string;
  [FilterFieldsKeys.programName]: string;
  [FilterFieldsKeys.pfhNo]: string;
}
