import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import APIs from '../api';
import { useAppDispatch, useAppSelector } from '../reducer/hooks';
import {
  selectProgram,
  selectRawAllProgram,
  setAllProgram,
  setRawAllProgram,
} from '../reducer/programSlice';

interface IUseFetchProgramProps {
  shouldFetch?: boolean;
}

export function useFetchProgram(props: IUseFetchProgramProps) {
  const { shouldFetch } = props || {};

  const dispatch = useAppDispatch();

  useQuery({
    enabled: shouldFetch,
    queryKey: 'programList',
    queryFn: APIs.query.program.getAllProgram,
    staleTime: 60 * 60 * 12,
    onSuccess: ({ rows }) => {
      dispatch(setAllProgram(rows));
      dispatch(setRawAllProgram(rows));
    },
  });
}

export function useGetProgramEnum(distributorAgentId?: string) {
  const { allPrograms } = useAppSelector(selectProgram);
  const [assignablePrograms, setAssignablePrograms] = useState<Record<string, string>>({});

  const rawAllProgram = useAppSelector(selectRawAllProgram);

  const getEnumAllProgramDisplayName = () => {
    if (!rawAllProgram || rawAllProgram.length === 0) return {};

    return rawAllProgram.reduce((acc: any, { programName, programDisplayName }) => {
      return {
        ...acc,
        [programDisplayName]: programName,
      };
    }, {});
  };

  const EnumAllProgramDisplayName = getEnumAllProgramDisplayName();

  useEffect(() => {
    if (isNil(distributorAgentId)) {
      return;
    }

    if (!distributorAgentId) {
      return setAssignablePrograms({});
    }

    APIs.query.program
      .getAssignableProgram({ distributorProgramAgentId: distributorAgentId })
      .then((res) => {
        if (!res) {
          return setAssignablePrograms({});
        }

        const assignableProgramObj = res.programNames.reduce((acc: Record<string, string>, cur) => {
          if (acc[cur]) {
            return acc;
          }

          return { ...acc, [cur]: cur };
        }, {});

        setAssignablePrograms(assignableProgramObj);
      });
  }, [distributorAgentId]);

  return {
    EnumAllProgram: allPrograms,
    EnumAssignableProgram: assignablePrograms,
    EnumAllProgramDisplayName,
  };
}
