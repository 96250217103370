import { useAppDispatch, useAppSelector } from '../reducer/hooks';
import { addLoadingKey, removeLoadingKey, selectLoadingKeys } from '../reducer/loadingSlice';

// Control on/off loading
// UI: src/components/LoadingModal

const useLoading = () => {
  const dispatch = useAppDispatch();
  const loadingKeys = useAppSelector(selectLoadingKeys);

  // console.log('222 loadingKeys :', loadingKeys);

  const showLoading = (key: string) => {
    dispatch(addLoadingKey({ key }));
  };

  const hideLoading = (key: string) => {
    dispatch(removeLoadingKey({ key }));
  };

  return { showLoading, hideLoading };
};

export default useLoading;
