import creditSwapRecord from './creditSwapRecord';
import custodyWallet from './custodyWallet';
import feeAdjustmentRate from './feeAdjustmentRate';
import programAgentDeposit from './programAgentDeposit';
import programAgentRebateWallet from './programAgentRebateWallet';
import programAgentWallet from './programAgentWallet';
import programList from './programList';

export default {
  creditSwapRecord,
  programAgentDeposit,
  programAgentWallet,
  programAgentRebateWallet,
  custodyWallet,
  feeAdjustmentRate,
  programList,
};
