import React from 'react';

import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import PermissionTab from '../../../components/Layout/PermissionTab';
import useTranslation, { Iprefix as IPrefix } from '../../../hooks/useTranslation';
import DailyReportTab from './components/DailyReportTab';
import MonthlyReportTab from './components/MonthlyReportTab';

interface ICustomerSpendingReportProps {}

const translatePrefix = 'customerSpendingReport' as IPrefix;

const CustomerSpendingReport: React.FC<ICustomerSpendingReportProps> = (props) => {
  const { t } = useTranslation(translatePrefix);
  return (
    <PermissionTab
      contentArr={[
        {
          translatePrefix: `${translatePrefix}.dailyReportTab` as IPrefix,
          element: <DailyReportTab />,
        },
        {
          translatePrefix: `${translatePrefix}.monthlyReportTab` as IPrefix,
          element: <MonthlyReportTab />,
        },
      ]}
      tabsArr={[
        {
          name: t(`dailyReportTab.title`),
          value: `${translatePrefix}.dailyReportTab` as IPrefix,
          permissionCode:
            AgentPortalFeatureCode.Report.CustomerSpendingReport.DailyReportTab.prefix,
        },
        {
          name: t(`monthlyReportTab.title`),
          value: `${translatePrefix}.monthlyReportTab` as IPrefix,
          permissionCode:
            AgentPortalFeatureCode.Report.CustomerSpendingReport.MonthlyReportTab.prefix,
        },
      ]}
    />
  );
};

export default CustomerSpendingReport;
