import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';

const getAssetListByProgramName = ({ programName }: { programName: string }) => {
  return axios.get<never, any>(AgentPortal.EptQuery.assets.merchantSupportedAssets, {
    params: {
      programName,
    },
  });
};

const getProgramAssetList = () => {
  return axios.get<never, any>(AgentPortal.EptQuery.assets.merchantSupportedAssets);
};

const getWalletAddress = ({ chainCode, tokenName }: { chainCode: string; tokenName: string }) => {
  return axios.get<never, any>(AgentPortal.EptQuery.address.getAgentAddress, {
    params: {
      chainCode,
      tokenName,
    },
  });
};

export default {
  getAssetListByProgramName,
  getWalletAddress,
  getProgramAssetList,
};
