import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { EnumApprovalStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import APIs from '../../../api';
import { CustomPagination, NoRowsOverlay } from '../../../components/Layout';
import { useTranslation } from '../../../hooks';
import useLoading from '../../../hooks/useLoading';
import { toDisplayTime } from '../../../utils';
import { useGenGridCol } from '../../../utils/ComponentHelper';
import { dataGridDefaults } from '../../../utils/constant';
import { ApproveHistory } from '../types/ApproveHistory';

interface ApprovalProgressDialogContentProps {
  id?: string;
  isShowPendingAfterRejected?: boolean;
  approveHistory?: ApproveHistory[];
  getApproveHistory: ({ id }: { id: string }) => Promise<ApproveHistory[]>;
}

const StyledDataGrid = styled(DataGrid)(
  () => `
  .MuiDataGrid-columnHeaders {
    background: #333746;
  }

  `
);

const ApprovalProgressDialogContent = (props: ApprovalProgressDialogContentProps) => {
  const { isShowPendingAfterRejected, id, getApproveHistory } = props;

  const { t } = useTranslation('enumConstants');
  const { showLoading, hideLoading } = useLoading();

  const fetchApproveHistory = async () => {
    if (!id) return;

    showLoading('getApproveHistory');
    const res = await getApproveHistory({ id });
    hideLoading('getApproveHistory');

    if (!res) return [];

    return res;
  };

  const { data } = useQuery(`approveHistory-${id}`, fetchApproveHistory, {
    onSuccess: (data) => {
      setApproveHistory(data);
    },
  });

  const [approveHistory, setApproveHistory] = useState<ApproveHistory[] | undefined>(data);

  const columns = [
    useGenGridCol('approval_sequence'),
    useGenGridCol('permission'),
    useGenGridCol('operation_result'),
    useGenGridCol('operated_by'),
    useGenGridCol('operation_time'),
    useGenGridCol('remarks'),
  ];

  const createTableRows = (data?: ApproveHistory[]) => {
    if (!data || data?.length === 0) {
      return [];
    }

    return data.map((item) => {
      return {
        id: item.seq,
        approval_sequence: item.seq,
        permission: item.approvalPermission,
        operation_result: t(EnumApprovalStatus[item.status]),
        operated_by: item.approvedBy,
        operation_time: toDisplayTime(item.approvedDate),
        remarks: item.remarks,
      };
    });
  };

  const displayApprovalArr = useMemo(() => {
    if (isShowPendingAfterRejected) {
      return approveHistory;
    }

    const filteredApprovalArr = approveHistory?.filter(({ status }) => {
      if (approveHistory.find(({ status }) => status === EnumApprovalStatus.Rejected)) {
        return status !== EnumApprovalStatus.Pending;
      }
      return true;
    });

    return filteredApprovalArr;
  }, [approveHistory, isShowPendingAfterRejected]);

  const rows = createTableRows(displayApprovalArr);

  return (
    <div style={{ padding: '8px 32px 24px', background: '#333746' }}>
      <StyledDataGrid
        {...dataGridDefaults}
        rows={rows}
        rowCount={displayApprovalArr?.length}
        columns={columns}
        page={0}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: displayApprovalArr?.length, footerBgColor: '#333746' },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'approval_sequence', sort: 'asc' }],
          },
        }}
        hideFooter={true}
      />
    </div>
  );
};

export default ApprovalProgressDialogContent;
