import APIs from '../../../../api';

export const fetchExchangeRate = async (fromCurrency: string, toCurrency: string) => {
  const res = await APIs.setting.exchangeRate.getExchangeRate({
    fromCurrency,
    toCurrency,
  });

  return res || {};
};
