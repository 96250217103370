import { ApiResult } from '../../../../../../api/types';
import { useTranslation } from '../../../../../../hooks';
import { useDistributorAgentMap } from '../../../../../../hooks/useDistributorAgentMap';
import useProgramDisplayName from '../../../../../../hooks/useProgramDisplayName';
import useSystemCurrency from '../../../../../../hooks/useSystemCurrency';
import { displayAmountCurrying, toDisplayTime } from '../../../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey, TableRow } from '../types/Table';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { te, tb, tc } = useTranslation();
  const { CurrencyDecimalsMapping } = useSystemCurrency();
  const { getProgramDisplayName } = useProgramDisplayName();
  const { distributorAgentMap } = useDistributorAgentMap();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      const displayAmount = displayAmountCurrying(0, 2);

      return {
        id: index,
        [TableColumnKey.Month]: row.month || '',
        [TableColumnKey.DistributorAgentId]: row.distributorAgentId || '',
        [TableColumnKey.DistributorAgentName]: distributorAgentMap[row.distributorAgentId] || '',
        [TableColumnKey.Repayment]: displayAmount(row.repayment),
        [TableColumnKey.ProgramName]: getProgramDisplayName(row.programName) || '',
        [TableColumnKey.TotalTransactionAmount]: displayAmount(row.totalTransactionAmount),
        [TableColumnKey.Payment]: displayAmount(row.spending),
        [TableColumnKey.PaMarkup]: displayAmount(row.paTransactionCharges),
        [TableColumnKey.AnnualFee]: displayAmount(row.annualFee),
        [TableColumnKey.VisaRebate]: displayAmount(row.totalVisaRebate),
        [TableColumnKey.PaRebate]: displayAmount(row.paRebate),
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
