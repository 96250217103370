import { EnumCreditAllocationStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

export const getTransferRequestStatusDisplayValue = (
  status: number,
  translateFunc: (key: string) => string
) => {
  if (!EnumCreditAllocationStatus[status]) return '';

  return translateFunc(`EnumCreditAllocationStatus.${EnumCreditAllocationStatus[status]}`);
};
