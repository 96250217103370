import addHours from 'date-fns/addHours';
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import FormHelperText from '@mui/material/FormHelperText';
import { SxProps } from '@mui/system';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarPickerView } from '@mui/x-date-pickers/CalendarPicker';
import { CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import { useTranslation } from '../hooks';
import { store } from '../reducer/store';
import { searchEndOfDay, searchEndOfSecond, toDBTime } from '../utils';
import { dateInFormat, dateTimeInFormat } from '../utils/config';
import { Box } from './MuiGenerals';
import { MpTextField } from './TextField';
import { zhHK } from 'date-fns/locale';
import { useLocaleState } from 'react-admin';
import { findElementWithRetry } from '../utils/htmlUtils';

interface PropsFaceBase {
  label?: string;
  timeValue: Date | null;
  minEndTime?: any;
  maxStartTime?: any;
  isLimitingTime?: boolean;
  allowHourOnly?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  inputLabel?: string;
  disabled?: boolean;
  setTimeValue: React.Dispatch<React.SetStateAction<Date | null>> | ((value: Date | null) => void);
  helperText?: string;
  showToolbar?: boolean;
  helperTextSx?: CSSProperties;
  customInputFormat?: string;
  defaultCalendarMonth?: Date;
  sx?: SxProps;
  defaultEndOfDayTimeAfterDatePicked?: boolean;
  onClose?: (props?: any) => void;
}

type DateType = {
  type: 'date';
  views?: CalendarPickerView[];
};
type DateTimeType = {
  type: 'dateTime';
  views?: CalendarOrClockPickerView[];
};

type PropsFace = PropsFaceBase & (DateType | DateTimeType);

export default function TimePicker(props: PropsFace) {
  const {
    type,
    label,
    setTimeValue,
    timeValue = null,
    minEndTime,
    maxStartTime,
    isLimitingTime,
    allowHourOnly,
    views,
    disableFuture = false,
    disablePast,
    disabled,
    helperText,
    helperTextSx,
    showToolbar = true,
    customInputFormat = null,
    defaultCalendarMonth,
    sx,
    onClose,
    defaultEndOfDayTimeAfterDatePicked,
  } = props;
  const [timeShow, setTimeShow] = useState<Date | null | undefined>(timeValue);
  const [isClockView, setIsClockView] = useState(false);

  const selectionRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation('common');
  const [locale] = useLocaleState();
  const localesMap: { [key: string]: undefined | Locale } = { en: undefined, zh_cn: zhHK };
  const localeTextMap: { [key: string]: undefined | any } = {
    en: undefined,
    zh_cn: {
      ...zhHK,
      cancelButtonLabel: t('cancel'),
      okButtonLabel: t('confirm'),
    },
  };
  const hasViews = views && views.length > 0;
  const dateViews = hasViews && type === 'date' ? views : (['day'] as CalendarPickerView[]);
  const dateTimeViews =
    hasViews && type === 'dateTime'
      ? views
      : (['day', 'hours', 'minutes', 'seconds'] as CalendarOrClockPickerView[]);

  const turnDateIntoEndOfDayDate = (date: Date) => {
    const newDate = new Date(date);
    newDate.setHours(23);
    newDate.setMinutes(59);
    newDate.setSeconds(59);

    return newDate;
  };

  const handleChange = (newValue: Date | null) => {
    if (allowHourOnly) {
      newValue?.setMinutes(0);
      newValue?.setSeconds(0);
      setTimeShow(newValue);
    }

    if (type === 'dateTime') {
      const isAM = (newValue?.getHours() || 0) < 12;
      //this logic is currently not working, because the button don't have those classname
      const amBtn = document.querySelector('.MuiClock-amButton');
      const amBtnText = amBtn?.querySelector('.MuiTypography-caption');
      const pmBtn = document.querySelector('.MuiClock-pmButton');
      const pmBtnText = pmBtn?.querySelector('.MuiTypography-caption');

      if (isAM) {
        if (pmBtnText?.classList.contains('Mui-selected')) {
          pmBtnText?.classList.remove('Mui-selected');
        }
        if (!amBtnText?.classList.contains('Mui-selected')) {
          amBtnText?.classList.add('Mui-selected');
        }
      } else {
        if (amBtnText?.classList.contains('Mui-selected')) {
          amBtnText?.classList.remove('Mui-selected');
        }
        if (!pmBtnText?.classList.contains('Mui-selected')) {
          pmBtnText?.classList.add('Mui-selected');
        }
      }
    }

    setTimeShow(newValue);
  };

  const onAccept = () => {
    if (!timeShow) return;
    setTimeValue(timeShow);
  };

  const clockViewInit = useCallback(async () => {
    const clockView = await findElementWithRetry('.MuiClockPicker-root', 5, 100);
    if (!clockView) {
      console.error('Clock view not found');
      return;
    }

    const clockViewAmPmBtn = clockView.querySelectorAll('.MuiButtonBase-root');
    const amBtn = clockViewAmPmBtn?.[0];
    const pmBtn = clockViewAmPmBtn?.[1];

    if (!amBtn || !pmBtn) {
      console.error('AM/PM buttons not found');
      return;
    }

    const amBtnText = amBtn.querySelector('.MuiTypography-caption');
    const pmBtnText = pmBtn.querySelector('.MuiTypography-caption');

    if (amBtnText) {
      amBtnText.textContent = t('am');
    }
    if (pmBtnText) {
      pmBtnText.textContent = t('pm');
    }
  }, []);

  const onViewChange = (view: string) => {
    // Set the time to end of day when date is picked
    if (defaultEndOfDayTimeAfterDatePicked && view === 'hours') {
      setTimeShow((timeShow) => {
        return turnDateIntoEndOfDayDate(timeShow!);
      });
    }

    view === 'hours' || view === 'minutes' || view === 'seconds'
      ? setIsClockView(true)
      : setIsClockView(false);
  };

  useEffect(() => {
    setTimeShow(timeValue);
  }, [timeValue]);

  useEffect(() => {
    // Change the text of the AM/PM button
    // Can also fixed by upgrade the @mui/x-date-pickers to 5 and use .MuiClock-amButton
    // this assumption is based the button alignment of the button text, might not work if the button order is changed
    // add retry logic to make sure the button is rendered
    if (isClockView) {
      clockViewInit();
    }

    // @mui/x-date-pickers to 5 cannot know the change of view from input view to clock view
    // so need to add event listener to the button for a hard fix
    // but still had some small delay to change the text
    const penIconButton = document.querySelector('.MuiPickersToolbar-penIconButton');
    if (penIconButton) {
      penIconButton.addEventListener('click', clockViewInit);
    }
    return () => {
      if (penIconButton) {
        penIconButton.removeEventListener('click', clockViewInit);
      }
    };
  }, [isClockView, clockViewInit]);

  return (
    <Box sx={{ position: 'relative', '>div': { width: '100%' }, ...sx }}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={localesMap[locale]}
        localeText={localeTextMap[locale]}
      >
        {type === 'date' && (
          <MobileDatePicker
            inputRef={selectionRef}
            onClose={() => {
              const focusedDivElement = selectionRef.current;
              setIsClockView(false);
              setTimeout(() => focusedDivElement?.blur(), 200);
            }}
            views={dateViews}
            disableFuture={disableFuture}
            disablePast={disablePast}
            label={label}
            inputFormat={customInputFormat || dateInFormat}
            value={timeShow}
            onAccept={onAccept}
            onChange={handleChange}
            minDate={minEndTime}
            maxDate={maxStartTime}
            disabled={disabled}
            showToolbar={showToolbar}
            renderInput={(params) => <MpTextField {...params} />}
            defaultCalendarMonth={defaultCalendarMonth}
          />
        )}
        {type === 'dateTime' && (
          <MobileDateTimePicker
            inputRef={selectionRef}
            onClose={() => {
              const focusedDivElement = selectionRef.current;
              setIsClockView(false);
              setTimeout(() => focusedDivElement?.blur(), 200);
              onClose && onClose({ timeShow });
            }}
            onViewChange={onViewChange}
            views={dateTimeViews}
            disableFuture={disableFuture}
            label={label}
            inputFormat={customInputFormat || dateTimeInFormat}
            shouldDisableTime={(value, view) => {
              if (!allowHourOnly) {
                return false;
              }
              if (view === 'minutes' || view === 'seconds') {
                if (value) {
                  return true;
                }
              }
              return false;
            }}
            value={timeShow}
            onAccept={onAccept}
            onChange={handleChange}
            minDateTime={minEndTime}
            maxDateTime={maxStartTime}
            minTime={isLimitingTime ? minEndTime : undefined}
            maxTime={isLimitingTime ? maxStartTime : undefined}
            disabled={disabled}
            renderInput={(params) => <MpTextField sx={sx} {...params} />}
          />
        )}
      </LocalizationProvider>
      {helperText && (
        <FormHelperText
          children={helperText}
          sx={{ position: 'absolute', bottom: -15, left: 0, zIndex: 99 }}
        />
      )}
    </Box>
  );
}

type maybeDate = Date | null;
interface useDatePickerFace {
  start: {
    defaultDate: maybeDate;
    label?: String;
    defaultEndOfDayTimeAfterDatePicked?: boolean;
  };
  end: {
    defaultDate: maybeDate;
    label?: String;
    defaultEndOfDayTimeAfterDatePicked?: boolean;
  };
  isTimezoneConvert: boolean;
  isEndOfSecond: boolean;
}
//to put default date
//useDatePicker({ start: { defaultDate: new Date() } })
export function useDatePicker(inputConfig?: Partial<useDatePickerFace>) {
  const defaultConfig = {
    start: {
      defaultDate: null,
      label: 'Time Start',
      defaultEndOfDayTimeAfterDatePicked: false,
    },
    end: {
      defaultDate: null,
      label: 'Time End',
      defaultEndOfDayTimeAfterDatePicked: true,
    },
    isTimezoneConvert: true,
    isEndOfSecond: true,
  };

  const config = { ...defaultConfig, ...inputConfig };

  const [dateStart, setDateStart] = useState<maybeDate>(config.start.defaultDate);
  const [dateEnd, setDateEnd] = useState<maybeDate>(config.end.defaultDate);

  const { t } = useTranslation('common');

  const clearDate = () => {
    setDateStart(null);
    setDateEnd(null);
  };

  const resetDate = () => {
    setDateStart(config.start.defaultDate);
    setDateEnd(config.end.defaultDate);
  };

  const hyphenStyle = {
    verticalAlign: 'bottom',
    fontSize: '2rem',
    margin: '0 10px',
  };

  const DateRangePicker = useMemo(
    () => (p: any) =>
      (
        <Box
          // className="datePickerObj"
          sx={{
            display: 'flex',
            width: '100%',
            '>div': {
              width: '50%',
            },
          }}
        >
          <TimePicker
            type={p.type}
            label={p.startLabel ?? t('start_date')}
            timeValue={dateStart}
            setTimeValue={setDateStart}
            maxStartTime={dateEnd}
            defaultEndOfDayTimeAfterDatePicked={config?.start?.defaultEndOfDayTimeAfterDatePicked}
          />
          <span style={hyphenStyle}> - </span>
          <TimePicker
            type={p.type}
            label={p.endLabel ?? t('end_date')}
            timeValue={dateEnd}
            setTimeValue={setDateEnd}
            minEndTime={dateStart}
            defaultEndOfDayTimeAfterDatePicked={config?.end?.defaultEndOfDayTimeAfterDatePicked}
          />
        </Box>
      ),
    [dateStart, dateEnd]
  );
  const localUTCdiff = Number(store.getState().profile.timezone) * -1 || 0;
  const usedUTCDiff = config.isTimezoneConvert ? localUTCdiff : 0;
  const searchEndOfTime = config.isEndOfSecond ? searchEndOfSecond : searchEndOfDay;

  const startTime = toDBTime(dateStart && addHours(dateStart, usedUTCDiff));
  const endTime = toDBTime(dateEnd && searchEndOfTime(addHours(dateEnd, usedUTCDiff)));
  const output = {
    start: startTime ? startTime.slice(0, -4) + '000Z' : '',
    end: endTime ? endTime.slice(0, -4) + '999Z' : '',
  };

  return {
    Picker: DateRangePicker,
    start: output.start,
    end: output.end,
    clearDate,
    resetDate,
  };
}

export type DatePickerReturnType = {
  Picker: (p: any) => JSX.Element;
  start: string;
  end: string;
  clearDate: () => void;
  resetDate: () => void;
};
