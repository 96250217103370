import './index.css';

import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState
} from 'react';

import Autocomplete, {
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
  AutocompleteProps
} from '@mui/material/Autocomplete';

import { MpTextField } from '../TextField';

// [IMPORTANT] For "renderInput", pass {...params} (ref and inputProps) on input component to prevent error
// Omit renderInput from props, will be handled inside of wrapper component
type MUIAutoCompleteProps<T> = Omit<
  AutocompleteProps<T, undefined, undefined, undefined>,
  'renderInput'
>;

interface CustomAutoCompleteProps<T> {
  label: string;
  value: string;
  // Length limit of options list
  optionCountLimit?: number;
  // The length of the input value that triggers the autocomplete to expand
  triggerLength?: number;
  handleChange: (event: SyntheticEvent<Element, Event>, value: any) => void;
  setOptions: Dispatch<SetStateAction<T[]>>;
}

const AutoComplete = <T,>(props: MUIAutoCompleteProps<T> & CustomAutoCompleteProps<T>) => {
  const {
    onInputChange,
    onClose,
    options,
    label,
    value,
    optionCountLimit,
    triggerLength,
    getOptionLabel,
    handleChange,
    disabled,
    setOptions,
    renderOption,
  } = props;

  const OPTIONS_LENGTH_LIMIT = optionCountLimit || 10;
  const TRIGGER_LENGTH = triggerLength || 4;

  const [shouldAutocompleteExpand, setShouldAutocompleteExpand] = useState(false);

  const handledNullishValue = value ?? '';

  useEffect(() => {
    if (handledNullishValue) {
      return;
    }

    setOptions([]);
  }, [handledNullishValue]);

  const handleInputChange = (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason !== 'input' && reason !== 'clear') {
      return;
    }

    // Prevent the autocomplete from expanding if the input value is less than the trigger length
    if (value.length >= TRIGGER_LENGTH) {
      setShouldAutocompleteExpand(true);
    } else {
      setShouldAutocompleteExpand(false);
    }

    if (onInputChange) {
      onInputChange(event, value, reason);
    }
  };

  const handleClose = (event: SyntheticEvent<Element, Event>, reason: AutocompleteCloseReason) => {
    setShouldAutocompleteExpand(false);

    if (onClose) {
      onClose(event, reason);
    }
  };

  const handleOpen = (event: SyntheticEvent<Element, Event>) => {
    if (handledNullishValue.length < TRIGGER_LENGTH) return;

    setShouldAutocompleteExpand(true);
  };

  const getValidOptions = (options: Readonly<Array<T>>) => {
    const validOptions = [...options];

    if (options.length > OPTIONS_LENGTH_LIMIT) {
      return validOptions.slice(0, OPTIONS_LENGTH_LIMIT);
    }

    return validOptions;
  };

  const validOptions = getValidOptions(options);

  return (
    <Autocomplete
      open={shouldAutocompleteExpand}
      onOpen={handleOpen}
      onClose={handleClose}
      options={validOptions}
      renderInput={(params) => (
        <MpTextField label={label} value={handledNullishValue} {...params} />
      )}
      disableClearable={!handledNullishValue}
      onInputChange={handleInputChange}
      onChange={(event, value) => {
        if (value) {
          setOptions([value as T]);
        }

        handleChange(event, value);
      }}
      freeSolo
      getOptionLabel={getOptionLabel as any}
      disabled={disabled}
      inputValue={handledNullishValue}
      renderOption={renderOption as any}
    />
  );
};

export default AutoComplete;
