import { ReactNode } from 'react';

import { COLOR_THEME } from '../../../../style/colorTheme';

const Section = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        boxSizing: 'border-box',
        backgroundColor: COLOR_THEME.Page.Background,
        width: '100%',
        margin: '8px 0',
      }}
    >
      <div style={{ display: 'flex' }}>{children}</div>
    </div>
  );
};

export default Section;
