import React from 'react';

import { MpTextFieldNumberOnly } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';

interface IProps {
  label?: string;
  value: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  isAllowSignedNumber?: boolean;
}

const ExchangeRateConfigAdjustmentAmountTextField = (props: IProps) => {
  const { label, value, onChange, isAllowSignedNumber } = props;
  const { tc } = useTranslation();

  const allowSignedNumberMode = isAllowSignedNumber ? 'all' : undefined;

  return (
    <MpTextFieldNumberOnly
      label={label || tc('phInputField', { fieldName: tc('adjustmentAmount') })}
      value={value}
      onChange={onChange}
      decimalCount={6}
      allowSignedNumber={allowSignedNumberMode}
      showDecimalKeyboard
    />
  );
};

export default ExchangeRateConfigAdjustmentAmountTextField;
