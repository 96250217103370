import { useCallback, useContext, useEffect } from 'react';

import { LoginContext } from '../';
import submitBtn from '../../../../assets/icons/login-page-submit-btn.png';
import { LoadingDialog } from '../../../../components';
import Logo from './Logo';

interface IProps {
  onSubmit: () => void;
  children: React.ReactNode;
}

const PageWrapper = (props: IProps) => {
  const { onSubmit, children } = props;

  const handleSubmit = useCallback(() => {
    if (!onSubmit) return;

    onSubmit();
  }, [onSubmit]);

  // handle "Enter" key press
  useEffect(() => {
    const ENTER_KEY_CODE = 13;

    const handleEnterKey = (e: any) => {
      if (e.keyCode === ENTER_KEY_CODE) {
        handleSubmit();
      }
    };

    window.addEventListener('keydown', handleEnterKey);

    return () => {
      window.removeEventListener('keydown', handleEnterKey);
    };
  }, [handleSubmit]);

  return (
    <>
      <div className="login-page">
        <div className="content">
          <div className="logo-container">
            <Logo />
          </div>

          <div className="fields-container">{children}</div>

          <div className="submit-btn" onClick={handleSubmit}>
            <img src={submitBtn} alt="logo" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PageWrapper;
