import { Currency } from '../../../../../../common/types/Currency';

export enum FilterFieldsKeys {
  settlementMonthFrom = 'settlementMonthFrom',
  settlementMonthTo = 'settlementMonthTo',
  distributorAgentId = 'distributorAgentId',
  programName = 'programName',
  pfhNo = 'pfhNo',
  currency = 'currency',
}

export interface FilterFields {
  [FilterFieldsKeys.settlementMonthFrom]: string;
  [FilterFieldsKeys.settlementMonthTo]: string;
  [FilterFieldsKeys.distributorAgentId]: string;
  [FilterFieldsKeys.programName]: string;
  [FilterFieldsKeys.pfhNo]: string;
  [FilterFieldsKeys.currency]: string;
}
