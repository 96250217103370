import { ValidationType, ValidatorResponse } from '../../hooks/useValidation/types';

export const requiredValidator =
  (errorMessage: string, isEnabled: boolean = true) =>
  (fieldKey: string, value: any): ValidatorResponse => {
    const isEmpty = value === undefined || value === null || value === '';
    const isEmptyArray = !isEmpty && Array.isArray(value) && value.length === 0;
    const isDate = value instanceof Date;
    const isEmptyObject =
      !isEmpty && !isDate && typeof value === 'object' && Object.keys(value).length === 0;

    const isInvalid = isEmpty || isEmptyArray || isEmptyObject;

    const errorInfo = {
      message: errorMessage,
      fieldKey,
      value,
      validatorType: ValidationType.Required,
    };

    if (!isEnabled) {
      return { isValid: true, errorInfo };
    }

    if (isInvalid) {
      return { isValid: false, errorInfo };
    }

    return { isValid: true, errorInfo };
  };
