import { useEffect, useState } from 'react';

import APIs from '../../../../api';
import { SingleSelection } from '../../../../components';
import { useTranslation } from '../../../../hooks';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

const ProgramNameSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const [apiRes, setApiRes] = useState<any[]>([]);

  //   const { EnumAllProgram } = useGetProgram();

  const getProgramNameEnum = () => {
    if (!apiRes) return {};

    return apiRes.reduce((acc: any, curr: any) => {
      return {
        ...acc,
        [curr.programName]: curr.programName,
      };
    }, {});
  };

  useEffect(() => {
    const fetchPrograms = async () => {
      const res = await APIs.merchantPortal.query.getPrograms();

      if (!res) return;

      setApiRes(res.programs);
    };

    fetchPrograms();
  }, []);

  const programNameEnum = getProgramNameEnum();

  const { tc } = useTranslation();

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('programName') })}
      value={value || ''}
      onChange={onChange}
      clearSelect={onClear}
      enumData={programNameEnum}
      nameFn={(item) => item}
    />
  );
};

export default ProgramNameSingleSelection;
