import React, { useMemo } from 'react';

import { Theme } from '@mui/system/createTheme';
import { SxProps } from '@mui/system/styleFunctionSx';

import { useTranslation } from '../../hooks';
import { IcommonKey } from '../../hooks/useTranslation';
import { useZusTranslatePrefixStore } from '../../zustand/store';
import en from '../i18nProvider/en';
import { Box } from '../MuiGenerals';
import { useAppSelector } from '../../reducer/hooks';
import { selectIsMobileView } from '../../reducer/mediaSlice';

export default function GridBox(props: {
  labelElePairArr: Array<[string, JSX.Element]>;
  columnCount?: number;
  containerSx?: SxProps<Theme>;
  dividingLineColor?: string;
  isAlignColumn?: boolean;
  subSection?: JSX.Element;
}) {
  const {
    labelElePairArr,
    columnCount = 2,
    containerSx,
    dividingLineColor = '',
    isAlignColumn,
    subSection,
  } = props;
  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t, tc } = useTranslation(translatePrefix);
  const isMobile = useAppSelector(selectIsMobileView);

  const gridTemplateColumns = useMemo(() => {
    let result = '';
    for (let i = 0; i < columnCount; i++) {
      if (i) {
        result += ' ';
      }

      const gap = `${20 * (columnCount * 2 - 1)}px`;

      const eachLabelWidth = `calc((100% - ${gap}) / ${columnCount} * 0.4)`;
      const eachContentWidth = `calc((100% - ${gap}) / ${columnCount} * 0.6)`;
      const eachElementMinSize = `calc((100% - ${gap}) / ${columnCount} * 0.7)`;

      const alignColumnStyle = `${eachLabelWidth} ${eachContentWidth}`;
      const contentWidthColumnStyle = `minmax(min-content,max-content) minmax(${eachElementMinSize},auto)`;

      result += isAlignColumn ? alignColumnStyle : contentWidthColumnStyle;
    }
    return result;
  }, [columnCount]);

  const remainder = labelElePairArr.length % columnCount;

  const lastRowColCount = remainder ? remainder : columnCount;

  const mostOutterBoxStyle = subSection ? containerSx || {} : {};
  const containerBoxStyle = subSection ? {} : containerSx || {};

  const hasInputField = labelElePairArr.find(([_label, element]) => {
    const isInputElement = !!element?.props?.label;
    const hasInputChild =
      (Array.isArray(element?.props?.children) &&
        element.props.children.find(
          (child: { props: Record<string, string> }) => !!child?.props?.label
        )) ||
      element?.props?.children?.props?.label;
    return isInputElement || hasInputChild;
  });
  const inputLabelStyleAddOn: Record<string, string> = hasInputField
    ? { alignItems: 'end', ...(isMobile && { paddingBottom: '5px' }) }
    : {};

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...mostOutterBoxStyle }}>
      <Box
        sx={{
          '.MuiInputLabel-shrink': {
            display: 'none',
          },
          display: isMobile ? 'block' : 'grid',
          gridTemplateColumns,
          columnGap: '20px',
          fontSize: '0.6875rem',
          padding: '0px',
          ...containerBoxStyle,
        }}
      >
        {labelElePairArr.map(([label, element], i) => {
          const isInputElement = !!element?.props?.label;
          const hasInputChild =
            (Array.isArray(element?.props?.children) &&
              element.props.children.find(
                (child: { props: Record<string, string> }) => !!child?.props?.label
              )) ||
            element?.props?.children?.props?.label;

          const rowsExcludedFirstRow = i > columnCount - 1;
          const rowsExcludedLastRow = i < labelElePairArr.length - lastRowColCount;

          const displayDataStyleAddOn =
            !isInputElement && !hasInputChild ? inputLabelStyleAddOn : {};

          return (
            <React.Fragment key={i}>
              <Box
                sx={{
                  // margin:
                  //   i > columnCount - 1 && i < labelElePairArr.length - 1 - columnCount
                  //     ? '12px 0px'
                  //     : undefined,
                  marginTop: rowsExcludedFirstRow ? '12px' : undefined,
                  marginBottom: rowsExcludedLastRow ? '12px' : undefined,
                  display: 'flex',
                  alignItems: 'center',
                  ...inputLabelStyleAddOn,
                }}
              >
                <Box>
                  {Object.keys(en.common).includes(label) ? tc(label as IcommonKey) : t(label)}:
                </Box>
              </Box>

              <Box
                sx={{
                  // margin: isInputElement ? '12px 0px' : 0,
                  display: 'flex',
                  alignItems: 'center',
                  '>div': { width: '100%', wordWrap: 'break-word' },
                  ':after':
                    (i + 1) % columnCount && dividingLineColor
                      ? {
                          content: '""',
                          background: dividingLineColor,
                          height: '100%',
                          width: '1px',
                        }
                      : undefined,

                  ...displayDataStyleAddOn,
                }}
              >
                <Box
                  sx={{
                    marginTop: rowsExcludedFirstRow ? '12px' : undefined,
                    marginBottom: rowsExcludedLastRow ? '12px' : undefined,
                    '>div': { width: '100%', margin: 0 },
                    // ':has(p)': {
                    //   marginBottom: '0px',
                    // },
                  }}
                >
                  {element}
                </Box>
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
      {subSection}
    </Box>
  );
}
