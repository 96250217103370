import { Dispatch, SetStateAction, useEffect } from 'react';

import { useDatePicker } from '../../../../../components/DatePicker';

interface IProps<FilterFieldsType> {
  DateObj: {
    CreationTime?: ReturnType<typeof useDatePicker>;
    LastModifiedTime?: ReturnType<typeof useDatePicker>;
  };
  setFields: Dispatch<SetStateAction<FilterFieldsType>>;
}

const useSyncDatePicker = <FilterFieldsType>(props: IProps<FilterFieldsType>) => {
  const { DateObj, setFields } = props;

  useEffect(() => {
    setFields((fields) => {
      return {
        ...fields,
        createdDateFrom: DateObj?.CreationTime?.start,
        createdDateTo: DateObj?.CreationTime?.end,
        lastModifiedTimeFrom: DateObj?.LastModifiedTime?.start,
        lastModifiedTimeTo: DateObj?.LastModifiedTime?.end,
      };
    });
  }, [
    DateObj?.CreationTime?.start,
    DateObj?.CreationTime?.end,
    DateObj?.LastModifiedTime?.start,
    DateObj?.LastModifiedTime?.end,
    setFields,
  ]);
};

export default useSyncDatePicker;
