import Column from '../../../../../../../../../common/approval/components/DetailView/Column';
import RowItem from '../../../../../../../../../common/approval/components/DetailView/RowItem';
import { useTranslation } from '../../../../../../../../../hooks';

const PersonPurportingToActOnColumn = ({
  isHavePersonPurportingToActOn,
  reason,
}: {
  isHavePersonPurportingToActOn: boolean;
  reason?: string;
}) => {
  const { t } = useTranslation('tcspAccountApplication');

  const personPurportingToActOn = isHavePersonPurportingToActOn
    ? reason
    : t('noPersonPurportingToActOn');

  return (
    <Column>
      <RowItem title={t('personPurportingToActOn')}>{personPurportingToActOn}</RowItem>
    </Column>
  );
};

export default PersonPurportingToActOnColumn;
