import React from 'react';

import { PALETTE } from '../../../../../style/colorTheme';
import { LoginFields } from '../../types';
import EmailVerificationCodeInputWithRequestBtn from '../EmailVerificationCodeInputWithRequestBtn';
import { RequestEmailVerificationCodeBtnUiStyle } from '../RequestEmailVerificationCodeBtn';

interface IProps {
  title?: string;
  subtitle?: string;
  fields: LoginFields;
  setFields: React.Dispatch<React.SetStateAction<LoginFields>>;
  handleRequestVerificationCode: () => Promise<any>;
  requestCodeOnMount?: boolean;
}

const VerificationCodeCard = (props: IProps) => {
  const { title, subtitle, fields, setFields, handleRequestVerificationCode, requestCodeOnMount } =
    props;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        background: PALETTE.Primary,
        padding: '24px',
        borderRadius: '12px',

        width: '100%',
      }}
    >
      <div style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '8px', color: '#919BB4' }}>
        {title}
      </div>
      <div style={{ fontSize: '12px', marginBottom: '32px', color: '#919BB4' }}>{subtitle}</div>

      <EmailVerificationCodeInputWithRequestBtn
        fields={fields}
        setFields={setFields}
        handleRequestVerificationCode={handleRequestVerificationCode}
        noInputIcon
        btnUiStyle={RequestEmailVerificationCodeBtnUiStyle.OUTLINED}
        requestCodeOnMount={requestCodeOnMount}
      />
    </div>
  );
};

export default VerificationCodeCard;
