import { COLUMN_WIDTH } from '../../../../../constants/ColumnWidth';
import { usePermission } from '../../../../../hooks';
import { useGenGridCol } from '../../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/Table';

const useTableColumns = () => {
  const { hasPermission } = usePermission();

  const columns = [
    // useGenGridCol(TableColumnKey.PfhNumber),
    useGenGridCol(TableColumnKey.ClientId),
    useGenGridCol(TableColumnKey.Currency),
    useGenGridCol(TableColumnKey.Balance),
    useGenGridCol(TableColumnKey.FrozenBalance),
    useGenGridCol(TableColumnKey.CreationTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.LastModifiedTime, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
