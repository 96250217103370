import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import APIs from '../../api';
import { selectAgentRolesList, setAgentRolesList } from '../../reducer/agentRolesSlice';
import { useAppDispatch, useAppSelector } from '../../reducer/hooks';
import useLoading from '../useLoading';

interface IProps {
  shouldFetch?: boolean;
}

const useAgentRoles = (props?: IProps) => {
  const { shouldFetch } = props || {};
  const dispatch = useAppDispatch();
  const { list: agentRolesList } = useAppSelector(selectAgentRolesList);
  const { showLoading, hideLoading } = useLoading();

  const getAgentRolesEnum = () => {
    if (!agentRolesList) return {};

    return agentRolesList.reduce((acc, curr) => {
      const roleName = curr.roleName;
      const roleId = curr.roleId;

      return {
        ...acc,
        [roleName]: roleId,
      };
    }, {});
  };

  const getAgentRoleIdEnum = (): Record<number, string> => {
    if (!agentRolesList) return {};

    return agentRolesList.reduce((acc, curr) => {
      const roleName = curr.roleName;
      const roleId = curr.roleId;

      return {
        ...acc,
        [roleId]: roleName,
      };
    }, {});
  };

  const fetchAgentRoles = async () => {
    showLoading('getAgentRoles');
    const res = await APIs.merchantPortal.query.getRoles({});
    hideLoading('getAgentRoles');

    if (!res) return [];

    return res;
  };

  useQuery('agentRoles', fetchAgentRoles, {
    staleTime: Infinity,
    enabled: shouldFetch && (!agentRolesList || agentRolesList.length === 0),
    onSuccess: (data) => {
      dispatch(setAgentRolesList(data));
    },
  });

  const AgentRolesEnum = getAgentRolesEnum();
  const AgentRoleIdEnum = getAgentRoleIdEnum();

  return { agentRolesList, AgentRolesEnum, AgentRoleIdEnum };
};

export default useAgentRoles;
