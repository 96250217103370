export const getAgentRoleDisplayValue = (
  roles: number[],
  agentRoleIdEnum: Record<number, string>
) => {
  if (!roles || roles.length === 0) return '';

  return (
    roles
      .map((role) => {
        return agentRoleIdEnum[role];
      })
      // filter undefined values
      .filter(Boolean)
      .join(',')
  );
};
