import React, { ReactNode } from 'react';

import { Container } from '../../../components/MuiGenerals';

const FilterContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Container maxWidth={false} disableGutters>
      {children}
    </Container>
  );
};

export default FilterContainer;
