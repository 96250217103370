import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import APIs from '../../api';
import { selectAgentCurrencyList, setAgentCurrencyList } from '../../reducer/agentCurrencySlice';
import { useAppDispatch, useAppSelector } from '../../reducer/hooks';
import useLoading from '../useLoading';

interface IProps {
  shouldFetch?: boolean;
}

const useAgentCurrency = (props?: IProps) => {
  const { shouldFetch } = props || {};

  const dispatch = useAppDispatch();
  const { list: agentCurrencyList } = useAppSelector(selectAgentCurrencyList);
  const { showLoading, hideLoading } = useLoading();

  const getAgentCurrencyEnum = () => {
    if (!agentCurrencyList) return {};

    return agentCurrencyList.reduce((acc: any, curr: any) => {
      return {
        ...acc,
        [curr.currency]: JSON.stringify(curr),
      };
    }, {});
  };

  const fetchAgentCurrency = async () => {
    showLoading('getAgentCurrency');
    const res = await APIs.merchantPortal.query.getAgentCurrency();
    hideLoading('getAgentCurrency');

    if (!res) return [];

    return res;
  };

  useQuery('agentCurrency', fetchAgentCurrency, {
    staleTime: Infinity,
    enabled: shouldFetch && (!agentCurrencyList || agentCurrencyList.length === 0),
    onSuccess: (data) => {
      dispatch(setAgentCurrencyList(data));
    },
  });

  const AgentCurrencyEnum = getAgentCurrencyEnum();

  return { agentCurrencyList, AgentCurrencyEnum };
};

export default useAgentCurrency;
