import {
  ValidationType,
  ValidatorResponse
} from '../../hooks/useValidation/types';

interface ValidatorData {
  password: string;
  confirmPassword: string;
}

export const confirmPasswordValidator =
  (errorMessage: string, { password, confirmPassword }: ValidatorData) =>
  (fieldKey: string, value: string | number): ValidatorResponse => {
    const errorInfo = {
      message: errorMessage,
      fieldKey,
      value,
      validatorType: ValidationType.ConfirmPassword,
    };

    if (password !== confirmPassword) {
      return { isValid: false, errorInfo };
    }

    return { isValid: true, errorInfo };
  };
