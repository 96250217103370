import { styled } from '@mui/material';

import { COLOR_THEME } from '../style/colorTheme';
import { hexToRGB, importantStyle } from './';

export const customSx = {
  dialogBtnMargin: {
    margin: '0px 10px 10px 0px',
  },
  layoutBox: {
    backgroundColor: COLOR_THEME.CustomSx.LayoutBox.Background,
    padding: '2rem',
  },
  customeLayoutBox: {
    margin: '0px 0px 10px 0px',
    backgroundColor: 'white',
    padding: '0',
  },
  filterContainer: {
    padding: '1rem',
  },
  datagridContainer: {
    backgroundColor: COLOR_THEME.CustomSx.DatagridContainer.Background,
    color: importantStyle(COLOR_THEME.CustomSx.DatagridContainer.Text),
    paddingTop: '1rem',
  },
  filterCmdBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    marginBottom: '1rem',
    '& .MuiButton-root': {
      color: COLOR_THEME.CustomSx.DatagridContainer.ButtonText,
      borderColor: COLOR_THEME.CustomSx.DatagridContainer.ButtonBorder,
    },
  },
  tableExportBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    marginBottom: 0,
    height: 0,
    position: 'relative',
    '& .MuiButton-root': {
      color: COLOR_THEME.CustomSx.DatagridContainer.ButtonText,
      borderColor: COLOR_THEME.CustomSx.DatagridContainer.ButtonBorder,
      position: 'absolute',
      top: 8.7,
    },
  },
  createRequestBtnWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  createRequestBtn: {
    '& .MuiButton-root': {
      color: COLOR_THEME.CustomSx.DatagridContainer.Text,
      borderColor: COLOR_THEME.CustomSx.DatagridContainer.Text,
    },
  },
  gridFilter: {
    backgroundColor: COLOR_THEME.CustomSx.GridFilter.Background,
    color: COLOR_THEME.CustomSx.DatagridContainer.Text,
    display: 'grid',
    // gridTemplateColumns: 'minmax(max-content, 5rem) 3fr minmax(max-content, 5rem) 3fr',
    // alignItems: "center",
    lineHeight: '4rem',
    columnGap: '2rem',
    '& *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiInputAdornment-filled': {
      overflow: 'initial',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .datePickerObj': {
      display: 'inline',
      '& .MuiFormControl-root': {
        width: 'calc(50% - 1rem)',
      },
    },
  },
  threeColumnsgridFilter: {
    display: 'grid',
    gridTemplateColumns: '3fr 5fr 3fr 2fr 3fr 4fr',
    lineHeight: '4rem',
    columnGap: '1rem',
    '& *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiInputAdornment-filled': {
      overflow: 'initial',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .datePickerObj': {
      display: 'inline',
      '& .MuiFormControl-root': {
        width: 'calc(50% - 1rem)',
      },
    },
  },
  filterB: {
    backgroundColor: COLOR_THEME.CustomSx.FilterB.Background,

    display: 'flex',
    justifyContent: 'center',
    gap: '2rem',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  dateSelect: {
    display: 'flex',
    gap: '1rem',
    '& .MuiFormControl-root': {
      flex: 1,
    },
  },
  tableCell: {
    width: '450px',
    borderBottom: 'none',
  },
  addressValue: {
    color: '#4A90F7',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  tableRow: {
    backgroundColor: COLOR_THEME.CustomSx.TableRow.Background,
    opacity: 0.5,
    height: '40px',
  },
  operation: {
    btnMargin: {
      margin: '20px 5px 0px 20px',
    },
    operationActionBtnMargin: {
      textTransform: 'none',
      margin: '0px 5px',
      borderRadius: '15px',
      padding: '5px',
      minWidth: '90px',
      height: '25px',
      fontSize: '9px',
    },
  },
  dataGrid: {
    '*::-webkit-scrollbar': {
      height: '7px',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: `rgba(${hexToRGB(COLOR_THEME.CustomSx.DatagridContainer.Text)}, 0.5)`,
      borderRadius: '10px',
    },
  },
  monthRangePicker: {
    container: {
      display: 'flex',
      width: '100%',
      '>div': {
        width: '50%',
      },
    },
    spanText: {
      verticalAlign: 'bottom',
      fontSize: '2rem',
      margin: '0 10px',
    },
  },
};

export const ContentWithSidebar = styled('main')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
}));

export const Content = styled('div')(({ theme }) => ({
  '*::-webkit-scrollbar': {
    height: '7px',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: `rgba(${hexToRGB(COLOR_THEME.CustomSx.DatagridContainer.ScrollThumb)}, 0.5)`,
    borderRadius: '10px',
  },
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 2,
  padding: theme.spacing(4),
  width: '100%',
  paddingTop: '120px',
  // "& .MuiInputBase-root": {
  //   backgroundColor: "white",
  // },
  '& .MuiInputLabel-shrink': {
    display: 'none',
  },
  '& .resetBtn': {
    color: 'white',
    backgroundColor: '#7F9AC4',

    '&:hover': {
      backgroundColor: `rgba(${hexToRGB(COLOR_THEME.CustomSx.DatagridContainer.Text)}, 0.5)`,
    },
  },
  '& .MuiTypography-root': {
    lineHeight: 1,
  },
  '& .filterFieldInput': {
    lineHeight: '3rem',
  },
  '& .MuiFormControl-root': {
    marginTop: 0,
    marginBottom: 0,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'inherit',
    },

    '> label': {
      color: `rgba(${hexToRGB(COLOR_THEME.CustomSx.DatagridContainer.LabelText)}, 0.8)`,

      '&.Mui-disabled': {
        color: `rgba(${hexToRGB(COLOR_THEME.CustomSx.DatagridContainer.LabelText)}, 0.5)`,
      },
    },

    ':hover': {
      '>label': {
        color: COLOR_THEME.CustomSx.DatagridContainer.Text,

        '&.Mui-disabled': {
          color: `rgba(${hexToRGB(COLOR_THEME.CustomSx.DatagridContainer.Text)}, 0.5)`,
        },
      },
    },

    '.MuiFilledInput-root': {
      color: 'white',
    },
    '.MuiFilledInput-input.Mui-disabled': {
      color: '#838da4',
      WebkitTextFillColor: '#838da4',
    },
  },
}));
