import { EnumAgentType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

export const getAgentTypeDisplayValue = (
  agentType: number,
  translateFunc: (key: string) => string
) => {
  const value = EnumAgentType[agentType];

  return translateFunc(`EnumAgentType.${value}`);
};
