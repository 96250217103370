import { SingleSelection } from '../../../../components';
import { useTranslation } from '../../../../hooks';
import { EnumRebateMode } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

interface IProps {
    label?: string;
    value: any;
    onChange: (e: any) => void;
    onClear: () => void;
}

const RebateModeSingleSelection = (props: IProps) => {
    const { label, value, onChange, onClear } = props;

    const { tc, t } = useTranslation("EnumRebateMode");

    return (
        <SingleSelection
            label={label || tc('phSelection', { fieldName: tc('rebateMode') })}
            value={value || ''}
            onChange={onChange}
            clearSelect={onClear}
            enumData={EnumRebateMode}
            nameFn={(item) => t(item)}
        />
    );
};

export default RebateModeSingleSelection;
