import { useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';
import ApproveButton from './ApproveButton';
import CancelButton from './CancelButton';
import RejectButton from './RejectButton';
import RemarksTextArea from './RemarksTextArea';

const ActionButtons = ({
  handleApproveClick,
  handleRejectClick,
  closeDialog,
  remarksInput,
  handleRemarksChange,
}: any) => {
  const isMobile = useAppSelector(selectIsMobileView);

  return (
    <div
      style={{
        width: '100%',
        minHeight: '60px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: isMobile ? '0' : '0 48px',
        boxSizing: 'border-box',
        ...(isMobile && {
          flexDirection: 'column',
        }),
      }}
    >
      <RemarksTextArea value={remarksInput} onChange={handleRemarksChange} />
      <div
        style={{
          ...(isMobile && {
            width: '90%',
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: '500px',
          }),
        }}
      >
        <ApproveButton onClick={handleApproveClick} />
        <RejectButton onClick={handleRejectClick} />
        <CancelButton onClick={closeDialog} />
      </div>
    </div>
  );
};

export default ActionButtons;
