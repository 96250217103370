import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';

interface IGetExchangeRateRes {
  currentExchangeRate: string;
  nextExchangeRate: string;
  StartFrom: Date;
}

const getAll = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get<any, IGetExchangeRateRes>(
    AgentPortal.EptSetting.agentExchangeRate.getAll,
    input
  );
};
const getExport = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get<any, IGetExchangeRateRes>(
    AgentPortal.EptSetting.agentExchangeRate.export,
    input
  );
};

const postCreate = async (params: any, config?: any) => {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post<never, any>(AgentPortal.EptSetting.agentExchangeRate.create, input, {});
};
const postEdit = async (params: any, config?: any) => {
  const input = {
    ...params,
    ...config,
  };

  return await axios.post<never, any>(AgentPortal.EptSetting.agentExchangeRate.edit, input, {});
};

export default { getAll, getExport, postCreate, postEdit };
