import { useEffect } from 'react';

import { Iprefix } from '../../hooks/useTranslation';
import { COLOR_THEME } from '../../style/colorTheme';
import { IZusParams, useZusDialogStore, useZusTranslatePrefixStore } from '../../zustand/store';
import { Box } from '../MuiGenerals';
import DivideLine from './DivideLine';
import { useAppSelector } from '../../reducer/hooks';
import { selectIsMobileView } from '../../reducer/mediaSlice';

function FilterTable({
  filter,
  table,
  dialog,
  translatePrefix,
  zusParams,
}: {
  filter: JSX.Element;
  table: JSX.Element;
  dialog?: JSX.Element;
  translatePrefix: Iprefix;
  zusParams: IZusParams<any>;
}) {
  const { setTranslatePrefix } = useZusTranslatePrefixStore();
  const zusDialog = useZusDialogStore();

  const isMobile = useAppSelector(selectIsMobileView);

  useEffect(() => {
    setTranslatePrefix(translatePrefix);
    return () => {
      zusParams.clear();
      dialog && zusDialog.close();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: COLOR_THEME.Layout.FilterTable.Background,
        paddingTop: '2rem',
        paddingLeft: isMobile ? '1rem' : '2rem',
        paddingRight: isMobile ? '1rem' : '2rem',
      }}
    >
      {dialog}
      {filter}
      <DivideLine />
      {table}
    </Box>
  );
}

export default FilterTable;
