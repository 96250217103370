export enum TableColumnKey {
  Operation = 'operations',
  CreationTime = 'creationTime',
  LastModifiedTime = 'lastModifiedTime',

  TaskId = 'taskId',
  ApprovalProgress = 'approvalProgress',
  Month = 'month',
  ProgramName = 'programName',
  Currency = 'currency',
  RebateCurrency = 'rebateCurrency',
  RebateExchangeRate = 'rebateExchangeRate',
  TotalDistributorAgentRebateAmount = 'totalDistributorAgentRebateAmount',
  TotalClientRebateAmount = 'totalClientRebateAmount',
  NetTotalDistributorAgentRebateAmount = 'netTotalDistributorAgentRebateAmount',
  Status = 'status',
  Step = 'step',
}

export interface TableRow {
  id: number;

  [TableColumnKey.TaskId]: string;
  [TableColumnKey.ApprovalProgress]: string;
  [TableColumnKey.Month]: string;
  [TableColumnKey.ProgramName]: string;
  [TableColumnKey.Currency]: string;
  [TableColumnKey.RebateCurrency]: string;
  [TableColumnKey.RebateExchangeRate]: string;
  [TableColumnKey.TotalDistributorAgentRebateAmount]: string;
  [TableColumnKey.TotalClientRebateAmount]: string;
  [TableColumnKey.NetTotalDistributorAgentRebateAmount]: string;
  [TableColumnKey.Status]: string;
  [TableColumnKey.Step]: string;
  [TableColumnKey.CreationTime]: string;
  [TableColumnKey.LastModifiedTime]: string | undefined;

  rawData: any;
}
