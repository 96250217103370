import { ApiResult } from '../../../../api/types';
import { useTranslation } from '../../../../hooks';
import { roundDown } from '../../../../utils/math';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';
import { getExchangeRateConfigurationAdjustmentTypeDisplayValue } from './getExchangeRateConfigurationAdjustmentTypeDisplayValue';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { translate } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      const adjustmentType = getExchangeRateConfigurationAdjustmentTypeDisplayValue(
        row.adjustType,
        translate
      );

      const exchangeRate = roundDown(row.exchangeRate, 6);
      const adjustmentAmount = row.adjustAmount && roundDown(row.adjustAmount, 6);
      const adjustedExchangeRate = roundDown(row.adjustedExchangeRate, 6);

      return {
        id: index,
        [TableColumnKey.programName]: row.programName,
        [TableColumnKey.from]: row.fromCurrency,
        [TableColumnKey.to]: row.toCurrency,
        [TableColumnKey.currentExchangeRate]: exchangeRate,
        [TableColumnKey.adjustmentType]: adjustmentType,
        [TableColumnKey.adjustmentAmount]: adjustmentAmount,
        [TableColumnKey.exchangeRateAfterAdjustment]: adjustedExchangeRate,
        // [TableColumnKey.createdBy]: row.createdBy,
        // [TableColumnKey.createdDate]: toDisplayTime(row.createdDate),
        // [TableColumnKey.lastModifiedBy]: row.lastModifiedBy,
        // [TableColumnKey.lastModifiedTime]: toDisplayTime(row.lastModifiedDate),
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
