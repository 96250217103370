export enum ActiveTabs {
  Purchase = '3',
  Refund = '4',
  Withdraw = '6',
}

export enum CountryRegion {
  All = 'all',
  HK = 'HK',
  GB = 'GB',
  Oversea = 'Oversea',
}

export enum Currency {
  All = 'all',
  HKD = 'HKD',
  NonHKD = 'NonHKD',
}

export interface FeeSetting {
  fix?: string;
  percent?: string;
}

export interface FeeSettings {
  localAgentMarkup: FeeSetting;
  overseaAgentMarkup: FeeSetting;
  local: FeeSetting;
  oversea: FeeSetting;
  gb: FeeSetting;
}

export interface FeeAdjustmentRes {
  cardAcceptorCountryCode: 'HK' | 'GB' | '';
  acquirerCurrency: 'HKD' | '';
  e6Fee: string;
  endDate: string;
  feePercent: string;
  feeSettings: FeeSettings | null;
  programName: string;
  startDate: string;
  transactionType: number;
}
