import { ApiResult } from '../../../../../../api/types';
import { useTranslation } from '../../../../../../hooks';
import useProgramDisplayName from '../../../../../../hooks/useProgramDisplayName';
import useSystemCurrency from '../../../../../../hooks/useSystemCurrency';
import { displayAmountCurrying, toDisplayTime } from '../../../../../../utils';
import { convertDateTimeStringToFormat } from '../../../../../../utils/dateUtils';
import { padZero } from '../../../../../../utils/math';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey, TableRow } from '../types/Table';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { te, tb, tc } = useTranslation();
  // const { CurrencyDecimalsMapping } = useSystemCurrency();
  const { getProgramDisplayName } = useProgramDisplayName();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      // const decimals = CurrencyDecimalsMapping[row.currency] || 0;
      // const toDisplayAmount = displayAmountCurrying(2);

      const programDisplayName = getProgramDisplayName(row.programName);

      return {
        id: index,
        [TableColumnKey.SettlementDate]: row.settlementDate
          ? convertDateTimeStringToFormat(row.settlementDate, 'yyyy-MM-dd')
          : '',
        [TableColumnKey.DistributorAgentId]: row.distributorAgentId || '',
        [TableColumnKey.DistributorAgentName]: row.distributorAgentName || '',
        [TableColumnKey.PfhNumber]: row.customerNumber || '',
        [TableColumnKey.ProgramName]: programDisplayName || '',

        [TableColumnKey.Currency]: row.currency || '',
        [TableColumnKey.Repayment]: row.repayment || '',
        [TableColumnKey.TotalTransactionAmount]: row.totalTransactionAmount || '',

        [TableColumnKey.LocalPayment]: row.localPayment || '',
        [TableColumnKey.LocalPaymentPfHCost]: row.localPaymentPfhCost || '',
        [TableColumnKey.LocalPaymentPaMarkUp]: row.localPaymentPaMarkup || '',

        [TableColumnKey.LocalWithdraw]: row.localWithdraw || '',
        [TableColumnKey.LocalWithdrawPfhCost]: row.localWithdrawPfhCost || '',
        [TableColumnKey.LocalWithdrawPaMarkup]: row.localWithdrawPaMarkup || '',

        [TableColumnKey.LocalRefund]: row.localRefund || '',
        [TableColumnKey.LocalRefundPfhCost]: row.localRefundPfhCost || '',
        [TableColumnKey.LocalRefundPaMarkup]: row.localRefundPaMarkup || '',

        [TableColumnKey.OverseasPayment]: row.overseaPayment || '',
        [TableColumnKey.OverseasPaymentPfhCost]: row.overseaPaymentPfhCost || '',
        [TableColumnKey.OverseasPaymentPaMarkUp]: row.overseaPaymentPaMarkup || '',

        [TableColumnKey.OverseasWithdraw]: row.overseaWithdraw || '',
        [TableColumnKey.OverseasWithdrawPfhCost]: row.overseaWithdrawPfhCost || '',
        [TableColumnKey.OverseasWithdrawPaMarkup]: row.overseaWithdrawPaMarkup || '',

        [TableColumnKey.OverseasRefund]: row.overseaRefund || '',
        [TableColumnKey.OverseasRefundPfhCost]: row.overseaRefundPfhCost || '',
        [TableColumnKey.OverseasRefundPaMarkup]: row.overseaRefundPaMarkup || '',

        [TableColumnKey.TotalPfhCost]: row.totalPfhCost || '',
        [TableColumnKey.TotalPaMarkup]: row.totalPaMarkup || '',

        [TableColumnKey.AnnualFee]: row.annualFee || '',
        [TableColumnKey.VisaRebate]: row.visaRebate || '',
        [TableColumnKey.PaTotalRebate]: row.paTotalRebate || '',

        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
