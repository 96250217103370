import { useTranslation } from '../../../../../../hooks';
import { Iprefix } from '../../../../../../hooks/useTranslation';
import useValidation from '../../../../../../hooks/useValidation';
import { ValidationConfigProps } from '../../../../../../hooks/useValidation/types';
import { largerThanZeroValidator } from '../../../../../../utils/validators/largerThanZeroValidator';
import { requiredValidator } from '../../../../../../utils/validators/requiredValidator';
import { CreateRequestFields } from '../types';

interface IProps {
  fields: CreateRequestFields;
}

const useCreateRequestValidation = (props: IProps) => {
  const { fields } = props;
  const translatePrefix = 'creditAllocation' as Iprefix;

  const { translate, t } = useTranslation(translatePrefix);

  const validationConfig: ValidationConfigProps = [
    {
      key: 'distributorAgentId',
      value: fields['distributorAgentId'],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('distributorAgentId'),
          })
        ),
      ],
    },
    {
      key: 'currency',
      value: fields['currency'],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('currency'),
          })
        ),
      ],
    },
    {
      key: 'transactionAmount',
      value: fields['transactionAmount'],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('transactionAmount'),
          })
        ),
        largerThanZeroValidator(
          translate('validation.shouldBeLargerThanZero', {
            fieldName: t('transactionAmount'),
          })
        ),
      ],
    },
  ];

  return useValidation(validationConfig);
};

export default useCreateRequestValidation;
