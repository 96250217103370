import React, { useState } from 'react';

import APIs from '../../../../../api';
import { ApiResult } from '../../../../../api/types';
import Table from '../../../../../common/filterTable/components/Table';
import FilterContainer from '../../../../../common/filterTable/layout/FilterContainer';
import FilterTableLayoutContainer from '../../../../../common/filterTable/layout/FilterTableLayoutContainer';
import TableContainer from '../../../../../common/filterTable/layout/TableContainer';
import useNewFilterTablePage from '../../../../../common/page/hooks/useNewFilterTablePage';
import { ExportBtn } from '../../../../../components/Button';
import { useDatePicker } from '../../../../../components/DatePicker';
import { DivideLine } from '../../../../../components/Layout';
import { Iprefix as IPrefix } from '../../../../../hooks/useTranslation';
import FilterSection from './components/FilterSection';
import useGetListParams from './helpers/useGetListParams';
import useTableColumns from './helpers/useTableColumns';
import useTableRows from './helpers/useTableRows';
import { FilterFields, FilterFieldsKeys } from './types/FilterFields';
import { GetListRes } from './types/GetListRes';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
import SearchFirstDataGridOverlay from '../../../../../components/Layout/SearchFirstDataGridOverlay';

interface IClientTabProps { }

const ClientTab: React.FC<IClientTabProps> = (props) => {
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    lastMonth.setDate(1);
    lastMonth.setHours(0, 0, 0, 0);
    const initFields = {
        [FilterFieldsKeys.monthFrom]: lastMonth.toISOString(),
        [FilterFieldsKeys.monthTo]: lastMonth.toISOString(),
        [FilterFieldsKeys.distributorAgentId]: '',
        [FilterFieldsKeys.programName]: '',
        [FilterFieldsKeys.pfhNo]: '',
        [FilterFieldsKeys.rebateMode]: '',
    };

    const translatePrefix = 'monthlyRebateReport.clientTab' as IPrefix;
    const { getListParams } = useGetListParams();

    const settlementDateStart = new Date();
    settlementDateStart.setHours(0, 0, 0, 0);
    const DateObj = {
        SettlementDate: useDatePicker(
            {
                start: {
                    defaultDate: settlementDateStart,
                },
                isEndOfSecond: false,
            }
        ),
    };

    const [isFirstSearch, setIsFirstSearch] = useState(true);
    const getTableDataApiFunc = (params: any) => {
        if (isFirstSearch) {
            return;
        }
        return APIs.report.monthlyRebateReport.clientReport.getAll(params);
    };
    const exportTableApiFunc = APIs.report.monthlyRebateReport.clientReport.getExport;

    const pageConfig = {
        initFields,
        DateObj,
        translatePrefix,
        getListParams,
        getTableDataApiFunc,
        exportTableApiFunc,
    };

    const {
        page,
        onPageChange,
        fields,
        setFields,
        list,
        onFilterSearch,
        onFilterReset,
        onExport,
        getIsHavePermission,
        Tabs,
    } = useNewFilterTablePage<FilterFields, ApiResult<GetListRes>>(pageConfig);

    const { rows, totalCounts, convertToTableRows } = useTableRows({ apiList: list });
    const columns = useTableColumns();

    const isShowExportBtn = getIsHavePermission(AgentPortalFeatureCode.Report.MonthlyRebateReport.ClientReportTab.Export);

    const handleExport = async () => {
        await onExport('Client Monthly Rebate Report', totalCounts, convertToTableRows);
    };
    return <>
        <FilterTableLayoutContainer>
            <FilterContainer>
                <FilterSection
                    fields={fields}
                    setFields={setFields}
                    onSearch={() => { onFilterSearch(); setIsFirstSearch(false); }}
                    onReset={() => { onFilterReset(); DateObj.SettlementDate.resetDate(); }}
                    DateObj={DateObj}
                />
            </FilterContainer>

            <DivideLine />

            <TableContainer>
                <ExportBtn onExport={handleExport} isShow={isShowExportBtn} />
                <Tabs>
                    <Table
                        columns={columns}
                        rows={rows}
                        rowCount={totalCounts}
                        page={page}
                        onPageChange={onPageChange}
                        customNoRowOverlay={isFirstSearch ? SearchFirstDataGridOverlay : undefined}
                    />
                </Tabs>
            </TableContainer>
        </FilterTableLayoutContainer>
    </>;
}

export default ClientTab;
