import { DataGrid, GridColumns, GridColumnsInitialState, GridRowsProp } from '@mui/x-data-grid';

import { IlistResult } from '../../api/types';
import { useAlerting, usePermission, useTranslation } from '../../hooks';
import { downloadFiles, getFullApiResponse } from '../../utils';
import { dataGridColumnConfig } from '../../utils/ComponentHelper';
import { dataGridDefaults } from '../../utils/constant';
import { customSx } from '../../utils/styling';
import { IZusParams } from '../../zustand/store';
import { ExportBtn } from '../Button';
import { Container } from '../MuiGenerals';
import CustomPagination from './CustomPagination';
import NoRowsOverlay from './NoRowsOverlay';
import TableTab from './TableTab';

export default function TableTemplate({
  exportBtn,
  zusParams,
  count,
  columns,
  content,
  visibilityConfigArr,
  otherColumnsConfig,
}: {
  exportBtn?: {
    fileName: string;
    permissionKey: string;
    apiFn: (params: any, config?: any) => Promise<IlistResult<any>>;
    omitKeys: string[];
    mappingFn: (array: any[], omitKeys?: string[]) => any[];
  };
  zusParams: IZusParams<any>;
  count: number;
  content: GridRowsProp;
  columns: GridColumns;
  visibilityConfigArr?: {
    fieldName: string;
    hasPermission: boolean;
  }[];
  otherColumnsConfig?: Omit<GridColumnsInitialState, 'columnVisibilityModel'> | undefined;
}) {
  const { hasPermission } = usePermission();
  const { tc } = useTranslation();
  const { alerting } = useAlerting();

  const onExport = async () => {
    if (!exportBtn) {
      return;
    }
    if (count === 0) {
      return alerting('error', tc('no_data_export'));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      exportBtn.apiFn({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;

    const res = exportBtn.mappingFn(rawRes, exportBtn.omitKeys);
    const config = {};
    downloadFiles(exportBtn.fileName, res, config);
  };

  return (
    <Container style={customSx.datagridContainer} maxWidth={false} disableGutters>
      {exportBtn && (
        <ExportBtn isShow={hasPermission(exportBtn.permissionKey)} onExport={onExport} />
      )}
      <TableTab>
        <DataGrid
          {...dataGridDefaults}
          key={zusParams.body.page}
          initialState={
            (visibilityConfigArr || otherColumnsConfig) &&
            dataGridColumnConfig(visibilityConfigArr, otherColumnsConfig)
          }
          rows={content}
          rowCount={count}
          columns={columns}
          page={zusParams.body.page}
          onPageChange={zusParams.setPage}
          components={{
            NoRowsOverlay,
            Footer: CustomPagination,
          }}
          componentsProps={{
            footer: { totalRecords: count },
          }}
        />
      </TableTab>
    </Container>
  );
}
