import { enumLanguage } from '../../utils/constant';
import { IYubiDetails } from '../../zustand/types';
import axios from '../axiosInstance';
import { Itimezone } from '../types';

const endpointUserProfile = { ...{ getAdmin: '', upsert: '' } }; // TODO: use real endpoint

const getUserProfile = async (config?: any) => {
  const res = await axios.get<
    never,
    {
      adminPortalId: string;
      id: string | null;
      timezone: Itimezone;
      lang: keyof typeof enumLanguage;
      email: string;
      dateCreated: Date;
      dateLastModified: Date;
    }
  >(endpointUserProfile.getAdmin, config);
  return res;
};

const upsertUserProfile = async (
  params: { lang: keyof typeof enumLanguage; timezone?: string },
  config?: any
) => {
  const input = { ...params, ...config };
  const res = await axios.post<
    never,
    {
      adminPortalId: string;
      id: string | null;
      timezone: Itimezone;
      lang: keyof typeof enumLanguage;
      email: string;
      dateCreated: Date;
      dateLastModified: Date;
    }
  >(endpointUserProfile.upsert, input);
  return res;
};

// import { IYubiDetails } from "../zustand/types";
// let refreshTokenTime: Date | undefined;
// function setRefreshTokenTime(now: Date) {
//   refreshTokenTime = addMinutes(now, 37); // hard limit is 36 mins
// }

const getMyYubiKeyStaus = async () => {
  const res = await axios.get<never, IYubiDetails>('/merchant_portal/portal_users/my_details');
  return res;
};

export default { getUserProfile, upsertUserProfile, getMyYubiKeyStaus };
