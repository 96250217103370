import { useTranslation } from '../../../hooks';
import useValidation from '../../../hooks/useValidation';
import { ValidationConfigProps } from '../../../hooks/useValidation/types';
import { confirmPasswordValidator } from '../../../utils/validators/confirmPasswordValidator';
import { lengthLimitValidator } from '../../../utils/validators/lengthLimitValidator';
import { requiredNumberAmount } from '../../../utils/validators/requiredNumberAmount';
import { requiredSymbolValidator } from '../../../utils/validators/requiredSymbolValidator';
import { requiredUpperCaseLetterValidator } from '../../../utils/validators/requiredUpperCaseLetterValidator';
import { requiredValidator } from '../../../utils/validators/requiredValidator';

interface IProps {
  fields: {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
  };
}

const useChangePasswordValidation = (props: IProps) => {
  const { fields } = props;

  const { translate, t } = useTranslation('changePassword');

  const validationConfig: ValidationConfigProps = [
    {
      key: 'currentPassword',
      value: fields.currentPassword,
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('currentPassword'),
          })
        ),
      ],
    },
    {
      key: 'newPassword',
      value: fields.newPassword,
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('newPassword'),
          })
        ),
        lengthLimitValidator(
          translate('validation.requiredLength', {
            fieldName: t('newPassword'),
            min: 8,
            max: 40,
          }),
          {
            min: 8,
            max: 40,
          }
        ),
        requiredUpperCaseLetterValidator(
          translate('validation.requiredUpperCase', {
            fieldName: t('newPassword'),
            min: 1,
          }),
          {
            min: 1,
          }
        ),
        requiredSymbolValidator(translate('validation.requiredSymbol')),
        requiredNumberAmount(
          translate('validation.requiredContainNumber', {
            fieldName: t('newPassword'),
            min: 1,
          }),
          { min: 1 }
        ),
      ],
    },
    {
      key: 'confirmNewPassword',
      value: fields.confirmNewPassword,
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('confirmPassword'),
          })
        ),
        confirmPasswordValidator(translate('validation.confirmPassword'), {
          password: fields.newPassword,
          confirmPassword: fields.confirmNewPassword,
        }),
      ],
    },
  ];

  return useValidation(validationConfig);
};

export default useChangePasswordValidation;
