export enum TableColumnKey {
  Operation = 'operations',
  CreationTime = 'creationTime',
  LastModifiedTime = 'lastModifiedTime',

  OrderId = 'orderId',
  ApprovalProgress = 'approvalProgress',
  DistributorAgentId = 'distributorAgentId',
  Currency = 'currency',
  TransactionAmount = 'transactionAmount',
  Status = 'status',
  Remarks = 'remarks',
  CreatedBy = 'createdBy',
}

export interface TableRow {
  id: number;

  [TableColumnKey.OrderId]: string;
  [TableColumnKey.ApprovalProgress]: string;
  [TableColumnKey.DistributorAgentId]: string;
  [TableColumnKey.Currency]: string;
  [TableColumnKey.TransactionAmount]: string;
  [TableColumnKey.Status]: string;
  [TableColumnKey.CreatedBy]: string;
  [TableColumnKey.CreationTime]: string;
  [TableColumnKey.LastModifiedTime]: string | undefined;

  rawData: any;
}
