import AmlDetailsDialog from './AmlDetailsDialog';
import UploadedDocumentsDialog from './UploadedDocumentsDialog';

export default function Dialog() {
  return (
    <>
      <AmlDetailsDialog />
      <UploadedDocumentsDialog />
    </>
  );
}
