import { useCallback, useEffect, useState } from 'react';

import { SingleSelection } from '../../../../components';
import { SelectChangeEvent } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';
import useAgentCurrency from '../../../../hooks/useAgentCurrency';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const AgentCurrencySingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc } = useTranslation();

  const { AgentCurrencyEnum } = useAgentCurrency();

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('currency') })}
      value={value && Object.keys(value).length > 0 ? JSON.stringify(value) : ''}
      clearSelect={onClear}
      onChange={onChange}
      enumData={AgentCurrencyEnum}
      nameFn={(item) => item}
    />
  );
};

export default AgentCurrencySingleSelection;
