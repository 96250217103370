import React, { useMemo } from 'react';

import { Box } from '../../../MuiGenerals';
import { COLOR_THEME } from '../../../../style/colorTheme';
import DoughnutChart from '../../../Chart/DoughnutChart';
import { useAppSelector } from '../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../reducer/mediaSlice';

interface ITopItemsDoughnutChartProps {
  data: any[];
  legendData: any[];
  dataKey: string;
  onMouseEnter?: (event: any, data: any) => void;
  width?: number;
  height?: number;
}

const TopItemsDoughnutChart: React.FC<ITopItemsDoughnutChartProps> = ({
  data,
  dataKey,
  legendData,
  onMouseEnter,
  width = 200,
  height = 200,
}) => {
  const isMobile = useAppSelector(selectIsMobileView);

  return !data || data.length === 0 || legendData.length === 0 ? (
    <></>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        height: '100%',
        ...(isMobile && { alignItems: 'center' }),
      }}
    >
      <DoughnutChart data={data} dataKey={dataKey} height={height} width={width} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'space-between',
          width: '100%',
          height: '90%',
          padding: isMobile ? '24px 0 0' : '0',
        }}
      >
        {legendData.map((entry, index) => {
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 2,
              }}
              key={index}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <span style={{ fontSize: '1rem' }}>{index + 1}.</span>
                <Box
                  sx={{
                    width: '13px',
                    height: '13px',
                    backgroundColor:
                      COLOR_THEME.CustomSx.Chart.DoughnutChart.ChartColor[
                        index % COLOR_THEME.CustomSx.Chart.DoughnutChart.ChartColor.length
                      ],
                    borderRadius: '50%',
                    marginRight: '5px',
                    ml: 1,
                    marginTop: '0.3rem',
                  }}
                />
                <Box>
                  <span style={{ fontSize: '1rem' }}>{entry.name}</span>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <span style={{ marginBottom: '0.2rem', fontSize: '1rem' }}> {entry.value}</span>
                {entry.label && (
                  <Box>
                    <span
                      style={{ fontSize: '0.7rem', color: COLOR_THEME.CustomSx.Dashboard.label }}
                    >
                      {entry.label}
                    </span>
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default TopItemsDoughnutChart;
