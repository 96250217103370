import React, { ReactNode, SetStateAction } from 'react';

import ResetButton from '../../../../../../common/filterTable/components/Buttons/ResetButton';
import SearchButton from '../../../../../../common/filterTable/components/Buttons/SearchButton';
import Filters from '../../../../../../common/filterTable/components/Filters/Filters';
import FilterActions from '../../../../../../common/filterTable/layout/FilterActions';
import FilterGrid from '../../../../../../common/filterTable/layout/FilterGrid';
import { useGenFilterField } from '../../../../../../utils/ComponentHelper';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import ProgramNameSingleSelection from '../../../../../../common/filterTable/components/Filters/ProgramNameSingleSelection';
import MonthRangePicker from '../../../../../../components/MonthRangePicker';
import { generateLimitedYearDateTime } from '../../../../../../utils/dateUtils';
import ReportCurrencySingleSelection from '../../../../../../common/filterTable/components/Filters/ReportCurrencySingleSelection';
import AgentProgramDisplayNameSingleSelection from '../../../../../../common/filterTable/components/Filters/AgentProgramDisplayNameSingleSelection';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  const filterFields: ReactNode[] = useGenFilterField([
    [
      'month',
      <MonthRangePicker
        startMonth={fields[FilterFieldsKeys.settlementMonthFrom]}
        endMonth={fields[FilterFieldsKeys.settlementMonthTo]}
        onChangeStartMonth={(value) => updateField(FilterFieldsKeys.settlementMonthFrom, value)}
        onChangeEndMonth={(value) => updateField(FilterFieldsKeys.settlementMonthTo, value)}
        defaultStartMonth={lastMonth}
        defaultEndMonth={lastMonth}
        minMonth={generateLimitedYearDateTime(2)}
        maxMonth={lastMonth}
      />,
    ],
    [
      FilterFieldsKeys.programName,
      <AgentProgramDisplayNameSingleSelection
        value={fields[FilterFieldsKeys.programName]}
        onChange={(e) => updateField(FilterFieldsKeys.programName, e.target.value)}
        onClear={() => updateField(FilterFieldsKeys.programName, '')}
      />,
    ],
    [
      FilterFieldsKeys.currency,
      <ReportCurrencySingleSelection
        value={fields[FilterFieldsKeys.currency]}
        onChange={(e) => updateField(FilterFieldsKeys.currency, e.target.value)}
        onClear={() => updateField(FilterFieldsKeys.currency, 'HKD')}
      />,
    ],
  ]);

  return (
    <>
      <FilterGrid>
        <Filters filterFields={filterFields} />
      </FilterGrid>
      <FilterActions>
        <SearchButton onSearch={onSearch} />
        <ResetButton onReset={onReset} />
      </FilterActions>
    </>
  );
};

export default FilterSection;
