import { SingleSelection } from '../../../../components';
import { useTranslation } from '../../../../hooks';
import { CountryRegion } from '../../../../views/ProgramAgentManagement/FeeAdjustmentRate/types';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

const FeeAdjustmentRateCountrySingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc, t } = useTranslation('feeAdjustmentRate');

  const Enum = {
    [t('all')]: CountryRegion.All,
    [t('hk')]: CountryRegion.HK,
    [t('oversea')]: CountryRegion.Oversea,
    [t('gb')]: CountryRegion.GB,
  };

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: t('countryRegion') })}
      value={value || ''}
      onChange={onChange}
      clearSelect={onClear}
      enumData={Enum}
      nameFn={(name) => name}
      isNoSorting
    />
  );
};

export default FeeAdjustmentRateCountrySingleSelection;
