import BigNumber from 'bignumber.js';

export const safeAdd = (num1: number | string, num2: number | string) => {
  return BigNumber(num1).plus(num2).toString();
};

export const safeMultiply = (num1: number | string, num2: number | string) => {
  return BigNumber(num1).multipliedBy(num2).toString();
};

export const safeDivide = (num1: number | string, num2: number | string) => {
  return BigNumber(num1).dividedBy(num2).toString();
};

export const roundDown = (value: number | string, decimal: number = 2) => {
  const multiplier = Math.pow(10, decimal);

  return String(safeDivide(Math.floor(Number(safeMultiply(value, multiplier))), multiplier));
};

export const getDecimalDigits = (value: string): number => {
  const parts = value.split('.');
  return parts.length > 1 ? parts[1].length : 0;
};

export const padZero = (number: number | string, amountOfZero: number = 2) => {
  const parts = String(number).split('.');

  if (parts.length < 2) {
    // No decimal part, add .00
    return `${number}.00`;
  } else {
    // Has decimal part, add trailing zeros if necessary
    return `${parts[0]}.${parts[1].padEnd(amountOfZero, '0')}`;
  }
};
