import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IgetAllProgram } from '../api/types';
import { RootState } from './store';

type IprogramSliceState = {
  allPrograms: Record<string, string>;
  rawAllPrograms: IgetAllProgram[];
};

const initialState: IprogramSliceState = {
  allPrograms: {},
  rawAllPrograms: [],
};

const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    setAllProgram: (state, action: PayloadAction<IgetAllProgram[]>) => {
      state.allPrograms = action.payload.reduce((acc: Record<string, string>, { programName }) => {
        // hide empty program name value
        if (programName === '' || acc[programName]) {
          return acc;
        }

        return {
          ...acc,
          [programName]: programName,
        };
      }, {});
    },
    setRawAllProgram: (state, action: PayloadAction<IgetAllProgram[]>) => {
      state.rawAllPrograms = action.payload;
    },
  },
});

export const { reducer: programReducer } = programSlice;

export const selectProgram = (state: RootState) => state.program;

export const selectRawAllProgram = (state: RootState) => state.program.rawAllPrograms;

export const { setAllProgram, setRawAllProgram } = programSlice.actions;
