import './index.css';

import { useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Typography } from '@mui/material';

import { forceLogout } from '../../../api/axiosInstance';
import { OpSettingComponent } from '../../../assets/icons';
import { useAutoLogout, useIsActive, useTranslation } from '../../../hooks';
import useAgentCurrency from '../../../hooks/useAgentCurrency';
import useAgentProgramCurrency from '../../../hooks/useAgentProgramCurrency';
import useAgentRoles from '../../../hooks/useAgentRoles';
import useMedia from '../../../hooks/useMedia';
import { useFetchProgram } from '../../../hooks/useProgram';
import useSystemCurrency from '../../../hooks/useSystemCurrency';
import { useAppDispatch, useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';
import { selectProfile } from '../../../reducer/profileSlice';
import { selectStuff, setIsRefreshingToken } from '../../../reducer/stuffSlice';
import { COLOR_THEME } from '../../../style/colorTheme';
import {
  enumMapping,
  getIsRedirectLoginPath,
  importantStyle
} from '../../../utils';
import { enumTimezone } from '../../../utils/constant';
import { Content, ContentWithSidebar } from '../../../utils/styling';
import { LanguageSelection } from '../../../views/User/AccountSetting';
import { useZusMenuCollapsedStore } from '../../../zustand/store';
import { OpIconButtonAdjustable } from '../../Button';
import LogoutButton from '../../Button/LogoutButton';
import LoadingDialogProvider from '../LoadingDialogProvider';
import Menu from '../Menu';
import SideMenu from '../SideMenu';

const Enum = {
  timezones: enumMapping(enumTimezone),
};
const sx: Record<string, any> = {
  AppBarSpan: {
    display: 'flex',
    backgroundColor: 'white',
  },
  SideBarParent: {
    minHeight: '100vh',
    zIndex: 2,
  },
  topRightBar: {
    position: 'absolute',
    height: '28px',
    top: '1rem',
    right: '.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    '& Button.MuiButton-text': {
      fontSize: '1rem',
    },
  },
  topLeftBar: {
    position: 'absolute',
    top: '1rem',
    left: '345px',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: '6px 8px',
  },
  pageName: {
    position: 'absolute',
    top: '4.5rem',
    left: '345px',
    padding: '6px 8px',
  },
  email: {
    color: COLOR_THEME.Layout.LayoutBar.Text,
    fontSize: '1rem',
  },
  appName: {
    fontSize: '1rem',
  },
} as const;

export default function LayoutBar(props: any) {
  const { t, te, tc } = useTranslation('menu');
  const [isSideMenuCollapse, setIsSideMenuCollapse] = useState(false);
  const [isShowSideMenuInMobileView, setIsShowSideMenuInMobileView] = useState(false);
  const zusMenuCollapsed = useZusMenuCollapsedStore();

  const dispatch = useAppDispatch();
  const { appName, email, timezone, jwtDetails } = useAppSelector(selectProfile);
  const { programAgentId } = jwtDetails || {};

  const { viewPermission } = useAppSelector(selectStuff);
  const { isRefreshingToken } = useAppSelector(selectStuff);
  const profile = useAppSelector(selectProfile);
  const isLogin = profile.isLogged;

  const redirect = useRedirect();

  useMedia();
  useAutoLogout();
  useIsActive();

  useFetchProgram({ shouldFetch: isLogin });
  useAgentRoles({ shouldFetch: isLogin });
  useSystemCurrency({ shouldFetch: isLogin });
  useAgentCurrency({ shouldFetch: isLogin });
  useAgentProgramCurrency({ shouldFetch: isLogin });

  // useAccountSetting() // only call once

  const timezoneDisplay = `GMT${te(Enum.timezones[timezone])}`;

  const isMobileView = useAppSelector(selectIsMobileView);

  // prevent isRefreshingToken dead lock
  useEffect(() => {
    const TIMEOUT_SECOND = 10 * 1000;

    let timeoutId: ReturnType<typeof setTimeout>;

    if (isRefreshingToken) {
      timeoutId = setTimeout(() => {
        dispatch(setIsRefreshingToken(false));
      }, TIMEOUT_SECOND);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isRefreshingToken]);

  const logoutFn = () => {
    forceLogout('', { from: 'menu-button' });
  };

  const onCollapseClick = () => {
    setIsSideMenuCollapse(!isSideMenuCollapse);
  };

  const getSafePixelWithMenu = () => {
    const desktopStyle = isSideMenuCollapse ? '106px' : '345px';
    const mobileStyle = '24px';
    // const mobileStyle = isShowSideMenuInMobileView
    //   ? isSideMenuCollapse
    //     ? '106px'
    //     : '345px'
    //   : '24px';

    return isMobileView ? mobileStyle : desktopStyle;
  };

  const getTopMenuHeaderStyle = () => {
    return {
      left: getSafePixelWithMenu(),
      transition: 'left 0.3s 0.1s cubic-bezier(0.0, 0.0, 0.2, 1)',
    };
  };

  // adapt to side menu position
  const topMenuHeaderStyle = getTopMenuHeaderStyle();

  useEffect(() => {
    if (isMobileView) {
      setIsShowSideMenuInMobileView(false);
      setIsSideMenuCollapse(false);
    } else {
      setIsShowSideMenuInMobileView(true);
    }
  }, [isMobileView]);

  useEffect(() => {
    zusMenuCollapsed.setIsMenuCollapsed(isSideMenuCollapse);
  }, [isSideMenuCollapse]);

  if (getIsRedirectLoginPath()) return <>{props.children}</>;

  return (
    <>
      <LoadingDialogProvider isLoadingDialogOpen={isRefreshingToken} />
      {/* a container tried to prevent overflow when menu is open */}
      <div
        // style={{
        //   ...(isShowSideMenuInMobileView && isMobileView
        //     ? { height: '100dvh', overflow: 'hidden' }
        //     : {}),
        // }}
        className={
          isShowSideMenuInMobileView && isMobileView ? 'content-with-sidebar-container' : ''
        }
      >
        <ContentWithSidebar>
          {isLogin && (
            <>
              {/* Side Menu */}
              {isShowSideMenuInMobileView && (
                <Box style={{ ...sx.SideBarParent }}>
                  <SideMenu
                    isCollapse={isSideMenuCollapse}
                    onCollapseClick={onCollapseClick}
                    isMobileView={isMobileView}
                    // isShowCollapseButton={!isMobileView}
                  >
                    <Menu
                      closeMobileSideMenu={() => {
                        if (!isMobileView) return;

                        setIsShowSideMenuInMobileView(false);
                      }}
                    />
                  </SideMenu>
                </Box>
              )}

              {/* Black overlay for mobile side menu */}
              {isMobileView && isShowSideMenuInMobileView && !isSideMenuCollapse && (
                <div
                  // style={{
                  //   position: 'absolute',
                  //   width: '100vw',
                  //   height: '100dvh',
                  //   background: 'rgba(0,0,0,0.8)',
                  //   zIndex: 1,
                  //   transition: 'opacity 0.2s cubic-bezier(0.0, 0.0, 0.2, 1)',
                  // }}
                  className="side-menu-overlay"
                  onClick={() => {
                    setIsShowSideMenuInMobileView(false);
                  }}
                />
              )}

              <Box style={{ ...sx.topLeftBar, ...topMenuHeaderStyle }}>
                {/* side menu icon for mobile */}
                {isMobileView && (
                  <Box
                    sx={{
                      width: '27px',
                      height: '27px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      transform: 'translateY(-2px)',
                      svg: {
                        width: '100%',
                        height: '100%',
                      },
                    }}
                    onClick={() => {
                      setIsShowSideMenuInMobileView(!isShowSideMenuInMobileView);
                      setIsSideMenuCollapse(false);
                    }}
                  >
                    <MenuIcon />
                  </Box>
                )}
                {/* Program Agent Id  */}
                <Typography variant="caption" sx={sx.appName}>
                  {programAgentId}
                </Typography>
              </Box>

              <Box sx={sx.topRightBar}>
                {!isMobileView && (
                  <>
                    <LanguageSelection />

                    {/* timezone */}
                    <Box
                      onClick={() => redirect('/accountSetting')}
                      sx={{
                        padding: '.3rem .7rem',
                        backgroundColor: COLOR_THEME.Layout.LayoutBar.LogoutBtn.Background,
                        color: COLOR_THEME.Layout.LayoutBar.LogoutBtn.Text,
                        borderRadius: '.5rem',
                        cursor: 'pointer',
                        height: '100%',
                      }}
                    >
                      {timezoneDisplay}
                    </Box>

                    {/* welcome, (email) */}
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Box sx={{ marginRight: '4px' }}>{tc('welcome')},</Box>
                      <Box sx={sx.email}>{email}</Box>
                    </Typography>
                  </>
                )}

                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...(isMobileView ? { padding: '0 12px' } : {}),
                  }}
                >
                  {/* Gear Icon */}
                  <Box sx={{ marginLeft: '8px', width: '16px', height: '16px' }}>
                    <OpIconButtonAdjustable
                      title={''}
                      size={'1rem'}
                      SvgElement={OpSettingComponent}
                      onClick={() => redirect('/accountSetting')}
                      sxSVG={{
                        fill: COLOR_THEME.Layout.LayoutBar.SecondaryText,
                        path: {
                          fill: COLOR_THEME.Layout.LayoutBar.SecondaryText,
                        },
                      }}
                    />
                  </Box>
                </Box>

                {/* Logout button */}
                <LogoutButton isHide={isMobileView} />
              </Box>
              <div style={{ ...sx.pageName, ...topMenuHeaderStyle }}>
                <Typography
                  variant="h6"
                  sx={{ color: importantStyle(COLOR_THEME.Layout.LayoutBar.Text) }}
                >
                  {viewPermission && t(viewPermission)}
                </Typography>
              </div>
            </>
          )}
          <Content
            style={isMobileView ? { paddingLeft: '0', paddingRight: '0', overflowX: 'hidden' } : {}}
          >
            {props.children}
          </Content>
        </ContentWithSidebar>
      </div>
    </>
  );
}
