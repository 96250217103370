import CrossIcon from '../../Icon/CrossIcon';

const CrossButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      style={{ position: 'absolute', right: '60px', top: '20px', cursor: 'pointer' }}
      onClick={onClick}
    >
      <CrossIcon />
    </div>
  );
};

export default CrossButton;
