import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import APIs from '../../../../api';
import ButtonMenu from '../../../../components/Button/ButtonMenu';
import TableTemplate from '../../../../components/Layout/TableTemplate';
import { usePermission } from '../../../../hooks';
import { useGenTableColumns } from '../../../../hooks/useTable';
import useTranslation, { Iprefix } from '../../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../../zustand/store';
import { useListMapping, useZusParams } from '../hooks';
import {
  editDialogTranslateKey as EditTK,
  filterTableTranslateKey as TableTK
} from '../mappings/translate';
import { IapiGetAllRes } from '../types';

const api = {
  table: APIs.setting.distributorAgentProgram.getAll,
  export: APIs.setting.distributorAgentProgram.getExport,
};
export default function Table() {
  const { hasPermission } = usePermission();
  const translatePrefix: Iprefix = 'distributorAgentProgram';
  const exportFileName = 'Distributor Agent Program';
  const exportPermissionKey = AgentPortalFeatureCode.Setting.DistributorAgentProgram.Export;
  const editPermissionKey = AgentPortalFeatureCode.Setting.DistributorAgentProgram.Edit;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const { t, tc } = useTranslation(translatePrefix);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const tableMapping = useListMapping('table');
  const exportMapping = useListMapping('export');

  const omitKeys: string[] = [];

  const content: GridRowsProp = tableMapping(rows, omitKeys);

  const renderOperationCell = useCallback((params: GridRenderCellParams) => {
    const getAllData = params.row['rawData'] as IapiGetAllRes;

    return (
      <ButtonMenu
        title={t(TableTK.operation)}
        options={[
          {
            name: t(EditTK.editBtn),
            onClickFn: () => zusDialog.open('editRequestDialog', getAllData),
          },
        ]}
      />
    );
  }, []);

  const { columns } = useGenTableColumns([
    { translateKey: TableTK.operation, renderCell: renderOperationCell },
    { translateKey: TableTK.distributorAgentId, minWidth: 150 },
    { translateKey: TableTK.programDisplayName, minWidth: 150 },
    { translateKey: TableTK.programName, minWidth: 150 },
    { translateKey: TableTK.rebateMode, minWidth: 150 },
    { translateKey: TableTK.rebateRateToDistributorAgentAndClient, minWidth: 150 },
    { translateKey: TableTK.percentageToDistributorAgent, minWidth: 150 },
    { translateKey: TableTK.percentageToClient, minWidth: 150 },
    { translateKey: TableTK.status },
    { translateKey: TableTK.createdBy },
    { translateKey: TableTK.creationTime },
    { translateKey: TableTK.lastModifiedBy },
    { translateKey: TableTK.lastModifiedTime },
  ]);

  return (
    <TableTemplate
      exportBtn={{
        fileName: exportFileName,
        permissionKey: exportPermissionKey,
        apiFn: api.export,
        omitKeys: ['rawData'],
        mappingFn: exportMapping,
      }}
      visibilityConfigArr={[
        { fieldName: TableTK.operation, hasPermission: hasPermission(editPermissionKey) },
      ]}
      zusParams={zusParams}
      count={count}
      content={content}
      columns={columns}
    />
  );
}
