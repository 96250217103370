import { Currency } from '../../../../../../common/types/Currency';

export enum FilterFieldsKeys {
  settlementMonthFrom = 'settlementMonthFrom',
  settlementMonthTo = 'settlementMonthTo',
  DistributorAgentId = 'distributorAgentId',
  PfhNumber = 'pfhNumber',
  ProgramName = 'programName',
}

export interface FilterFields {
  [FilterFieldsKeys.settlementMonthFrom]: string;
  [FilterFieldsKeys.settlementMonthTo]: string;
  [FilterFieldsKeys.DistributorAgentId]: string;
  [FilterFieldsKeys.PfhNumber]: string;
  [FilterFieldsKeys.ProgramName]: string;
}
