import React, { ReactNode, SetStateAction, useEffect } from 'react';

import ResetButton from '../../../../../../common/filterTable/components/Buttons/ResetButton';
import SearchButton from '../../../../../../common/filterTable/components/Buttons/SearchButton';
import Filters from '../../../../../../common/filterTable/components/Filters/Filters';
import FilterActions from '../../../../../../common/filterTable/layout/FilterActions';
import FilterGrid from '../../../../../../common/filterTable/layout/FilterGrid';
import { useGenFilterField } from '../../../../../../utils/ComponentHelper';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import ProgramNameSingleSelection from '../../../../../../common/filterTable/components/Filters/ProgramNameSingleSelection';
import ReportCurrencySingleSelection from '../../../../../../common/filterTable/components/Filters/ReportCurrencySingleSelection';

interface FilterSectionProps {
    fields: FilterFields;
    setFields: React.Dispatch<SetStateAction<FilterFields>>;
    onSearch: () => void;
    onReset: () => void;
    DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
    const { fields, setFields, onSearch, onReset, DateObj } = props;

    const updateField = (fieldName: string, value: any) => {
        setFields((fields) => {
            return {
                ...fields,
                [fieldName]: value,
            };
        });
    };
    useEffect(() => {
        updateField(FilterFieldsKeys.settlementDateFrom, DateObj.SettlementDate.start);
        updateField(FilterFieldsKeys.settlementDateTo, DateObj.SettlementDate.end);
    }, [DateObj.SettlementDate.start, DateObj.SettlementDate.end]);

    const filterFields: ReactNode[] = useGenFilterField([
        ['settlementDate', <DateObj.SettlementDate.Picker type="date" />],
        [FilterFieldsKeys.programName, <ProgramNameSingleSelection value={fields[FilterFieldsKeys.programName]}
            onChange={(e) => updateField(FilterFieldsKeys.programName, e.target.value)}
            onClear={() => updateField(FilterFieldsKeys.programName, "")} />],
        [
            FilterFieldsKeys.currency,
            <ReportCurrencySingleSelection
                value={fields[FilterFieldsKeys.currency]}
                onChange={(e) => updateField(FilterFieldsKeys.currency, e.target.value)}
                // Currency is Required, so currently will use the string, maybe change to Enum in the future
                onClear={() => updateField(FilterFieldsKeys.currency, "HKD")}
            />,
        ],
    ]);

    return (
        <>
            <FilterGrid>
                <Filters filterFields={filterFields} />
            </FilterGrid>
            <FilterActions>
                <SearchButton onSearch={onSearch} />
                <ResetButton onReset={onReset} />
            </FilterActions>
        </>
    );
};

export default FilterSection;
