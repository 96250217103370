import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import { DialogInOne } from '../../../../../components';
import { IdialogInOneProps } from '../../../../../components/DialogInOne';
import PermissionTab from '../../../../../components/Layout/PermissionTab';
import { useTranslation } from '../../../../../hooks';
import { Iprefix } from '../../../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../../../zustand/store';
import DetailTabContent from './DetailTabContent';
import FinancialsTab from './FinancialsTab';

// import { IdetailApiRes } from '../../types';

export default function DetailsDialog() {
  const translatePrefix: Iprefix = 'clientList';

  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();

  const onClose = async () => {
    await zusDialog.close();
  };

  const dialogConfig: IdialogInOneProps = {
    title: '',
    self: {
      open: zusDialog.match('detailsDialog'),
      onClose: onClose,
    },
    content: (
      <PermissionTab
        contentArr={[
          {
            translatePrefix: `${translatePrefix}.detailsTab.title` as Iprefix,

            element: <DetailTabContent />,
          },
          // Financial Tab
          {
            translatePrefix: `${translatePrefix}.financialTab.title` as Iprefix,
            element: <FinancialsTab />,
          },
        ]}
        tabsArr={[
          {
            name: t('detailsTab.title'),
            value: `${translatePrefix}.detailsTab.title` as Iprefix,
            permissionCode: AgentPortalFeatureCode.ClientManagement.ClientList.Details.prefix,
          },
          {
            name: t('financialTab.title'),
            value: `${translatePrefix}.financialTab.title` as Iprefix,
            permissionCode:
              AgentPortalFeatureCode.ClientManagement.ClientList.Details.FinancialsTab,
          },
        ]}
        forceTranslatePrefix={translatePrefix}
        styleConfig={{
          backgroundColor: 'transparent',
        }}
      />
    ),
    onConfirm: () => {},
    isConfirmHidden: true,
    onCancel: onClose,
    cancelButtonText: tc('close'),
    size: 'xl',
    dialogHeight: {
      height: '92vh',
      maxHeight: '796px',
    },
  };

  return <DialogInOne {...dialogConfig} />;
}
