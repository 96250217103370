import React from 'react';

import { FormHelperText } from '@mui/material';

import APIs from '../../../../../api';
import { PartialSearchDistributorAgentIdResponse } from '../../../../../api/types';
import {
  Container,
  Row,
  RowContent,
  RowHeader
} from '../../../../../common/dialogs/layout/contentLayout';
import DistributorAgentIdAutoComplete from '../../../../../common/filterTable/components/Filters/DistributorAgentIdAutoComplete';
import SystemCurrencySingleSelection from '../../../../../common/filterTable/components/Filters/SystemCurrencySingleSelection';
import TransactionAmountTextField from '../../../../../common/filterTable/components/Filters/TransactionAmountTextField';
import { SelectChangeEvent } from '../../../../../components/MuiGenerals';
import { useTranslation } from '../../../../../hooks';
import useLoading from '../../../../../hooks/useLoading';
import { Iprefix } from '../../../../../hooks/useTranslation';
import { useAppSelector } from '../../../../../reducer/hooks';
import { selectProfile } from '../../../../../reducer/profileSlice';
import { SystemCurrency } from '../../../../../reducer/systemCurrencySlice';
import { displayAmountCurrying } from '../../../../../utils';
import { CreateRequestFields } from './types';

interface IProps {
  fields: CreateRequestFields;
  setFields: React.Dispatch<React.SetStateAction<CreateRequestFields>>;
  balance: string | null;
  setBalance: React.Dispatch<React.SetStateAction<string | null>>;
}

const Content = (props: IProps) => {
  const { fields, setFields, balance, setBalance } = props;
  const { jwtDetails } = useAppSelector(selectProfile);

  const { programAgentId } = jwtDetails || {};

  const translatePrefix = 'creditAllocation' as Iprefix;

  const { t } = useTranslation(translatePrefix);

  const [distributorAgentIdOptions, setDistributorAgentIdOptions] = React.useState<
    PartialSearchDistributorAgentIdResponse[]
  >([]);

  const balanceDisplayValue = t('availableBalance', { balance: balance ?? '0' });

  const { showLoading, hideLoading } = useLoading();

  const handleDistributorAgentIdInputChange = async (value: string) => {
    setFields((fields) => ({ ...fields, distributorAgentId: value }));

    if (value) {
      const res = await APIs.setting.userSetting.partialSearchDistributorAgentId({
        keywords: value.toUpperCase(),
        limit: 10,
        offset: 0,
      });

      if (!res) return;

      setDistributorAgentIdOptions(res);
    }
  };

  const handleDistributorAgentIdOptionChange = (value: string) => {
    setFields((fields) => ({ ...fields, distributorAgentId: value }));
  };

  const fetchBalance = async (props: { currency: string; tokenName: string }) => {
    const { currency, tokenName } = props;

    showLoading('getBalance');
    const res = await APIs.distributorAgentManagement.creditAllocation.getBalance({
      currency: currency,
      tokenName: tokenName,
      clientId: programAgentId || '',
    });
    hideLoading('getBalance');

    if (!res) return;

    return res;
  };

  const handleCurrencyChange = async (e: SelectChangeEvent) => {
    const currencyObj = JSON.parse(e.target.value);

    setFields((fields) => ({ ...fields, currency: currencyObj }));

    const { currency, tokenName } = currencyObj;

    const res = await fetchBalance({ currency, tokenName });

    // expect only will have one item in array when provided currency and tokenName
    const result = res?.[0];
    const toDisplayAmount = displayAmountCurrying(result?.decimals || '');

    setBalance(toDisplayAmount(result?.totalBalance || '0'));
  };

  const handleTransactionAmountChange = (e: React.ChangeEvent<any>) => {
    // only allow number and max 2 decimal
    const value = e.target.value;
    const regex = /^\d*\.?\d{0,2}$/;

    if (!regex.test(value)) return;

    setFields((fields) => ({ ...fields, transactionAmount: value }));
  };

  return (
    <>
      <Container>
        <Row>
          <RowHeader>{t('distributorAgentId')}</RowHeader>
          <RowContent>
            <DistributorAgentIdAutoComplete
              value={fields.distributorAgentId}
              options={distributorAgentIdOptions}
              onInputChange={(value: string) => {
                handleDistributorAgentIdInputChange(value);
              }}
              handleChange={(option: PartialSearchDistributorAgentIdResponse) => {
                handleDistributorAgentIdOptionChange(option?.distributorAgentId);
              }}
              setOptions={setDistributorAgentIdOptions}
            />
          </RowContent>
        </Row>
        <Row>
          <RowHeader>{t('currency')}</RowHeader>
          <RowContent>
            <SystemCurrencySingleSelection
              value={fields.currency}
              onChange={handleCurrencyChange}
              onClear={() => {
                setFields((fields) => ({
                  ...fields,
                  currency: {} as SystemCurrency,
                }));
                setBalance(null);
              }}
              tokenNameFilter={'HKDM'}
            />
            {balance && (
              <FormHelperText sx={{ color: '#FFFFFF', marginTop: '8px', paddingLeft: '12px' }}>
                {balanceDisplayValue}
              </FormHelperText>
            )}
          </RowContent>
        </Row>
        <Row>
          <RowHeader>{t('transactionAmount')}</RowHeader>
          <RowContent>
            <TransactionAmountTextField
              value={fields.transactionAmount}
              onChange={handleTransactionAmountChange}
            />
          </RowContent>
        </Row>
      </Container>
    </>
  );
};

export default Content;
