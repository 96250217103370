import { DialogInOne } from '../../../components';
import { useTranslation } from '../../../hooks';
import { ApproveHistory } from '../types/ApproveHistory';
import ApprovalProgressDialogContent from './ApprovalProgressDialogContent';

interface ApprovalProgressDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  title?: string;
  content?: React.ReactNode;
  id: string;
  getApproveHistory: ({ id }: { id: string }) => Promise<ApproveHistory[]>;
}

const ApprovalProgressDialog = (props: ApprovalProgressDialogProps) => {
  const { title, isOpen, closeDialog, id, getApproveHistory } = props;
  const { tc } = useTranslation();

  const content = props.content || <ApprovalProgressDialogContent id={id} getApproveHistory={getApproveHistory} />;

  const dialogConfig = {
    title: title || tc('approvalProgressDialogTitle'),
    self: {
      open: isOpen,
      onClose: () => {
        closeDialog();
      },
    },
    content: content,
    onConfirm: () => { },
    onCancel: () => {
      closeDialog();
    },
    isLoadingDialog: true,
    isConfirmHidden: true,
    size: 'md' as any,
  };

  return <DialogInOne {...dialogConfig} />;
};

export default ApprovalProgressDialog;
