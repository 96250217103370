export const getIsBitSet = (bitwiseValue: number, bitPosition: number) => {
  const isBitSet = (bitwiseValue >> bitPosition) & 1;
  return Boolean(isBitSet);
};

export const joinArrayToString = (arr: string[]) => {
  return arr.join(', ');
};

const cleanArrayEmptyValue = (arr: string[]) => {
  return arr.filter((item) => item !== '' && item !== null && item !== undefined);
};

export const getBitWiseRepresentation = (valueArr: string[], bitWiseValue: number) => {
  const result = valueArr.map((item, index) => {
    if (getIsBitSet(bitWiseValue, index)) {
      return item;
    }

    return '';
  });

  return cleanArrayEmptyValue(result);
};
