const filterTableTranslateKey = {
  orderId: 'order_id',
  orderType: 'order_type',
  from: 'from',
  to: 'to',
  currency: 'currency',
  transactionAmount: 'transaction_amount',
  postBalance: 'post_balance',
  ledgerTransactionId: 'ledger_transaction_id',
  remarks: 'remarks',
  creationTime: 'creation_time',
};

export { filterTableTranslateKey };
