import { Currency } from '../../../../../../common/types/Currency';

export enum FilterFieldsKeys {
  settlementDateFrom = 'settlementDateFrom',
  settlementDateTo = 'settlementDateTo',
  programName = 'programName',
  currency = 'currency',
}

export interface FilterFields {
  [FilterFieldsKeys.settlementDateFrom]: string;
  [FilterFieldsKeys.settlementDateTo]: string;
  [FilterFieldsKeys.programName]: string;
  [FilterFieldsKeys.currency]: string;
}
