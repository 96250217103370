import { format, parseISO } from 'date-fns';
import { enUS, zhCN } from 'date-fns/locale';

export function convertDateTimeStringToFormat(dateTimeString: string, formatStr: string) {
  if (!dateTimeString) {
    return '';
  }
  const parsedDate = parseISO(dateTimeString); // Convert string to Date object

  const formattedDate = format(parsedDate, formatStr); // Format date

  return formattedDate;
}

export function convertDateStringOrDateToYearMonth(date: string | Date) {
  if (!date) {
    return '';
  }
  if (typeof date === 'string') {
    return convertDateTimeStringToFormat(date, 'yyyy-MM');
  } else if (date instanceof Date) {
    return format(date, 'yyyy-MM');
  }
  return '';
}

export function generateLimitedMonthDateTime(limitedMonth: number) {
  const date = new Date();
  date.setMonth(date.getMonth() - limitedMonth);
  return date;
}

export function generateLimitedYearDateTime(limitedYear: number) {
  const date = new Date();
  date.setFullYear(date.getFullYear() - limitedYear);
  return date;
}

export function convertDateStringToLocaleDateString(dateString: string, locale: string) {
  if (!dateString) {
    return '';
  }
  const date = parseISO(dateString);
  let formattedDate = '';

  if (locale === 'en') {
    formattedDate = format(date, 'dd MMM', { locale: enUS });
  } else if (locale === 'zh_cn') {
    formattedDate = format(date, 'M月d日', { locale: zhCN });
  }

  return formattedDate;
}
