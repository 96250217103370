import React, { useContext } from 'react';

import { EnumVerificationType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Access';

import { LoginContext } from '../';
import { useAlerting, useTranslation } from '../../../../hooks';
import EmailVerificationCodeInputWithRequestBtn from '../components/EmailVerificationCodeInputWithRequestBtn';
import PageWrapper from '../components/PageWrapper';
import useEmailCodeApi from '../helpers/useEmailCodeApi';
import useEmailVerificationValidation from '../helpers/useEmailVerificationValidation';
import { LoginFieldsKeys, PageKeys } from '../types';

interface IProps {}

const EmailVerification = (props: IProps) => {
  const loginContext = useContext(LoginContext);

  const { fields, setFields, setPage } = loginContext;

  const { alerting } = useAlerting();
  const { validate } = useEmailVerificationValidation({ fields });
  const { t } = useTranslation('login');

  const { requestEmailVerificationCode, verifyEmailVerificationCode } = useEmailCodeApi();

  const onSubmit = async () => {
    const validationResult = validate();

    if (validationResult.allErrors.length > 0) {
      alerting('error', validationResult.allErrors[0]);
      return;
    }
    const res = await verifyEmailVerificationCode(fields[LoginFieldsKeys.EmailVerificationCode]);

    if (!res) return;

    setPage(PageKeys.ResetPassword);
  };

  const handleRequestVerificationCode = async () => {
    return await requestEmailVerificationCode({
      email: fields[LoginFieldsKeys.Email],
      type: EnumVerificationType.AgentResetPassword,
    });
  };

  return (
    <PageWrapper onSubmit={onSubmit}>
      <div className="login-field">
        <EmailVerificationCodeInputWithRequestBtn
          fields={fields}
          setFields={setFields}
          handleRequestVerificationCode={handleRequestVerificationCode}
          requestCodeOnMount={true}
        />
      </div>
    </PageWrapper>
  );
};

export default EmailVerification;
