import { PageMode } from '../types';
import CreateRecordButton from './CreateRecordButton';

export const CreateButtonView = ({
  onClick,
  isHavePermission,
}: {
  onClick: () => void;
  isHavePermission: boolean;
}) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '240px',
      }}
    >
      {isHavePermission ? <CreateRecordButton onClick={onClick} /> : 'No Record Found'}
    </div>
  );
};
