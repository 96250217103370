import { AxiosRequestConfig } from 'axios';

import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';
import { EnumCustomerDocumentType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import {
  IapiGetAllRes,
  Idocument
} from '../../views/ClientManagement/ClientList/types';
import axios from '../axiosInstance';
import { IlistResult } from '../types';

const getAll = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get<any, IlistResult<IapiGetAllRes>>(
    AgentPortal.EPtClientManagement.clientList.getAll,
    input
  );
};

const getExport = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get<any, IlistResult<IapiGetAllRes>>(
    AgentPortal.EPtClientManagement.clientList.export,
    input
  );
};

const getOffering = async (params: { customerNumber: string }, config?: any) => {
  const input = {
    params,
    ...config,
  };

  return await axios.get<any, any>(AgentPortal.EPtClientManagement.clientList.offering, input);
};

const getDocumentList = async (params: { customerNumber: string }, config?: AxiosRequestConfig) => {
  const input = {
    params,
    ...config,
  };

  return await axios.get<any, { customerDocuments: Idocument[] | null | undefined }>(
    AgentPortal.EPtClientManagement.clientList.getDocument,
    input
  );
};
const getDocumentFile = async (
  params: { applicationNumber: string; type: EnumCustomerDocumentType },
  config?: AxiosRequestConfig
) => {
  const input = {
    params,
    ...config,
  };

  return await axios.get<any, any>(AgentPortal.EPtClientManagement.clientList.viewDocument, input);
};

const getIdNumber = async (params: { customerNumber: string }, config?: AxiosRequestConfig) => {
  const input = {
    params,
    ...config,
  };

  return await axios.get<any, any>(AgentPortal.EPtClientManagement.clientList.idDecrypt, input);
};

const getDetail = async (params: { customerNumber: string }, config?: AxiosRequestConfig) => {
  const input = {
    params,
    ...config,
  };

  return await axios.get<any, any>(AgentPortal.EPtClientManagement.clientList.info, input);
};

export default {
  getAll,
  getOffering,
  getExport,
  document,
  getIdNumber,
  getDetail,
  getDocumentList,
  getDocumentFile,
};
