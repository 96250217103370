import React, { ReactNode } from 'react';

import Title from './Title';
import Filters from './Filters';
import Wrapper from '../Wrapper';

const FilterCard = ({ title, children }: { title?: string; children: ReactNode }) => {
  return (
    <Wrapper>
      <Title value={title} />
      <Filters>{children}</Filters>
    </Wrapper>
  );
};

export default FilterCard;
