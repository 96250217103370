import { FilterTable } from '../../../components/Layout';
import { Iprefix } from '../../../hooks/useTranslation';
import Dialog from './components/Dialog';
import Filter from './components/Filter';
import Table from './components/Table';
import { useZusParams } from './hooks';

const ClientList = () => {
  const translatePrefix: Iprefix = 'clientList';
  const zusParams = useZusParams();
  return (
    // <></>
    <FilterTable
      dialog={<Dialog />}
      filter={<Filter />}
      table={<Table />}
      translatePrefix={translatePrefix}
      zusParams={zusParams}
    />
  );
};

export default ClientList;
