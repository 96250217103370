import { RefObject } from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';

import { inputCharacterMaxLength } from '../../utils/constant';

type MpTextFieldProps = TextFieldProps & {
  innerRef?: RefObject<HTMLDivElement>;
  showEmailKeyboard?: boolean;
  showDecimalKeyboard?: boolean;
  showNumericKeyboard?: boolean;
};
export default function MpTextField(props: MpTextFieldProps) {
  const { innerRef, showEmailKeyboard, showDecimalKeyboard, showNumericKeyboard, ...rest } = props;

  const getInputMode = () => {
    if (showEmailKeyboard) {
      return 'email';
    }

    if (showDecimalKeyboard) {
      return 'decimal';
    }

    if (showNumericKeyboard) {
      return 'numeric';
    }

    return 'text';
  };

  const getPattern = () => {
    if (showDecimalKeyboard || showNumericKeyboard) {
      return '[0-9]*';
    }

    return '';
  };

  const inputMode = getInputMode() as any;
  const pattern = getPattern();

  const newProps = {
    ...rest,
    ref: innerRef,
    inputProps: {
      maxLength: inputCharacterMaxLength,
      inputMode,
      pattern,
      ...props.inputProps,
    },
  };

  return <TextField {...newProps} />;
}
