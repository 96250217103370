import React, { ReactNode, SetStateAction, useEffect } from 'react';

import ResetButton from '../../../../../../common/filterTable/components/Buttons/ResetButton';
import SearchButton from '../../../../../../common/filterTable/components/Buttons/SearchButton';
import AgentProgramDisplayNameSingleSelection from '../../../../../../common/filterTable/components/Filters/AgentProgramDisplayNameSingleSelection';
import Filters from '../../../../../../common/filterTable/components/Filters/Filters';
import PfhNumberTextFields from '../../../../../../common/filterTable/components/Filters/PfhNumberTextFields';
import FilterActions from '../../../../../../common/filterTable/layout/FilterActions';
import FilterGrid from '../../../../../../common/filterTable/layout/FilterGrid';
import DaIdAutoCompleteFilter from '../../../../../../components/DaIdAutoCompleteFilter';
import { useGenFilterField } from '../../../../../../utils/ComponentHelper';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  useEffect(() => {
    updateField(FilterFieldsKeys.settlementDateFrom, DateObj.SettlementDate.start);
    updateField(FilterFieldsKeys.settlementDateTo, DateObj.SettlementDate.end);
  }, [DateObj.SettlementDate.start, DateObj.SettlementDate.end]);

  const filterFields: ReactNode[] = useGenFilterField([
    ['settlementDate', <DateObj.SettlementDate.Picker type="date" />],
    [
      FilterFieldsKeys.distributorAgentId,
      <DaIdAutoCompleteFilter
        value={fields[FilterFieldsKeys.distributorAgentId]}
        onChange={(value) => updateField(FilterFieldsKeys.distributorAgentId, value)}
        onClear={() => updateField(FilterFieldsKeys.distributorAgentId, '')}
      />,
    ],
    [
      FilterFieldsKeys.programName,
      <AgentProgramDisplayNameSingleSelection
        value={fields[FilterFieldsKeys.programName]}
        onChange={(e) => updateField(FilterFieldsKeys.programName, e.target.value)}
        onClear={() => updateField(FilterFieldsKeys.programName, '')}
      />,
    ],
    [
      FilterFieldsKeys.pfhNo,
      <PfhNumberTextFields
        value={fields[FilterFieldsKeys.pfhNo]}
        onChange={(e) => updateField(FilterFieldsKeys.pfhNo, e.target.value)}
      />,
    ],
  ]);

  return (
    <>
      <FilterGrid>
        <Filters filterFields={filterFields} />
      </FilterGrid>
      <FilterActions>
        <SearchButton onSearch={onSearch} />
        <ResetButton onReset={onReset} />
      </FilterActions>
    </>
  );
};

export default FilterSection;
