export enum TableColumnKey {
  Month = 'month',
  SettlementDate = 'settlementDate',

  DistributorAgentId = 'distributorAgentId',
  DistributorAgentName = 'distributorAgentName',
  PfhNumber = 'pfhNumber',
  ProgramName = 'programName',
  Currency = 'currency',
  Repayment = 'repayment',
  TotalTransactionAmount = 'totalTransactionAmount',

  LocalPayment = 'localPayment',
  LocalPaymentPfHCost = 'localPaymentPfhCost',
  LocalPaymentPaMarkUp = 'localPaymentPaMarkUp',

  LocalWithdraw = 'localWithdrawal',
  LocalWithdrawPfhCost = 'localWithdrawalPfhCost',
  LocalWithdrawPaMarkup = 'localWithdrawalPaMarkup',

  LocalRefund = 'localRefund',
  LocalRefundPfhCost = 'localRefundPfhCost',
  LocalRefundPaMarkup = 'localRefundPaMarkup',

  OverseasPayment = 'overseasPayment',
  OverseasPaymentPfhCost = 'overseasPaymentPfhCost',
  OverseasPaymentPaMarkUp = 'overseasPaymentPaMarkUp',

  OverseasWithdraw = 'overseasWithdraw',
  OverseasWithdrawPfhCost = 'overseasWithdrawPfhCost',
  OverseasWithdrawPaMarkup = 'overseasWithdrawPaMarkup',

  OverseasRefund = 'overseasRefund',
  OverseasRefundPfhCost = 'overseasRefundPfhCost',
  OverseasRefundPaMarkup = 'overseasRefundPaMarkup',

  TotalPfhCost = 'totalPfhCost',
  TotalPaMarkup = 'totalPaMarkup',

  AnnualFee = 'annualFee',
  VisaRebate = 'visaRebate',
  PaTotalRebate = 'paTotalRebate',
}

export interface TableRow {
  id: number;
  [TableColumnKey.Month]: string;
  [TableColumnKey.DistributorAgentId]: string;
  [TableColumnKey.DistributorAgentName]: string;
  [TableColumnKey.PfhNumber]: string;
  [TableColumnKey.ProgramName]: string;
  [TableColumnKey.Currency]: string;
  [TableColumnKey.Repayment]: string;
  [TableColumnKey.TotalTransactionAmount]: string;

  [TableColumnKey.LocalPayment]: string;
  [TableColumnKey.LocalPaymentPfHCost]: string;
  [TableColumnKey.LocalPaymentPaMarkUp]: string;

  [TableColumnKey.LocalWithdraw]: string;
  [TableColumnKey.LocalWithdrawPfhCost]: string;
  [TableColumnKey.LocalWithdrawPaMarkup]: string;

  [TableColumnKey.LocalRefund]: string;
  [TableColumnKey.LocalRefundPfhCost]: string;
  [TableColumnKey.LocalRefundPaMarkup]: string;

  [TableColumnKey.OverseasPayment]: string;
  [TableColumnKey.OverseasPaymentPfhCost]: string;
  [TableColumnKey.OverseasPaymentPaMarkUp]: string;

  [TableColumnKey.OverseasWithdraw]: string;
  [TableColumnKey.OverseasWithdrawPfhCost]: string;
  [TableColumnKey.OverseasWithdrawPaMarkup]: string;

  [TableColumnKey.OverseasRefund]: string;
  [TableColumnKey.OverseasRefundPfhCost]: string;
  [TableColumnKey.OverseasRefundPaMarkup]: string;

  [TableColumnKey.TotalPfhCost]: string;
  [TableColumnKey.TotalPaMarkup]: string;

  [TableColumnKey.AnnualFee]: string;
  [TableColumnKey.VisaRebate]: string;
  [TableColumnKey.PaTotalRebate]: string;

  rawData: any;
}
