import { useCallback, useEffect, useState } from 'react';

import { SingleSelection } from '../../../../components';
import { SelectChangeEvent } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';
import { EnumAgentType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';
import { removeKeysFromEnum } from '../../../../helper/removeKeysFromEnum';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const AgentTypeSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc, t } = useTranslation('EnumAgentType');

  const getEnumAgentType = () => {
    const enumAgentType = removeKeysFromEnum(EnumAgentType, {
      valuesToRemove: [-1],
    });

    return enumAgentType;
  };

  const Enum = getEnumAgentType();

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('agentType') })}
      value={value || ''}
      clearSelect={onClear}
      onChange={onChange}
      enumData={Enum}
      nameFn={(item) => t(item)}
    />
  );
};

export default AgentTypeSingleSelection;
