import {
  EptCustomerManagement,
  EptQuery,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';

import axios from '../axiosInstance';
import { CardInfo, CustomerLevel, IprogramList, Network, RewardRate } from '../types';
import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';
import { Role } from '../../reducer/agentRolesSlice';

const endpointQuery = { ...EptQuery.query };

const getProgramList = async () => {
  const res = await axios.get<never, IprogramList[]>(endpointQuery.merchantPrograms);
  return res;
};
const getAssetList = async () => {
  const res = await axios.get<never, any[]>(endpointQuery.asset);
  return res;
};
const getExchangeRate = async (
  params: {
    customerNumber: string;
    programName: string;
    fromCurrency: string;
    toCurrency: string;
  },
  config?: any
) => {
  const input = { params, ...config };

  const res = await axios.get<never, any[]>(endpointQuery.exchangeRate, input);
  return res;
};

const getCustomerLevels = async (programName: string) => {
  const res = await axios.get<never, CustomerLevel[]>(EptQuery.customerLevel.customerLevel, {
    params: {
      programName,
    },
  });
  return res;
};

const getRewardRateByProgramName = ({ programName }: { programName: string }) => {
  return axios.get<never, RewardRate[]>(EptQuery.rewardRateConfig.rewardRate, {
    params: {
      programName,
    },
  });
};

interface IGetRewardCurrencyByProgramName {
  id: number;
  merchantId: number;
  programName: string;
  rewardCurrency: string;
  wmMerchantId: number;
  wmRewardClientId: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
}

const getRewardCurrencyByProgramName = ({ programName }: { programName: string }) => {
  return axios.get<never, IGetRewardCurrencyByProgramName[]>(EptQuery.rewardConfig.rewardCurrency, {
    params: {
      programName,
    },
  });
};

const getProgramNameAndCardDetails = ({ customerNumber }: { customerNumber: string }) => {
  return axios.get<never, CardInfo[]>(EptCustomerManagement.card.getCardList, {
    params: {
      customerNumber,
    },
  });
};

const getNetworkList = ({ programName }: { programName: string }) => {
  return axios.get<never, Network[]>(EptQuery.query.network, { params: { programName } });
};

const getPrograms = () => {
  return axios.get<never, { programs: any[] }>('agent-portal/query/programs');
};

//

const getRoles = async (params: any, config?: any) => {
  const input = {
    params: {
      ...params,
    },
    ...config,
  };

  return await axios.get<any, Role[]>(AgentPortal.EptQuery.auth.getRoleId, input);
};

const getAgentCurrency = () => {
  return axios.get<never, any>(AgentPortal.EptQuery.assets.agentSupportedAssets);
};

const getAgentProgramCurrency = () => {
  return axios.get<never, any>(AgentPortal.EptQuery.agent.programs);
};

export default {
  getProgramList,
  getAssetList,
  getExchangeRate,
  getCustomerLevels,
  getRewardRateByProgramName,
  getRewardCurrencyByProgramName,
  getProgramNameAndCardDetails,
  getNetworkList,
  getPrograms,
  getRoles,
  getAgentCurrency,
  getAgentProgramCurrency,
};
