import {
  EnumRebateType,
  EnumRebateStatus,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/AgentRebate';
import { ApiResult } from '../../../../api/types';
import { useTranslation } from '../../../../hooks';
import { useAppSelector } from '../../../../reducer/hooks';
import { selectProfile } from '../../../../reducer/profileSlice';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey, TableRow } from '../types/Table';
import useSystemCurrency from '../../../../hooks/useSystemCurrency';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { te, tb, tc, translate } = useTranslation();
  const { CurrencyDecimalsMapping } = useSystemCurrency();

  const translateStatus = (status: number) => {
    if (!EnumRebateStatus[status]) return '';

    return translate(`EnumRebateStatus.${EnumRebateStatus[status]}`);
  };

  const translateRebateType = (type: number) => {
    if (!EnumRebateType[type]) return '';

    return translate(`EnumRebateType.${EnumRebateType[type]}`);
  };

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      return {
        id: index,
        [TableColumnKey.TaskId]: row.taskId || '',
        [TableColumnKey.OrderId]: row.orderId,
        [TableColumnKey.Sequence]: row.seq,
        [TableColumnKey.Month]: row.month,
        [TableColumnKey.ProgramName]: row.programName,
        [TableColumnKey.rebateType]: translateRebateType(row.type),
        [TableColumnKey.From]: row.fromClientId,
        [TableColumnKey.To]: row.toClientId,
        [TableColumnKey.Currency]: row.currency || '',
        [TableColumnKey.Amount]: row.amount,
        [TableColumnKey.RebateCurrency]: row.rebateCurrency,
        [TableColumnKey.RebateExchangeRate]: row.rebateExchangeRate,
        [TableColumnKey.RebateAmount]: row.rebateAmount,
        [TableColumnKey.Status]: translateStatus(row.status),
        [TableColumnKey.CreationTime]: toDisplayTime(row.createdDate),

        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
