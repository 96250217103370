import React from "react";

import useTranslation, { Iprefix as IPrefix } from '../../../hooks/useTranslation';
import PermissionTab from "../../../components/Layout/PermissionTab";
import { AgentPortalFeatureCode } from "@wallet-manager/pfh-pmp-node-def-types";

import ClientTab from './components/ClientTab';
import DistributorAgentTab from './components/DistributorAgentTab';

interface IDistributorAgentReportProps { }

const translatePrefix = 'monthlyRebateReport' as IPrefix;

const MonthlyRebateReport: React.FC<IDistributorAgentReportProps> = (props) => {
    const { t } = useTranslation(translatePrefix);
    return <PermissionTab
        contentArr={[
            { translatePrefix: `${translatePrefix}.clientTab` as IPrefix, element: <ClientTab /> },
            { translatePrefix: `${translatePrefix}.distributorAgentTab` as IPrefix, element: <DistributorAgentTab /> }
        ]}
        tabsArr={[
            { name: t(`clientTab.title`), value: `${translatePrefix}.clientTab` as IPrefix, permissionCode: AgentPortalFeatureCode.Report.MonthlyRebateReport.ClientReportTab.prefix },
            { name: t(`distributorAgentTab.title`), value: `${translatePrefix}.distributorAgentTab` as IPrefix, permissionCode: AgentPortalFeatureCode.Report.MonthlyRebateReport.DistributorReportTab.prefix }
        ]}
    />;
}

export default MonthlyRebateReport;
