import React from 'react';

const SmallText = ({ title, value }: { title: string; value: string }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '0.8rem',
      }}
    >
      <div style={{ fontSize: '0.7rem', color: 'rgba(255,255,255,0.5)' }}>{`${title}:`}</div>

      <div style={{ fontSize: '0.7rem', color: 'rgba(255,255,255,0.5)', marginLeft: '0.5rem' }}>
        {value}
      </div>
    </div>
  );
};

export default SmallText;
