import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';
import { PartialSearchDistributorAgentIdResponse } from '../types';

const getAll = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  //   return await axios.get(AgentPortal.EPtPaManagement.custodyWallet.getAll, input);
  return await axios.get(AgentPortal.EptSetting.userSetting.getAll, input);
};

const getExport = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(AgentPortal.EptSetting.userSetting.export, input);
};

const partialSearchDistributorAgentId = async (params: {
  keywords?: string;
  limit?: number;
  offset?: number;
}) => {
  const requestParams: any = {};
  if (params.keywords !== undefined) {
    requestParams.keywords = params.keywords;
  }

  if (params.limit !== undefined) {
    requestParams.limit = params.limit;
  }
  if (params.offset !== undefined) {
    requestParams.offset = params.offset;
  }

  return await axios.get<any, PartialSearchDistributorAgentIdResponse[]>(
    AgentPortal.EptQuery.agent.getDistributorAgentId,
    {
      params: requestParams,
    }
  );
};

const createUser = async (
  params: {
    email: string;
    name: string;
    roles: number[];
    remark: string;
    status: number;
    distributorAgentId?: string;
    agentType: number;
  },
  config = {}
) => {
  const res = await axios.post<never, any>(
    AgentPortal.EptSetting.userSetting.createUser,
    params,
    {}
  );

  return res;
};

const editUser = async (
  params: {
    distributorAgentId: string;
    name: string;
    roles: number[];
    status: number;
    remark: string;
    email: string;
    agentType: number;
  },
  config = {}
) => {
  const res = await axios.post<never, any>(AgentPortal.EptSetting.userSetting.edit, params, {});

  return res;
};

export default {
  getAll,
  getExport,
  partialSearchDistributorAgentId,
  createUser,
  editUser,
};
