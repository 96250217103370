import React, { useMemo } from 'react';
import DashboardGrid from '../../../../../../components/Dashboard/DashboardGrid';
import FilterHeader from '../../../../../../components/Dashboard/FilterHeader';
import LineChart from '../../../../../../components/Chart/LineChart';
import { COLOR_THEME } from '../../../../../../style/colorTheme';
import { Box } from '../../../../../../components/MuiGenerals';
import { useTranslation } from '../../../../../../hooks';
import { EnumFilterDay } from '../../types/EnumFilterDay';
import { useLocaleState } from 'react-admin';
import { convertDateStringToLocaleDateString } from '../../../../../../utils/dateUtils';
import { displayAmountCurrying } from '../../../../../../utils';

interface ITotalRebateAmountGraphProps {
  graphData: any;
  isLoading: boolean;
  totalAmount: number | string;
  currency: string;
  filterDay: string;
  setFilterDay: (day: string) => void;
}

const TotalRebateAmountGraph: React.FC<ITotalRebateAmountGraphProps> = ({
  graphData,
  totalAmount,
  currency,
  isLoading,
  filterDay,
  setFilterDay,
}) => {
  const { t } = useTranslation('dashboard');
  const [localeState, _] = useLocaleState();

  const convertedData = graphData?.map((item: any) => {
    return {
      name: convertDateStringToLocaleDateString(item.name, localeState),
      value: Math.abs(item.value),
    };
  });

  const chart = useMemo(() => {
    return (
      <LineChart
        data={convertedData}
        height={300}
        color={COLOR_THEME.CustomSx.Chart.LineChart.LineColor}
        valueKey="value"
        dotColor={COLOR_THEME.CustomSx.Chart.LineChart.DotColor}
        yAxisDisplay={true}
        tooltipValueKey={t('hkd')}
      />
    );
  }, [graphData]);

  return (
    <>
      <DashboardGrid
        xs={12}
        height={500}
        isNoRecord={(!graphData || graphData.length === 0) && !isLoading}
        header={
          <FilterHeader
            title={t('totalRebateAmount')}
            filterList={EnumFilterDay}
            value={filterDay}
            label={filterDay}
            nameFn={(name: string) => {
              return t(`${name}`);
            }}
            onChange={(e) => {
              setFilterDay(e.target.value);
            }}
          />
        }
        isLoading={isLoading}
        content={
          !isLoading && !graphData ? (
            <></>
          ) : (
            <Box>
              <Box
                sx={{
                  mt: 1,
                  mb: 2,
                }}
              >
                <span
                  style={{
                    color: COLOR_THEME.CustomSx.Dashboard.programAgentBalance.Title.Color,
                    fontSize: '3rem',
                  }}
                >
                  {displayAmountCurrying(0, 2)(Math.abs(Number(totalAmount)) || '0')}
                </span>
                <span
                  style={{
                    marginLeft: '0.5rem',
                    fontSize: '1.5rem',
                  }}
                >
                  {currency}
                </span>
              </Box>
              {chart}
            </Box>
          )
        }
      />
    </>
  );
};

export default TotalRebateAmountGraph;
