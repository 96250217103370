import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import APIs from '../../../../api';
import { OpRedirect } from '../../../../assets/icons';
import { OpIconButton } from '../../../../components';
import TableTemplate from '../../../../components/Layout/TableTemplate';
import { Box } from '../../../../components/MuiGenerals';
import { usePermission } from '../../../../hooks';
import { useGenTableColumns } from '../../../../hooks/useTable';
import useTranslation, { Iprefix } from '../../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../../zustand/store';
import { useListMapping, useZusParams } from '../hooks';
import { filterTableTranslateKey as TableTK } from '../mappings/translate';
import { IapiGetAllRes } from '../types';

const api = {
  table: APIs.clientManagement.clientApplication.getAll,
  export: APIs.clientManagement.clientApplication.getExport,
};
export default function Table() {
  const { hasPermission } = usePermission();
  const translatePrefix: Iprefix = 'clientApplication';
  const exportFileName = 'Client Application';
  const exportPermissionKey = AgentPortalFeatureCode.ClientManagement.ClientApplication.Export;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const { t, tc } = useTranslation(translatePrefix);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const tableMapping = useListMapping('table');
  const exportMapping = useListMapping('export');

  const omitKeys: string[] = [];

  const content: GridRowsProp = tableMapping(rows, omitKeys);

  const renderProgramNameCell = useCallback((params: GridRenderCellParams<any, any, any>) => {
    const programName = params.row[TableTK.programName];

    return (
      <Box>
        <Box>{programName}</Box>
      </Box>
    );
  }, []);

  const renderAmlDetailsCell = useCallback((params: GridRenderCellParams<any, any, any>) => {
    const { tcspAnswers } = params.row['rawData'] as IapiGetAllRes;
    return (
      <Box>
        <OpIconButton
          title={t(TableTK.amlDetails)}
          svgUrl={OpRedirect}
          onClick={() => zusDialog.open('amlDetailsDialog', { tcspAnswers })}
          size="1.5rem"
        />
      </Box>
    );
  }, []);
  const renderUploadedDocumentsCell = useCallback((params: GridRenderCellParams<any, any, any>) => {
    const { applicationNumber, idType, frontDocument, backDocument, firstName, lastName } = params
      .row['rawData'] as IapiGetAllRes;
    return (
      <Box>
        <OpIconButton
          title={t(TableTK.uploadedDocuments)}
          svgUrl={OpRedirect}
          onClick={() =>
            zusDialog.open('uploadedDocumentsDialog', {
              applicationNumber,
              idType,
              frontDocument: frontDocument.length ? frontDocument : undefined,
              backDocument: backDocument.length ? backDocument : undefined,
              firstName,
              lastName,
            })
          }
          size="1.5rem"
        />
      </Box>
    );
  }, []);

  const { columns } = useGenTableColumns([
    // { translateKey: TableTK.operation, renderCell: renderOperationCell },
    { translateKey: TableTK.distributorAgentId, minWidth: 170 },
    { translateKey: TableTK.applicationStatus, minWidth: 150 },
    { translateKey: TableTK.programName, minWidth: 150 },
    { translateKey: TableTK.referralCode },
    { translateKey: TableTK.emailAddress, minWidth: 150 },
    { translateKey: TableTK.phoneNumber },
    { translateKey: TableTK.pfhNumber, minWidth: 150 },
    { translateKey: TableTK.clientName },
    { translateKey: TableTK.identificationDocumentType, minWidth: 150 },
    { translateKey: TableTK.dateOfBirth },
    { translateKey: TableTK.pmpCustomerId, minWidth: 150 },
    { translateKey: TableTK.applicationStep, minWidth: 150 },
    { translateKey: TableTK.amlDetails, renderCell: renderAmlDetailsCell },
    { translateKey: TableTK.uploadedDocuments, renderCell: renderUploadedDocumentsCell },
    { translateKey: TableTK.createdBy },
    { translateKey: TableTK.creationTime },
    { translateKey: TableTK.lastModifiedBy },
    { translateKey: TableTK.lastModifiedTime },
  ]);

  return (
    <TableTemplate
      exportBtn={{
        fileName: exportFileName,
        permissionKey: exportPermissionKey,
        apiFn: api.export,
        omitKeys: ['rawData'],
        mappingFn: exportMapping,
      }}
      zusParams={zusParams}
      count={count}
      content={content}
      columns={columns}
    />
  );
}
