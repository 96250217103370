import React from 'react';

import { useTranslation } from '../../hooks';
import { customSx } from '../../utils/styling';
import { Box, Button } from '../MuiGenerals';

interface IProps {
  title?: string;
  onClick: () => void;
  isShow?: boolean;
}

const FilterBarCreateRecordButton = (props: IProps) => {
  const { title, onClick, isShow = true } = props;

  const { tc } = useTranslation();

  if (!isShow) return <></>;

  return (
    <Button variant="outlined" color="primary" onClick={onClick}>
      {title || tc('createRecord')}
    </Button>
  );
};

export default FilterBarCreateRecordButton;
