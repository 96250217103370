import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import APIs from '../../../../api';
import { OpRedirect } from '../../../../assets/icons';
import { OpIconButton } from '../../../../components';
import TableTemplate from '../../../../components/Layout/TableTemplate';
import { Box } from '../../../../components/MuiGenerals';
import { useAlerting, usePermission } from '../../../../hooks';
import { useGenTableColumns } from '../../../../hooks/useTable';
import useTranslation, { Iprefix } from '../../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../../zustand/store';
import { useListMapping, useZusParams } from '../hooks';
import { filterTableTranslateKey as TableTK } from '../mappings/translate';
import { IapiGetAllRes, IdetailsApiRes } from '../types';

const api = {
  table: APIs.clientManagement.clientList.getAll,
  export: APIs.clientManagement.clientList.getExport,
};
export default function Table() {
  const { hasPermission } = usePermission();
  const { alerting } = useAlerting();
  const translatePrefix: Iprefix = 'clientList';
  const exportFileName = 'Client List';
  const exportPermissionKey = AgentPortalFeatureCode.ClientManagement.ClientList.Export;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const { t, tc } = useTranslation(translatePrefix);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const tableMapping = useListMapping('table');
  const exportMapping = useListMapping('export');

  const omitKeys: string[] = [];

  const content: GridRowsProp = tableMapping(rows, omitKeys);

  const renderProgramNameCell = useCallback((params: GridRenderCellParams<any, any, any>) => {
    const programName = params.row[TableTK.programName];

    return (
      <Box>
        <Box>{programName}</Box>
      </Box>
    );
  }, []);

  const renderDetailsCell = useCallback((params: GridRenderCellParams<any, any, any>) => {
    const { customerNumber } = params.row['rawData'] as IapiGetAllRes;

    const clickHandler = async () => {
      const infoRes = (await APIs.clientManagement.clientList.getDetail({ customerNumber })) as
        | IdetailsApiRes
        | undefined
        | null;
      if (!infoRes) {
        if (infoRes === null) {
          alerting('error', tc('no_data_found'));
        }
        return;
      }

      const documentRes = await APIs.clientManagement.clientList.getDocumentList({
        customerNumber,
      });

      const { customerDocuments: customer_account_info_document = [] } = documentRes || {};

      const transformedResponse = Object.fromEntries(
        Object.entries(infoRes).map(([key, value]) => [key, value === '***N/A***' ? '' : value])
      ) as IdetailsApiRes;

      zusDialog.open('detailsDialog', {
        rowData: params.row,
        detailsData: { ...transformedResponse, customer_account_info_document },
      });
    };

    return (
      <Box>
        <OpIconButton
          title={t(TableTK.details)}
          svgUrl={OpRedirect}
          onClick={clickHandler}
          size="1.5rem"
        />
      </Box>
    );
  }, []);

  const { columns } = useGenTableColumns([
    // { translateKey: TableTK.operation, renderCell: renderOperationCell },
    { translateKey: TableTK.distributorAgentId, minWidth: 170 },
    { translateKey: TableTK.status, minWidth: 150 },
    { translateKey: TableTK.programName, minWidth: 150 },
    { translateKey: TableTK.emailAddress, minWidth: 150 },
    { translateKey: TableTK.phoneNumber },
    { translateKey: TableTK.pfhNumber, minWidth: 150 },
    { translateKey: TableTK.clientName },
    { translateKey: TableTK.pmpCustomerId, minWidth: 150 },
    { translateKey: TableTK.details, renderCell: renderDetailsCell },
    { translateKey: TableTK.createdBy },
    { translateKey: TableTK.creationTime },
    { translateKey: TableTK.lastModifiedBy },
    { translateKey: TableTK.lastModifiedTime },
  ]);

  return (
    <TableTemplate
      exportBtn={{
        fileName: exportFileName,
        permissionKey: exportPermissionKey,
        apiFn: api.export,
        omitKeys: ['rawData'],
        mappingFn: exportMapping,
      }}
      zusParams={zusParams}
      count={count}
      content={content}
      columns={columns}
      visibilityConfigArr={[
        {
          fieldName: TableTK.details,
          hasPermission: hasPermission(
            AgentPortalFeatureCode.ClientManagement.ClientList.Details.prefix
          ),
        },
      ]}
    />
  );
}
