import { useNavigate } from 'react-router-dom';

interface INavigateToRedirect {
  accessToken: string;
  refreshToken: string;
  features: string[];
}

const useLoginRedirect = () => {
  const navigate = useNavigate();

  // Navigate to "Redirect" page with login tokens and permissions
  const navigateToRedirect = ({ accessToken, refreshToken, features }: INavigateToRedirect) => {
    if (!accessToken || !refreshToken) {
      return console.log('Missing accessToken or refreshToken while login ', {
        accessToken,
        refreshToken,
      });
    }

    navigate(`/redirect`, {
      state: {
        accessToken,
        refreshToken,
        features,
      },
    });
  };

  return { navigateToRedirect };
};

export default useLoginRedirect;
