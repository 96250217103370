import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Itimezone } from '../api/types';
import { enumLanguage } from '../utils/constant';
import { RootState } from './store';

interface JWTDetails {
  merchantId: number;
  programAgentId: string;
  distributorAgentId: string;
  agentType: number;
  appUuid: string;
  email: string;
  status: number;
  roles: number[];
  programs: string[];
  ip: string;
  sessionExpires: number;
  snowFlake: string;
  iat: number;
  exp: number;
}
export interface ProfileLoginFace {
  isLogged: boolean;
  featureList: string[];
  appName: string;
  email: string;
  lang: keyof typeof enumLanguage;
  timezone: Itimezone;
  appUuid: string;
  jwtDetails: JWTDetails | null | undefined;
}

const initialState: ProfileLoginFace = {
  isLogged: false,
  featureList: [],
  appName: '',
  email: '',
  lang: getBrowserSetting().lang,
  timezone: getBrowserSetting().timezone,
  appUuid: '',
  jwtDetails: null,
};
function getBrowserSetting() {
  const defaultLang = /* navigator.language || */ 'en';
  const lang = defaultLang.includes('zh') ? enumLanguage.zh_cn : enumLanguage.en;
  const defaultTimezone = (new Date().getTimezoneOffset() * -1) / 60;
  const timezone = (
    defaultTimezone < 0 ? String(defaultTimezone) : `+${defaultTimezone}`
  ) as Itimezone;
  return { lang, timezone };
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<Partial<ProfileLoginFace>>) => {
      const {
        appName = '',
        featureList = [],
        email = '',
        appUuid = '',
        jwtDetails,
      } = action.payload;

      state.isLogged = true;
      state.featureList = featureList;
      state.email = email;
      state.appName = appName;
      state.appUuid = appUuid;
      state.jwtDetails = jwtDetails;
    },
    setAccountSetting: (
      state,
      action: PayloadAction<{
        lang: keyof typeof enumLanguage;
        timezone: Itimezone;
      }>
    ) => {
      state.lang = action.payload.lang;
      state.timezone = action.payload.timezone;
    },
    syncLocalStorage: (state, action: PayloadAction<ProfileLoginFace>) => {
      // const {
      //   lang,
      //   timezone,
      //   isLogged,
      //   featureList,
      //   appName,
      //   email,
      //   chain_analysis,
      // } = action.payload;
      // state.lang = lang;
      // state.timezone = timezone;
      // state.isLogged = isLogged;
      // state.featureList = featureList;
      // state.appName = appName;
      // state.email = email;
      // state.chain_analysis = chain_analysis;
      return action.payload;
    },
    logout: (state) => ({ ...initialState, lang: state.lang, timezone: state.timezone }),
  },
});

export const { logout, login, setAccountSetting, syncLocalStorage } = profileSlice.actions;

export const selectProfile = (state: RootState) => state.profile;
export const selectExpiryTime = (state: RootState) => {
  // const date = new Date(state.profile.expiryTimeStr);
  // return isValidDate(date) ? date : new Date();
  return new Date();
};
export const selectPermissions = (state: RootState) => state.profile.featureList;

export default profileSlice.reducer;
