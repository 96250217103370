export function findElementWithRetry(
  selector: string,
  maxAttempts: number = 5,
  delay: number = 500
): Promise<Element | null> {
  return new Promise((resolve, reject) => {
    let attempts = 0;

    const attemptToFindElement = () => {
      const element = document.querySelector(selector);
      attempts++;

      if (element) {
        resolve(element);
      } else if (attempts < maxAttempts) {
        setTimeout(attemptToFindElement, delay);
      } else {
        resolve(null);
      }
    };

    attemptToFindElement();
  });
}
