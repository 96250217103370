export const SOURCE_OF_FUNDS = [
  'Others',
  'BusinessIncome',
  'PersonalSaving',
  'Inheritance',
  'RentalIncome',
  'Investment',
  'SalesOfProperty',
  'SalesOfVirtualAssets',
  'Pension',
  'WagesDepositedFromEmployer',
];
