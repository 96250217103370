import { ApiResult } from '../../../../../../api/types';
import { useTranslation } from '../../../../../../hooks';
import useSystemCurrency from '../../../../../../hooks/useSystemCurrency';
import { displayAmountCurrying, toDisplayTime } from '../../../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey, TableRow } from '../types/Table';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { te, tb, tc } = useTranslation();
  const { CurrencyDecimalsMapping } = useSystemCurrency();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      const decimals = CurrencyDecimalsMapping[row.currency] || 0;
      const displayAmount = displayAmountCurrying(decimals);

      return {
        id: index,
        [TableColumnKey.SettlementDate]: row.settlementDate
          ? toDisplayTime(row.settlementDate, 'yyyy-MM-dd')
          : '',
        [TableColumnKey.ProgramName]: row.programName || '',
        [TableColumnKey.Currency]: row.currency || '',
        [TableColumnKey.Purchase]: displayAmount(row.purchase) || '',
        [TableColumnKey.Refund]: displayAmount(row.refund) || '',
        [TableColumnKey.AccountFunding]: displayAmount(row.accountFunding) || '',
        [TableColumnKey.Repayment]: displayAmount(row.repayment) || '',
        [TableColumnKey.FundingAdjustment]: displayAmount(row.fundingAdjustment) || '',
        [TableColumnKey.WaivedLateCharge]: displayAmount(row.lateChargeWaive) || '',
        [TableColumnKey.WaivedInterest]: displayAmount(row.interestWaive) || '',
        [TableColumnKey.Reward]: displayAmount(row.reward) || '',
        [TableColumnKey.Withdraw]: displayAmount(row.withdraw) || '',
        [TableColumnKey.ChargeBack]: displayAmount(row.chargeback) || '',
        [TableColumnKey.Transfer]: displayAmount(row.transfer) || '',
        [TableColumnKey.RepaymentAdjustment]: displayAmount(row.repaymentAdjustment) || '',
        [TableColumnKey.VipServiceFee]: displayAmount(row.vipServiceFee) || '',
        [TableColumnKey.Withholding]: displayAmount(row.withholding) || '',
        [TableColumnKey.Interest]: displayAmount(row.interest) || '',
        [TableColumnKey.Cashback]: displayAmount(row.cashback) || '',
        [TableColumnKey.Fee]: displayAmount(row.fee) || '',
        [TableColumnKey.FeeAdjustment]: displayAmount(row.feeAdjustment) || '',
        [TableColumnKey.WithdrawFeeAdjustment]: displayAmount(row.withdrawFeeAdjustment) || '',
        [TableColumnKey.AgentMarkupWithdrawFeeAdjustment]:
          displayAmount(row.withdrawFeeAdjustmentAgentMarkup) || '',
        [TableColumnKey.OverseaFeeAdjustment]: displayAmount(row.feeAdjustmentOversea) || '',
        [TableColumnKey.AgentMarkupOverseaFeeAdjustment]:
          displayAmount(row.feeAdjustmentOverseaAgentMarkup) || '',
        [TableColumnKey.UkFeeAdjustment]: displayAmount(row.feeAdjustmentGb) || '',
        [TableColumnKey.E6Fee]: displayAmount(row.e6Fee) || '',
        [TableColumnKey.AnnualFee]: displayAmount(row.annualFee) || '',
        [TableColumnKey.FastFund]: displayAmount(row.fastFund) || '',
        [TableColumnKey.LoadReversal]: displayAmount(row.loadReversal) || '',
        [TableColumnKey.LatePaymentFee]: displayAmount(row.latePaymentFee) || '',
        [TableColumnKey.Others]: displayAmount(row.others) || '',
        [TableColumnKey.TotalIn]: displayAmount(row.totalIn) || '',
        [TableColumnKey.TotalOut]: displayAmount(row.totalOut) || '',
        [TableColumnKey.TotalRebateTxAmount]: displayAmount(row.totalRebateTxAmount) || '',
        [TableColumnKey.TotalVisaFee]: displayAmount(row.totalVisaFee) || '',
        [TableColumnKey.TotalSwapCost]: displayAmount(row.totalSwapCost) || '',
        [TableColumnKey.TotalAgentRebate]: displayAmount(row.totalAgentRebate) || '',
        [TableColumnKey.TotalSystemCost]: displayAmount(row.totalSystemCost) || '',
        [TableColumnKey.TotalProfit]: displayAmount(row.totalProfit) || '',
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
