import { defaultListParams } from '../../../../common/filterTable/constants/defaultListParams';
import { cleanParams } from '../../../../common/filterTable/helpers/cleanParams';
import { FilterFields } from '../types/FilterFields';
import { GetListParams } from '../types/GetListParams';

const useGetListParams = () => {
  const getListParams = (fields: FilterFields) => {
    const {
      createdDateFrom,
      createdDateTo,
      lastModifiedTimeFrom,
      lastModifiedTimeTo,
      distributorAgentId,
      agentType,
      role,
      status,
      loginEmail,
    } = fields;

    const params: GetListParams = {
      ...defaultListParams,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom: lastModifiedTimeFrom,
      lastModifiedDateTo: lastModifiedTimeTo,
      distributorAgentId,
      agentType,
      roles: role,
      status,
      email: loginEmail,
    };

    return cleanParams(params);
  };

  return {
    getListParams,
  };
};

export default useGetListParams;
