import { getParsedUserAgent } from '../../../../utils/userAgent';

const getDeviceName = () => {
  let deviceName = 'Unknown';
  const parsedUserAgent = getParsedUserAgent();

  if (parsedUserAgent) {
    const { os, name, version } = parsedUserAgent;

    deviceName = `${os} ${name}(${version})`;
  }

  return deviceName.toUpperCase();
};

export default getDeviceName;
