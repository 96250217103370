import React from 'react';
import { Button } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';

interface IProps {
  onClick: () => void;
}

const EditButton = (props: IProps) => {
  const { onClick } = props;

  const { tc } = useTranslation();

  return (
    <Button
      className={'inheritColor'}
      color={'primary'}
      variant={'contained'}
      children={tc('edit')}
      onClick={onClick}
    />
  );
};

export default EditButton;
