import { useTranslate } from 'react-admin';

import Pagination from '@mui/material/Pagination';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid';

import { COLOR_THEME } from '../../style/colorTheme';
import { Typography } from '../MuiGenerals';

export default function CustomPagination(props: any) {
  const translate = useTranslate();
  const t = (key: string) => translate(`component.${key}`);
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const sx = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px',
      backgroundColor: props.footerBgColor || COLOR_THEME.Layout.CustomPagination.Background,
    },
    footerString: {
      paddingTop: '5px',
      color: '#A2A2A2',
    },
  };

  return (
    <div style={sx.container}>
      <Typography variant="body1" sx={sx.footerString}>
        {!props.noshowFooter ? (
          <>
            <span style={{ color: COLOR_THEME.Layout.CustomPagination.Text, opacity: 0.8 }}>
              {t('itemTotalStart')}
            </span>
            <span style={{ color: COLOR_THEME.Layout.CustomPagination.Text }}>
              {props.totalRecords}
            </span>
            <span style={{ color: COLOR_THEME.Layout.CustomPagination.Text, opacity: 0.8 }}>
              {t('itemTotalEnd')}
            </span>
          </>
        ) : (
          <></>
        )}
      </Typography>
      <Pagination
        count={pageCount}
        page={page + 1}
        size="small"
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
        sx={{
          button: {
            color: COLOR_THEME.Layout.CustomPagination.Text,
            '&.Mui-selected': { color: COLOR_THEME.Layout.CustomPagination.Selected.Text },
          },
        }}
      />
    </div>
  );
}
