export interface ValidationConfig {
  key: string;
  value: unknown;
  validators: any[];
}

export type ValidationConfigProps = ValidationConfig | ValidationConfig[];

// for individual validator

export interface ErrorInfo {
  message: string;
  fieldKey: string;
  value: any;
  validatorType: string;
}
export interface ValidatorResponse {
  isValid: boolean;
  errorInfo: ErrorInfo;
}

export interface ValidatorsResponse {
  isValid: boolean;
  errorInfo: ErrorInfo[];
}

export interface ValidatorResponseWithKey extends ValidatorsResponse {
  key: string;
}

export interface ValidationResult {
  [key: string]: ValidatorsResponse;
}

export enum ValidationType {
  Required = 'required',
  LargerThanZero = 'largerThanZero',
  ConfirmPassword = 'confirmPassword',
  LengthLimit = 'lengthLimit',
  RequiredUpperCaseLetter = 'requiredUpperCaseLetter',
  RequiredSymbol = 'requiredSymbol',
  RequiredNumberAmount = 'requiredNumberAmount',
  LoginEmail = 'loginEmail',
}
