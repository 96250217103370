import {
  ValidationType,
  ValidatorResponse
} from '../../hooks/useValidation/types';

export const requiredNumberAmount =
  (errorMessage: string, { min }: { min: number }) =>
  (fieldKey: string, value: any): ValidatorResponse => {
    const errorInfo = {
      message: errorMessage,
      fieldKey,
      value,
      validatorType: ValidationType.RequiredNumberAmount,
    };

    // regex to check string have at least {min} amount of number
    const regex = new RegExp(`[0-9]{${min},${min}}`);

    const isInvalid = !regex.test(value);

    if (isInvalid) {
      return { isValid: false, errorInfo };
    }

    return { isValid: true, errorInfo };
  };
