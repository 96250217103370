import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useAppSelector } from '../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../reducer/mediaSlice';

interface IProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  label: string;
  isValid?: boolean;
}

const PasswordField: React.FC<IProps> = (props) => {
  const [inputType, setInputType] = useState('password');
  const isMobile = useAppSelector(selectIsMobileView);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div
      className="login-field"
      style={{
        color: '#8B95A3',
        width: '100%',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        marginTop: '1rem',
        marginBottom: '1rem',
      }}
    >
      <label
        style={{
          width: '100%',
          flex: 2,
        }}
      >
        {props.label}
      </label>
      <div
        style={{
          backgroundColor: '#333333',
          color: '#8B95A3',
          height: '2rem',
          border: `1px solid ${props.isValid ? 'green' : '#44506E'}`,
          borderRadius: '.5rem',
          paddingLeft: '.5rem',
          paddingRight: '.5rem',
          outline: isFocused ? '1px solid #00A0E3' : 'none',
          ...(!isMobile && { flex: 3 }),
          ...(isMobile && { margin: '8px 0' }),
        }}
      >
        <div style={{ position: 'relative' }}>
          <input
            style={{
              backgroundColor: '#333333',
              color: '#8B95A3',
              border: 'none',
              width: '100%',
              height: '100%',
              fontSize: '0.8rem',
              outline: 'none',
              marginTop: '0.5rem'
            }}
            type={inputType}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          {inputType === 'password' ? (
            <VisibilityOffIcon
              style={{
                color: '#8B95A3',
                cursor: 'pointer',
                position: 'absolute',
                top: '6px',
                right: '8px',
              }}
              onClick={() => setInputType('text')}
            />
          ) : (
            <VisibilityIcon
              style={{
                color: '#8B95A3',
                cursor: 'pointer',
                position: 'absolute',
                top: '6px',
                right: '8px',
              }}
              onClick={() => setInputType('password')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PasswordField;
