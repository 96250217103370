import { defaultListParams } from '../../../../common/filterTable/constants/defaultListParams';
import { cleanParams } from '../../../../common/filterTable/helpers/cleanParams';
import { FilterFields } from '../types/FilterFields';
import { GetListParams } from '../types/GetListParams';

const useGetListParams = () => {
  const getListParams = (fields: FilterFields) => {
    const {
      createdDateFrom,
      createdDateTo,
      lastModifiedTimeFrom,
      lastModifiedTimeTo,
      orderId,
      distributorAgentId,
      currency,
    } = fields;

    const params: GetListParams = {
      ...defaultListParams,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom: lastModifiedTimeFrom,
      lastModifiedDateTo: lastModifiedTimeTo,
      targetTransferOrderId: orderId,
      toWalletClientId: distributorAgentId,
      creditCurrency: currency?.currency,
    };

    return cleanParams(params);
  };

  return {
    getListParams,
  };
};

export default useGetListParams;
