import React, { useContext } from 'react';

import { LoginContext } from '../';
import APIs from '../../../../api';
import emailIconSrc from '../../../../assets/icons/login-page-email-icon.png';
import { useAlerting, useTranslation } from '../../../../hooks';
import useLoading from '../../../../hooks/useLoading';
import LoginInput from '../components/LoginInput';
import PageWrapper from '../components/PageWrapper';
import getDeviceId from '../helpers/getDeviceId';
import getDeviceName from '../helpers/getDeviceName';
import useEnterEmailValidation from '../helpers/useEnterEmailValidation';
import { LoginFieldsKeys, PageKeys } from '../types';
import { getUrlProgramAgentId, isUrlProgramAgentIdValid } from '../helpers/getUrlProgramAgentId';

interface IProps {}

const EnterEmail = (props: IProps) => {
  const loginContext = useContext(LoginContext);

  const { fields, setFields, setPage } = loginContext;

  const { alerting } = useAlerting();
  const { validate } = useEnterEmailValidation({ fields });
  const { t } = useTranslation('login');
  const { showLoading, hideLoading } = useLoading();

  const onSubmit = async () => {
    const validationResult = validate();

    if (validationResult.allErrors.length > 0) {
      alerting('error', validationResult.allErrors[0]);
      return;
    }

    showLoading('getLoginStatus');
    const res = await APIs.merchantPortal.auth.getLoginStatusV2({
      email: fields[LoginFieldsKeys.Email],
      deviceId: getDeviceId(),
      deviceName: getDeviceName(),
      programAgentId: getUrlProgramAgentId(fields[LoginFieldsKeys.ProgramAgentId]),
    });
    hideLoading('getLoginStatus');

    if (!res) return;

    if (!res.isEmailExist) {
      return alerting('error', t('emailNotExistError'));
    }

    if (res.isForcePasswordChange) {
      setPage(PageKeys.EmailVerification);
    } else {
      if (res.isDeviceRegistered) {
        setPage(PageKeys.EnterPassword);
      } else {
        setPage(PageKeys.EnterPasswordWithVerify);
      }
    }
  };
  console.log('isUrlProgramAgentIdValid(getUrlProgramAgentId()) = ', getUrlProgramAgentId());
  return (
    <PageWrapper onSubmit={onSubmit}>
      <div className="login-field">
        <LoginInput
          label={t('enterEmail.email')}
          value={fields[LoginFieldsKeys.Email]}
          onChange={(e) =>
            setFields((fields) => ({ ...fields, [LoginFieldsKeys.Email]: e.target.value }))
          }
          iconSrc={emailIconSrc}
          autoComplete="username"
          showEmailKeyboard
        />
      </div>
      {/* Only show when url does not have valid programAgentId */}
      <div className="login-field">
        <LoginInput
          label={t('programAgentId')}
          value={fields[LoginFieldsKeys.ProgramAgentId]}
          onChange={(e) =>
            setFields((fields) => ({
              ...fields,
              [LoginFieldsKeys.ProgramAgentId]: e.target.value,
            }))
          }
          noIcon
          hide={isUrlProgramAgentIdValid(getUrlProgramAgentId())}
        />
      </div>
    </PageWrapper>
  );
};

export default EnterEmail;
