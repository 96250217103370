const filterTableTranslateKey = {
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  ledgerClientId: 'ledger_client_id',
  pfhNumber: 'pfh_number',
  currency: 'currency',
  sendingAddress: 'sending_address',
  receivingAddress: 'receiving_address',
  transactionHash: 'transaction_hash',
  referenceNumber: 'reference_number',
  lastLedgerTransactionId: 'last_ledger_transaction_id',
  amount: 'amount',
  postBalance: 'post_balance',
  frozenBalance: 'frozen_balance',
  riskLevel: 'risk_level',
  transactionStatus: 'transaction_status',
  transactionType: 'transaction_type',
  sendingClientId: 'sending_client_id',
  receivingClientId: 'receiving_client_id',
};

export { filterTableTranslateKey };
