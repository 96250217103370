import RetryDialog from "./RetryDialog"

const Dialog = () => {
  return (
    <>
      <RetryDialog />
    </>
  )
}

export default Dialog