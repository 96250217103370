import React from 'react';

import { Box } from '../../../../components/MuiGenerals';
import { COLOR_THEME } from '../../../../style/colorTheme';

const RowItem = ({ title, children }: { title: string; children: React.ReactNode | string }) => {
  return (
    <div style={{ display: 'flex', marginBottom: '24px', fontSize: '12px' }}>
      <div
        style={{
          width: '160px',
          minWidth: '160px',
          wordBreak: 'break-word',
          textAlign: 'left',
          marginRight: '24px',
          color: COLOR_THEME.Page.Text,
        }}
      >
        {`${title}`}
      </div>
      <Box
        sx={{
          color: COLOR_THEME.Page.Text,
          fontWeight: '700',
          whiteSpace: 'pre-wrap',
          ':not(:has(img))': {
            opacity: 0.5,
          },
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default RowItem;
