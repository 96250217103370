import { useGenGridCol } from '../../../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/Table';

const useTableColumns = () => {
  const columns = [
    useGenGridCol(TableColumnKey.Month),
    useGenGridCol(TableColumnKey.ProgramName, { minWidth: 150 }),
    useGenGridCol(TableColumnKey.DistributorAgentId),
    useGenGridCol(TableColumnKey.Currency),
    useGenGridCol(TableColumnKey.DistributorAgentRebateAmount, { minWidth: 150 }),
    useGenGridCol(TableColumnKey.ClientRebateAmount, { minWidth: 150 }),
    useGenGridCol(TableColumnKey.NetDistributorAgentRebateAmount, { minWidth: 150 }),
  ];

  return columns;
};

export default useTableColumns;
