import { ReactNode, useState } from 'react';

import { TextFieldProps } from '@mui/material/TextField';

import { Box } from '../MuiGenerals';
import MpTextField from './MpTextField';
import MpTextFieldNumberOnly, { MpTextFieldNumberProps } from './MpTextFieldNumberOnly';

type Props = (
  | ({ mode: 'number' } & MpTextFieldNumberProps)
  | ({ mode: 'free' } & TextFieldProps)
) & {
  startAdornment?: {
    adornmentNode: ReactNode;
    isHideAdornment?: boolean;
    isAlwaysShowAdornment?: boolean;
    isSelectable?: boolean;
  };

  endAdornment?: {
    adornmentNode: ReactNode;
    isHideAdornment?: boolean;
    isAlwaysShowAdornment?: boolean;
    isSelectable?: boolean;
  };
};

export default function MpTextFieldWithAdornment({
  value,
  startAdornment,
  endAdornment,
  mode,
  ...props
}: Props) {
  const [isFocus, setIsFocus] = useState(false);

  const shouldShowAdornment = value || isFocus;

  const isStartAdornmentShown =
    startAdornment &&
    (startAdornment.isAlwaysShowAdornment ||
      (shouldShowAdornment && !startAdornment.isHideAdornment));

  const isEndAdornmentShown =
    endAdornment &&
    (endAdornment.isAlwaysShowAdornment || (shouldShowAdornment && !endAdornment.isHideAdornment));

  const commonProps: TextFieldProps = {
    value,
    onFocus: (e) => {
      setIsFocus(true);
      props.onFocus && props.onFocus(e);
    },
    onBlur: (e) => {
      setIsFocus(false);
      props.onBlur && props.onBlur(e);
    },
    InputProps: {
      ...props.InputProps,
      startAdornment: isStartAdornmentShown && (
        <Box
          sx={{
            paddingTop: '21px',
            paddingBottom: '4px',
            height: '100%',
            userSelect: startAdornment.isSelectable ? undefined : 'none',
          }}
        >
          {startAdornment.adornmentNode}
        </Box>
      ),
      endAdornment: isEndAdornmentShown && (
        <Box
          sx={{
            paddingTop: '21px',
            paddingBottom: '4px',
            height: '100%',
            userSelect: endAdornment.isSelectable ? undefined : 'none',
          }}
        >
          {endAdornment.adornmentNode}
        </Box>
      ),
    },
  };

  return mode === 'number' ? (
    <MpTextFieldNumberOnly {...props} {...commonProps} />
  ) : (
    <MpTextField {...props} {...commonProps} />
  );
}
