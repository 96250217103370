import React from 'react';
import { SelectChangeEvent } from '../../../../components/MuiGenerals';
import { MultipleSelection } from '../../../../components';
import { useTranslation } from '../../../../hooks';
import useAgentRoles from '../../../../hooks/useAgentRoles';

interface IProps {
  label?: string;
  value: any;
  onChange: (arr: string[]) => void;
}

const AgentRoleMultipleSelection = (props: IProps) => {
  const { label, value, onChange } = props;

  const { tc } = useTranslation();
  const { AgentRolesEnum } = useAgentRoles();

  const displayLabel = label || tc('phSelection', { fieldName: tc('roles') });

  return (
    <>
      <MultipleSelection
        label={displayLabel}
        value={value}
        onChange={onChange}
        enumData={AgentRolesEnum}
        nameFn={(item: string) => item}
      />
    </>
  );
};

export default AgentRoleMultipleSelection;
