import { useEffect, useState } from 'react';

import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import PermissionTab from '../../../components/Layout/PermissionTab';
import { usePermission } from '../../../hooks';
import useTranslation, { Iprefix } from '../../../hooks/useTranslation';
import BalanceTab from './BalanceTab';
import { getTabTranslatePrefix } from './helper';
import HistoryTab from './HistoryTab';

export type IdefaultFilter = {
  currency: string;
  distributorAgentId: string;
};

export default function DistributorAgentWallet() {
  const translatePrefix: Iprefix = 'distributorAgentWallet';
  const [forcedTabValue, setForcedTabValue] = useState<Iprefix>();
  const [defaultFilter, setDefaultFilter] = useState<{
    currency: string;
    distributorAgentId: string;
  }>();
  const { hasPermission } = usePermission();
  const { t } = useTranslation(translatePrefix);

  const redirectToHistoryTab = (defaultFields: IdefaultFilter) => {
    if (
      !hasPermission(
        AgentPortalFeatureCode.DistributorAgentManagement.DistributorAgentWallet.HistoryTab.prefix
      )
    ) {
      return;
    }

    setForcedTabValue(getTabTranslatePrefix('historyTab'));
    setDefaultFilter(defaultFields);
  };

  useEffect(() => {
    if (!forcedTabValue) {
      setDefaultFilter(undefined);
      return;
    }

    setForcedTabValue(undefined);
  }, [forcedTabValue]);

  return (
    <PermissionTab
      contentArr={[
        {
          translatePrefix: getTabTranslatePrefix('balanceTab'),
          element: <BalanceTab redirectToHistoryTab={redirectToHistoryTab} />,
        },
        {
          translatePrefix: getTabTranslatePrefix('historyTab'),
          element: <HistoryTab defaultFilter={defaultFilter} />,
        },
      ]}
      tabsArr={[
        {
          name: t('balanceTab.title'),
          value: getTabTranslatePrefix('balanceTab'),
          permissionCode:
            AgentPortalFeatureCode.DistributorAgentManagement.DistributorAgentWallet.BalanceTab
              .prefix,
        },
        {
          name: t('historyTab.title'),
          value: getTabTranslatePrefix('historyTab'),
          permissionCode:
            AgentPortalFeatureCode.DistributorAgentManagement.DistributorAgentWallet.HistoryTab
              .prefix,
        },
      ]}
      forcedTabValue={forcedTabValue}
    />
  );
}
