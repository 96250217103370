import React, { ReactNode, SetStateAction } from 'react';

import ResetButton from '../../../../../common/filterTable/components/Buttons/ResetButton';
import SearchButton from '../../../../../common/filterTable/components/Buttons/SearchButton';
import Filters from '../../../../../common/filterTable/components/Filters/Filters';
// import PfhNumberTextFields from '../../../../common/filterTable/components/Filters/PfhNumberTextFields';
import SystemCurrencySingleSelection from '../../../../../common/filterTable/components/Filters/SystemCurrencySingleSelection';
import FilterActions from '../../../../../common/filterTable/layout/FilterActions';
import FilterGrid from '../../../../../common/filterTable/layout/FilterGrid';
import { useGenFilterField } from '../../../../../utils/ComponentHelper';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const filterFields: ReactNode[] = useGenFilterField([
    ['creationTime', <DateObj.CreationTime.Picker type="dateTime" />],
    ['lastModifiedTime', <DateObj.LastModifiedTime.Picker type="dateTime" />],

    [
      FilterFieldsKeys.Currency,
      <SystemCurrencySingleSelection
        value={fields[FilterFieldsKeys.Currency]}
        onChange={(e) => updateField(FilterFieldsKeys.Currency, JSON.parse(e.target.value))}
        onClear={() => updateField(FilterFieldsKeys.Currency, {})}
      />,
    ],
  ]);

  return (
    <>
      <FilterGrid>
        <Filters filterFields={filterFields} />
      </FilterGrid>
      <FilterActions>
        <SearchButton onSearch={onSearch} />
        <ResetButton onReset={onReset} />
      </FilterActions>
    </>
  );
};

export default FilterSection;
