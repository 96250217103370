import { CustomerAccount } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';
import { EptQuery } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';

import axios from '../axiosInstance';
import { ApiResult } from '../types';

const endpointCustomer = { ...EptQuery.customer };

export type CustomerWallet = {
  currency: string;
  balance: string;
  frozenBalance: string;
  decimals: number;
};

const getCustomerProgram = async (params: { customerNumber: string }, config?: any) => {
  const input = { params, ...config };
  const res = await axios.get<never, string>(endpointCustomer.programName, input);
  return res;
};
const getAssetBalances = async (
  params: { customerNumber: string; includeReward: boolean },
  config?: any
) => {
  const input = { params, ...config };
  const res = await axios.get<never, CustomerWallet[]>(endpointCustomer.wallet, input);
  return res;
};

const partialSearchCustomerNumber = async ({ customerNumber }: { customerNumber: string }) => {
  return await axios.get<never, ApiResult<CustomerAccount>>(EptQuery.customer.partialSearch, {
    params: { customerNumber },
  });
};

export default {
  getCustomerProgram,
  getAssetBalances,
  partialSearchCustomerNumber,
};
