import { useQuery } from 'react-query';
import useLoading from '../../../../../hooks/useLoading';
import { useAppSelector } from '../../../../../reducer/hooks';
import { selectProfile } from '../../../../../reducer/profileSlice';

interface IProps {
  listParams: any;
  setList: any;
  getTableDataApiFunc: (params: any) => any;
  pageId?: string;
}

const useTableQuery = <ApiResultListType>(props: IProps) => {
  const { listParams, setList, getTableDataApiFunc, pageId } = props;
  const profile = useAppSelector(selectProfile);
  const isLogin = profile.isLogged;

  const { showLoading, hideLoading } = useLoading();

  const pageTableId = `${pageId}Table`;

  const handleSettled = () => hideLoading(pageTableId);

  const handleSuccess = (data: ApiResultListType) => {
    if (!data) return;

    setList(data);
  };

  const handleQuery = () => {
    showLoading(pageTableId);

    const { currentTime, ...cleanParams } = listParams;
    return getTableDataApiFunc(cleanParams);
  };

  useQuery([pageTableId, listParams], handleQuery, {
    enabled: isLogin,
    onSettled: handleSettled,
    onSuccess: handleSuccess,
  });
};

export default useTableQuery;
