import { ValidationType, ValidatorResponse } from '../../hooks/useValidation/types';

export const loginEmailValidator =
  (errorMessage: string) =>
  (fieldKey: string, value: any): ValidatorResponse => {
    const errorInfo = {
      message: errorMessage,
      fieldKey,
      value,
      validatorType: ValidationType.LoginEmail,
    };

    // check login email format, this validation synced with backend
    const regex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    const isInvalid = !regex.test(value);

    if (isInvalid) {
      return { isValid: false, errorInfo };
    }

    return { isValid: true, errorInfo };
  };
