import {
  ValidationType,
  ValidatorResponse
} from '../../hooks/useValidation/types';

export const requiredUpperCaseLetterValidator =
  (errorMessage: string, { min }: { min: number }) =>
  (fieldKey: string, value: any): ValidatorResponse => {
    const errorInfo = {
      message: errorMessage,
      fieldKey,
      value,
      validatorType: ValidationType.RequiredUpperCaseLetter,
    };

    // regex to check if string have at least {min} amount of uppercase letters, min is inclusive (min = 1 means at least 1 uppercase letter)
    const regex = new RegExp(`(?=.*[A-Z]{${min},${min}})`);
    const isInvalid = !regex.test(value);

    if (isInvalid) {
      return { isValid: false, errorInfo };
    }

    return { isValid: true, errorInfo };
  };
