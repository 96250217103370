export const EXPECTED_TX_PER_MONTH: Record<string, string> = {
  '0': '10OrBelow',
  '11': '11To20',
  '21': '21to30',
  '31': '31To40',
  '41': '41To50',
  '51': '51To60',
  '61': '61To70',
  '71': '71To80',
  '81': '81To90',
  '91': '91OrAbove',
};
