import React from "react";

import { useTranslation } from "../../../../hooks";

interface IProps {
    onClick: () => void;
}

const ForgotPasswordBtn = ({ onClick }: IProps) => {
    const { t } = useTranslation("login");
    return (
        <div style={{
            fontSize: "14px",
            fontWeight: "bold",
            textAlign: "center",
            cursor: "pointer",
        }}
            onClick={onClick}>
            {t("forgotPassword")}
        </div>
    );
};

export default ForgotPasswordBtn;
