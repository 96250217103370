export const SOURCE_OF_WEALTH = [
  'Others', // 1
  'Employment', // 2
  'SelfEmployment', // 4
  'BusinessOwnership', // 8
  'LoansGrant', // 16
  'SpouseParentPartner', // 32
  'Inheritance', // 64
  'TrustFund', // 128
  'Investment', // 256
  'SalesOfVirtualAssets', // 512
  'PersonalRealEstate', // 1024
];
