import React, { useContext } from 'react';

import { LoginContext } from '../';
import APIs from '../../../../api';
import passwordIconSrc from '../../../../assets/icons/login-page-password-icon.png';
import { useAlerting, useTranslation } from '../../../../hooks';
import useLoading from '../../../../hooks/useLoading';
import LoginInput from '../components/LoginInput';
import PageWrapper from '../components/PageWrapper';
import getDeviceId from '../helpers/getDeviceId';
import useEnterPasswordValidation from '../helpers/useEnterPasswordValidation';
import useLoginRedirect from '../helpers/useLoginRedirect';
import { LoginFieldsKeys, PageKeys } from '../types';
import ForgotPasswordBtn from '../components/ForgotPasswordBtn';
import { getUrlProgramAgentId } from '../helpers/getUrlProgramAgentId';

const EnterPassword = () => {
  const loginContext = useContext(LoginContext);

  const { fields, setFields, setPage } = loginContext;

  const { t } = useTranslation('login');
  const { alerting } = useAlerting();
  const { showLoading, hideLoading } = useLoading();
  const { validate } = useEnterPasswordValidation({ fields });
  const { navigateToRedirect } = useLoginRedirect();

  const onSubmit = async () => {
    // FN validation
    const validationResult = validate();

    if (validationResult.allErrors.length > 0) {
      alerting('error', validationResult.allErrors[0]);
      return;
    }

    // Show loading, call login API
    showLoading('login');
    const res = await APIs.merchantPortal.auth.loginV2({
      email: fields.email,
      password: fields.password,
      deviceId: getDeviceId(),
      programAgentId: getUrlProgramAgentId(fields[LoginFieldsKeys.ProgramAgentId]),
    });
    hideLoading('login');

    if (!res) return;

    navigateToRedirect({
      accessToken: res.accessToken,
      refreshToken: res.refreshToken,
      features: res.features,
    });
  };

  return (
    <PageWrapper onSubmit={onSubmit}>
      <div className="login-field">
        <LoginInput
          label={t('enterPassword.password')}
          value={fields[LoginFieldsKeys.Password]}
          onChange={(e) =>
            setFields((fields) => ({ ...fields, [LoginFieldsKeys.Password]: e.target.value }))
          }
          iconSrc={passwordIconSrc}
          type={'password'}
          autoComplete="current-password"
        />
        <div className="forgot-password-btn-container">
          <ForgotPasswordBtn onClick={() => setPage(PageKeys.ForgotPassword)} />
        </div>
      </div>
    </PageWrapper>
  );
};

export default EnterPassword;
