export enum TableColumnKey {
  ProgramName = 'programName',
  ProgramDisplayName = 'programDisplayName',
  ProgramCurrency = 'programCurrency',
  QuestionType = 'questionType',
  KycIdvMethod = 'kycIdvMethod',
  CreationTime = 'creationTime',
  LastModifiedTime = 'lastModifiedTime',
}

export interface TableRow {
  id: number;
  [TableColumnKey.ProgramName]: string;
  [TableColumnKey.ProgramCurrency]: string;
  [TableColumnKey.ProgramDisplayName]: string;
  [TableColumnKey.QuestionType]: string;
  [TableColumnKey.KycIdvMethod]: string;
  [TableColumnKey.CreationTime]: string;
  [TableColumnKey.LastModifiedTime]: string | undefined;

  rawData: any;
}
