import { ReactNode } from 'react';

import { Box } from '../../../components/MuiGenerals';
import { customSx } from '../../../utils/styling';

const FilterActions = ({ children }: { children: ReactNode }) => {
  return <Box sx={customSx.filterB}>{children}</Box>;
};

export default FilterActions;
