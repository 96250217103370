import { useLayoutEffect, useState } from 'react';

import { EnumMerchantExchangeRateAdjustType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import APIs from '../../../../../../api';
import { DialogInOne } from '../../../../../../components';
import { IdialogInOneProps } from '../../../../../../components/DialogInOne';
import { useAlerting, useTranslation } from '../../../../../../hooks';
import { safeAdd } from '../../../../../../utils/math';
import { getAdjustedExchangeRate } from '../../../helpers/getAdjustedExchangeRate';
import useExchangeRateConfigEditRecordValidation from '../../../helpers/useExchangeRateConfigEditRecordValidation';
import { TableRow } from '../../../types/TableRow';
import Content from './Content';
import { ConfigFieldsKeys } from './types';

interface IProps {
  isOpen: boolean;
  selectedRow: TableRow;
  closeDialog: () => void;
  refreshTable: () => void;
}

const editConfigApiFunc = APIs.setting.exchangeRateConfiguration.postEdit;

const initFields = {
  adjustmentType: '',
  adjustmentAmount: '',
};

const EditConfigDialog = (props: IProps) => {
  const { isOpen, selectedRow, closeDialog, refreshTable } = props;

  const [configFields, setConfigFields] = useState(initFields);

  const defaultAdjustmentType = selectedRow?.rawData?.adjustType
    ? String(selectedRow?.rawData?.adjustType)
    : '';

  useLayoutEffect(() => {
    setConfigFields({
      [ConfigFieldsKeys.AdjustmentType]: defaultAdjustmentType,
      [ConfigFieldsKeys.AdjustmentAmount]: selectedRow?.rawData?.adjustAmount || '',
    });
  }, [selectedRow]);

  const { tc, translate } = useTranslation('exchangeRateConfiguration');
  const { alerting } = useAlerting();
  const { validate } = useExchangeRateConfigEditRecordValidation({ fields: configFields });

  const adjustedExchangeRate = getAdjustedExchangeRate({
    exchangeRateOfCurrentDate: selectedRow?.rawData?.exchangeRate,
    type: Number(configFields[ConfigFieldsKeys.AdjustmentType]),
    adjustAmount: configFields[ConfigFieldsKeys.AdjustmentAmount],
  });

  const handleCloseDialog = () => {
    closeDialog();
  };

  const getIsNoChange = () => {
    const isNoChange =
      configFields[ConfigFieldsKeys.AdjustmentType] === defaultAdjustmentType &&
      configFields[ConfigFieldsKeys.AdjustmentAmount] === selectedRow.rawData.adjustAmount;

    return isNoChange;
  };

  const getIsAdjustedExchangeRateValid = () => {
    const exchangeRateOfCurrentDate = selectedRow?.rawData?.exchangeRate;

    const isAbsoluteAdjustment =
      Number(configFields[ConfigFieldsKeys.AdjustmentType]) ===
      EnumMerchantExchangeRateAdjustType.AbsoluteValue;

    if (!isAbsoluteAdjustment) return true;

    const adjustedExchangeRate = safeAdd(
      exchangeRateOfCurrentDate,
      configFields[ConfigFieldsKeys.AdjustmentAmount]
    );

    const isAdjustedExchangeRateValid = Number(adjustedExchangeRate) > 0;

    return isAdjustedExchangeRateValid;
  };

  const checkIsCanProceed = () => {
    const isNoChange = getIsNoChange();
    const { allErrors } = validate();

    const isAdjustedExchangeRateValid = getIsAdjustedExchangeRateValid();

    if (isNoChange) {
      alerting('warning', translate('exchangeRateConfiguration.editDialog.noChangeAlert'));
      return false;
    }

    if (allErrors.length > 0) {
      alerting('warning', allErrors[0]);
      return false;
    }

    if (!isAdjustedExchangeRateValid) {
      alerting(
        'warning',
        translate('exchangeRateConfiguration.createRecordDialog.negativeAdjustedExchangeRateAlert')
      );
      return false;
    }

    return true;
  };

  const onConfirm = async () => {
    const isCanProceed = checkIsCanProceed();

    if (!isCanProceed) return;

    // call api
    const res = await editConfigApiFunc({
      fromCurrency: selectedRow?.rawData?.fromCurrency,
      toCurrency: selectedRow?.rawData?.toCurrency,
      adjustType: Number(configFields.adjustmentType),
      adjustAmount: configFields.adjustmentAmount,
      programName: selectedRow?.rawData?.programName,
    });

    if (!res) return;

    // // refresh table when success + alert success
    alerting('success', translate('exchangeRateConfiguration.editDialog.successAlert'));
    handleCloseDialog();
    refreshTable();
  };

  const dialogConfig: IdialogInOneProps = {
    title: tc('edit'),
    self: {
      open: isOpen,
      onClose: handleCloseDialog,
    },
    content: (
      <Content
        data={selectedRow}
        configFields={configFields}
        setConfigFields={setConfigFields}
        adjustedExchangeRate={adjustedExchangeRate}
      />
    ),
    onConfirm: () => {
      onConfirm();
    },
    onCancel: handleCloseDialog,
    size: 'sm',

    dialogActionStyling: { padding: '8px' },
  };

  return <DialogInOne {...dialogConfig} />;
};

export default EditConfigDialog;
