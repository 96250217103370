import { ChangeEvent, useCallback, useMemo } from 'react';

import { MpTextField } from '../../../../components';
import DaIdAutoCompleteFilter from '../../../../components/DaIdAutoCompleteFilter';
import { useDatePicker } from '../../../../components/DatePicker';
import FilterTemplate from '../../../../components/Layout/FilterTemplate';
import { SelectChangeEvent } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';
import {
  useFilterFields,
  useGenFilterBtnBar,
  useGenFilterFields
} from '../../../../hooks/useFilter';
import { Iprefix } from '../../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../../zustand/store';
import { IsearchParams, useZusParams } from '../hooks';
import {
  createAgentDialogTranslateKey as CreateAgentTK,
  filterTableTranslateKey as TK
} from '../mappings/translate';

const initFields = {
  distributorAgentId: '',
  distributorName: '',
};

export default function Filter() {
  const translatePrefix: Iprefix = 'distributorAgentList';

  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();

  const createAgentBtnName = t(CreateAgentTK.createAgentBtn);
  const handleClickingCreateAgentBtn = () => zusDialog.open('createRequestDialog');
  const createAgentPermission = '';

  const { filterBtnBar } = useGenFilterBtnBar([
    {
      name: createAgentBtnName,
      clickHandler: handleClickingCreateAgentBtn,
      permission: createAgentPermission,
    },
  ]);

  const DateFields = { CreationDate: useDatePicker(), LastModifiedDate: useDatePicker() };

  const { onReset, fields, setFields } = useFilterFields({
    initFields,
    dateFields: DateFields,
  });

  const zusParams = useZusParams();

  const apiParams: IsearchParams = useMemo(() => {
    const { distributorName: name, ...rest } = fields;
    const { start: createdDateFrom, end: createdDateTo } = DateFields.CreationDate;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } = DateFields.LastModifiedDate;

    return {
      ...rest,
      name,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
    };
  }, [
    fields,
    DateFields.CreationDate.start,
    DateFields.CreationDate.end,
    DateFields.LastModifiedDate.start,
    DateFields.LastModifiedDate.end,
  ]);

  const onSearch = useCallback(() => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  }, [apiParams]);

  const onChange =
    (field: keyof typeof fields) =>
    (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFields((f) => ({ ...f, [field]: e.target.value }));
    };

  const { filterFields } = useGenFilterFields(
    [
      { label: t(TK.creationTime), element: <DateFields.CreationDate.Picker type={'dateTime'} /> },
      {
        label: t(TK.lastModifiedTime),
        element: <DateFields.LastModifiedDate.Picker type={'dateTime'} />,
      },
      {
        label: t(TK.distributorAgentId),
        element: (
          <DaIdAutoCompleteFilter
            value={fields.distributorAgentId}
            onChange={(value) => setFields((f) => ({ ...f, distributorAgentId: value }))}
            onClear={() => setFields((f) => ({ ...f, distributorAgentId: '' }))}
          />
        ),
      },
      {
        label: t(TK.distributorName),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.distributorName) })}
            value={fields.distributorName}
            onChange={onChange('distributorName')}
          />
        ),
      },
    ],
    [fields, DateFields]
  );

  return (
    <FilterTemplate
      filterBtnBar={filterBtnBar}
      filterFields={filterFields}
      onSearch={onSearch}
      onReset={onReset}
    />
  );
}
