import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import APIs from '../../../../api';
import ButtonMenu from '../../../../components/Button/ButtonMenu';
import TableTemplate from '../../../../components/Layout/TableTemplate';
import { Box } from '../../../../components/MuiGenerals';
import { usePermission } from '../../../../hooks';
import { useGenTableColumns } from '../../../../hooks/useTable';
import useTranslation, { Iprefix } from '../../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../../zustand/store';
import { useListMapping, useZusParams } from '../hooks';
import {
  assignProgramDialogTranslateKey as AssignProgramTK,
  filterTableTranslateKey as TableTK,
  programNameAndEditDialogTranslateKey as EditTK,
} from '../mappings/translate';

const api = {
  table: APIs.setting.distributorAgentList.getAll,
  export: APIs.setting.distributorAgentList.getExport,
};
export default function Table() {
  const { hasPermission } = usePermission();
  const translatePrefix: Iprefix = 'distributorAgentList';
  const exportFileName = 'Distributor Agent List';
  const exportPermissionKey = AgentPortalFeatureCode.Setting.DistributorAgentList.Export;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const { t, tc } = useTranslation(translatePrefix);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const tableMapping = useListMapping('table');
  const exportMapping = useListMapping('export');

  const omitKeys: string[] = [];

  const content: GridRowsProp = tableMapping(rows, omitKeys);

  const renderOperationCell = useCallback(() => {
    return (
      <ButtonMenu
        title={t(TableTK.operation)}
        options={[
          {
            name: t(AssignProgramTK.assignProgramBtn),
            onClickFn: () => zusDialog.open('operationDialog'),
          },
          {
            name: t(EditTK.editBtn),
            onClickFn: () => zusDialog.open('editRequestDialog'),
          },
        ]}
      />
    );
  }, []);
  const renderProgramNameCell = useCallback((params: GridRenderCellParams<any, any, any>) => {
    const programName = params.row[TableTK.programName];

    return (
      <Box>
        <Box>{programName}</Box>
      </Box>
    );
  }, []);

  const { columns } = useGenTableColumns([
    // { translateKey: TableTK.operation, renderCell: renderOperationCell },
    { translateKey: TableTK.distributorAgentId, minWidth: 170 },
    { translateKey: TableTK.distributorName },
    // { translateKey: TableTK.programName, renderCell: renderProgramNameCell },
    { translateKey: TableTK.createdBy },
    { translateKey: TableTK.creationTime },
    { translateKey: TableTK.lastModifiedBy },
    { translateKey: TableTK.lastModifiedTime },
  ]);

  return (
    <TableTemplate
      exportBtn={{
        fileName: exportFileName,
        permissionKey: exportPermissionKey,
        apiFn: api.export,
        omitKeys: ['rawData'],
        mappingFn: exportMapping,
      }}
      zusParams={zusParams}
      count={count}
      content={content}
      columns={columns}
    />
  );
}
