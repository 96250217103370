import React from 'react';
import { useAppSelector } from '../../../../../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../../../../../reducer/mediaSlice';

const FeeSettingCard = ({ title, value }: { title: string; value: string }) => {
  const isMobile = useAppSelector(selectIsMobileView);

  return (
    <div
      style={{
        width: '25%',
        padding: isMobile ? '12px 16px' : '24px 32px',
        borderRight: '1px solid #485370',
        ...(isMobile && {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }),
      }}
    >
      <div>
        <div style={{ marginBottom: '6px', color: '#ADB7C3' }}>{title}</div>
        <div>{value}</div>
      </div>
    </div>
  );
};

export default FeeSettingCard;
