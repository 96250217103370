import { ApiResult } from '../../../../api/types';
import { getIdvMethodDisplayValue } from '../../../../helper/displayValue/getIdvMethodDisplayValue';
import { getQuestionTypeDisplayValue } from '../../../../helper/displayValue/getQuestionTypeDisplayValue';
import { useTranslation } from '../../../../hooks';
import { toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey, TableRow } from '../types/Table';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { te, tb, tc, translate } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      return {
        id: index,
        [TableColumnKey.ProgramName]: row.programName,
        [TableColumnKey.ProgramDisplayName]: row.programDisplayName,
        [TableColumnKey.ProgramCurrency]: row.programCurrency,
        [TableColumnKey.QuestionType]: getQuestionTypeDisplayValue(row.questionType, translate),
        [TableColumnKey.KycIdvMethod]: getIdvMethodDisplayValue(row.kycIdvMethod, translate),
        [TableColumnKey.CreationTime]: toDisplayTime(row.createdDate),
        [TableColumnKey.LastModifiedTime]: toDisplayTime(row.lastModifiedDate),

        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
