import { ApiResult } from '../../../../../../api/types';
import { useTranslation } from '../../../../../../hooks';
import useProgramDisplayName from '../../../../../../hooks/useProgramDisplayName';
import useSystemCurrency from '../../../../../../hooks/useSystemCurrency';
import { displayAmountCurrying, toDisplayTime } from '../../../../../../utils';
import { padZero } from '../../../../../../utils/math';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey, TableRow } from '../types/Table';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { te, tb, tc } = useTranslation();

  const { getProgramDisplayName } = useProgramDisplayName();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      // const decimals = CurrencyDecimalsMapping[row.currency] || 0;
      // const displayAmount = displayAmountCurrying(decimals);
      const programDisplayName = getProgramDisplayName(row.programName);

      return {
        id: index,
        // [TableColumnKey.Month]: row.month ? toDisplayTime(row.month, 'yyyy-MM-dd') : '',
        [TableColumnKey.Month]: row.month || '',
        [TableColumnKey.ProgramName]: programDisplayName || '',
        [TableColumnKey.Repayment]: padZero(row.repayment),
        [TableColumnKey.TotalTransactionAmount]: padZero(row.totalTransactionAmount),
        [TableColumnKey.Payment]: padZero(row.spending),
        [TableColumnKey.PaMarkup]: padZero(row.paTransactionCharges),
        [TableColumnKey.AnnualFee]: padZero(row.annualFee),
        [TableColumnKey.VisaRebate]: padZero(row.totalVisaRebate),
        [TableColumnKey.PaRebate]: padZero(row.paRebate),
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
