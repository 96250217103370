import React from 'react';

import { EnumAgentType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

import APIs from '../../../../../api';
import { PartialSearchDistributorAgentIdResponse } from '../../../../../api/types';
import {
  Container,
  Row,
  RowContent,
  RowHeader
} from '../../../../../common/dialogs/layout/contentLayout';
import AgentLoginEmailTextField from '../../../../../common/filterTable/components/Filters/AgentLoginEmailTextField';
import AgentNameTextField from '../../../../../common/filterTable/components/Filters/AgentNameTextField';
import AgentRoleSingleSelection from '../../../../../common/filterTable/components/Filters/AgentRoleSingleSelection';
import AgentTypeSingleSelection from '../../../../../common/filterTable/components/Filters/AgentTypeSingleSelection';
import DistributorAgentIdAutoComplete from '../../../../../common/filterTable/components/Filters/DistributorAgentIdAutoComplete';
import RemarksTextFields from '../../../../../common/filterTable/components/Filters/RemarksTextFields';
import StatusRadio from '../../../../../common/filterTable/components/Filters/StatusRadio';
import { useTranslation } from '../../../../../hooks';
import useLoading from '../../../../../hooks/useLoading';
import { CreateUserFields } from './types';

interface IProps {
  fields: CreateUserFields;
  setFields: React.Dispatch<React.SetStateAction<CreateUserFields>>;
}

const Content = (props: IProps) => {
  const { fields, setFields } = props;

  const [distributorAgentIdOptions, setDistributorAgentIdOptions] = React.useState<
    PartialSearchDistributorAgentIdResponse[]
  >([]);
  const [autoCompleteKey, setAutoCompleteKey] = React.useState(0);

  const { t } = useTranslation('userSetting');
  const { showLoading, hideLoading } = useLoading();

  const updateField = (fieldName: keyof CreateUserFields, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const handleDistributorAgentIdInputChange = async (value: string) => {
    setFields((fields) => ({ ...fields, distributorAgentId: value }));

    if (value) {
      // showLoading('searchDistributorAgentId');
      const res = await APIs.setting.userSetting.partialSearchDistributorAgentId({
        keywords: value.toUpperCase(),
        limit: 10,
        offset: 0,
      });
      // hideLoading('searchDistributorAgentId');

      if (!res) return;

      setDistributorAgentIdOptions(res);
    }
  };

  const handleDistributorAgentIdOptionChange = (value: string) => {
    setFields((fields) => ({ ...fields, distributorAgentId: value }));
  };

  const resetDistributorAgentId = () => {
    updateField('distributorAgentId', '');
    setAutoCompleteKey((prevKey) => prevKey + 1); // increment key to reset Autocomplete
  };

  return (
    <Container>
      <Row>
        <RowHeader>{t('agentType')}</RowHeader>
        <RowContent>
          <AgentTypeSingleSelection
            value={fields.agentType}
            onChange={(e) => {
              updateField('agentType', e.target.value);
              resetDistributorAgentId();
            }}
            onClear={() => updateField('agentType', '')}
          />
        </RowContent>
      </Row>
      <Row>
        <RowHeader>{t('distributorAgentId')}</RowHeader>
        <RowContent>
          <DistributorAgentIdAutoComplete
            key={autoCompleteKey}
            isDisabled={Number(fields.agentType) !== EnumAgentType.DistributorAgent}
            value={fields.distributorAgentId}
            options={distributorAgentIdOptions}
            onInputChange={(value: string) => {
              handleDistributorAgentIdInputChange(value);
            }}
            handleChange={(option: PartialSearchDistributorAgentIdResponse) => {
              handleDistributorAgentIdOptionChange(option?.distributorAgentId);
            }}
            setOptions={setDistributorAgentIdOptions}
          />
        </RowContent>
      </Row>
      <Row>
        <RowHeader>{t('loginEmail')}</RowHeader>
        <RowContent>
          <AgentLoginEmailTextField
            value={fields.loginEmail}
            onChange={(e) => updateField('loginEmail', e.target.value)}
          />
        </RowContent>
      </Row>
      <Row>
        <RowHeader>{t('name')}</RowHeader>
        <RowContent>
          <AgentNameTextField
            value={fields.name}
            onChange={(e) => updateField('name', e.target.value)}
          />
        </RowContent>
      </Row>
      <Row>
        <RowHeader>{t('roles')}</RowHeader>
        <RowContent>
          <AgentRoleSingleSelection
            value={fields.role}
            onChange={(e) => updateField('role', e.target.value)}
            onClear={() => updateField('role', '')}
          />
        </RowContent>
      </Row>
      <Row>
        <RowHeader>{t('status')}</RowHeader>
        <RowContent>
          <StatusRadio
            value={fields.status}
            onChange={(e) => updateField('status', e.target.value)}
          />
        </RowContent>
      </Row>
      <Row>
        <RowHeader>{t('remarks')}</RowHeader>
        <RowContent>
          <RemarksTextFields
            value={fields.remarks}
            onChange={(e) => updateField('remarks', e.target.value)}
          />
        </RowContent>
      </Row>
    </Container>
  );
};

export default Content;
