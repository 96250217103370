import React, { ReactNode, SetStateAction } from 'react';

import ResetButton from '../../../../../../common/filterTable/components/Buttons/ResetButton';
import SearchButton from '../../../../../../common/filterTable/components/Buttons/SearchButton';
import Filters from '../../../../../../common/filterTable/components/Filters/Filters';
import FilterActions from '../../../../../../common/filterTable/layout/FilterActions';
import FilterGrid from '../../../../../../common/filterTable/layout/FilterGrid';
import { useGenFilterField } from '../../../../../../utils/ComponentHelper';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import ProgramNameSingleSelection from '../../../../../../common/filterTable/components/Filters/ProgramNameSingleSelection';
import MonthRangePicker from '../../../../../../components/MonthRangePicker';
import { generateLimitedYearDateTime } from '../../../../../../utils/dateUtils';
import ReportCurrencySingleSelection from '../../../../../../common/filterTable/components/Filters/ReportCurrencySingleSelection';
import AgentProgramDisplayNameSingleSelection from '../../../../../../common/filterTable/components/Filters/AgentProgramDisplayNameSingleSelection';
import APIs from '../../../../../../api';
import { PartialSearchDistributorAgentIdResponse } from '../../../../../../api/types';
import DistributorAgentIdAutoComplete from '../../../../../../common/filterTable/components/Filters/DistributorAgentIdAutoComplete';
import PfhNumberTextFields from '../../../../../../common/filterTable/components/Filters/PfhNumberTextFields';
import { useTranslation } from '../../../../../../hooks';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj } = props;

  const [distributorAgentIdOptions, setDistributorAgentIdOptions] = React.useState<
    PartialSearchDistributorAgentIdResponse[]
  >([]);

  const { t } = useTranslation('customerSpendingReport');

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const handleDistributorAgentIdInputChange = async (value: string) => {
    setFields((fields) => ({ ...fields, distributorAgentId: value }));

    if (value) {
      const res = await APIs.setting.userSetting.partialSearchDistributorAgentId({
        keywords: value.toUpperCase(),
        limit: 10,
        offset: 0,
      });

      if (!res) return;

      setDistributorAgentIdOptions(res);
    }
  };

  const handleDistributorAgentIdOptionChange = (value: string) => {
    setFields((fields) => ({ ...fields, distributorAgentId: value }));
  };

  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  const filterFields: ReactNode[] = useGenFilterField([
    [
      'month',
      <MonthRangePicker
        startMonth={fields[FilterFieldsKeys.settlementMonthFrom]}
        endMonth={fields[FilterFieldsKeys.settlementMonthTo]}
        onChangeStartMonth={(value) => updateField(FilterFieldsKeys.settlementMonthFrom, value)}
        onChangeEndMonth={(value) => updateField(FilterFieldsKeys.settlementMonthTo, value)}
        defaultStartMonth={lastMonth}
        defaultEndMonth={lastMonth}
        minMonth={generateLimitedYearDateTime(2)}
        maxMonth={lastMonth}
      />,
    ],
    [
      FilterFieldsKeys.DistributorAgentId,
      <DistributorAgentIdAutoComplete
        value={fields.distributorAgentId}
        options={distributorAgentIdOptions}
        onInputChange={(value: string) => {
          handleDistributorAgentIdInputChange(value);
        }}
        handleChange={(option: PartialSearchDistributorAgentIdResponse) => {
          handleDistributorAgentIdOptionChange(option?.distributorAgentId);
        }}
        setOptions={setDistributorAgentIdOptions}
      />,
    ],
    [
      FilterFieldsKeys.PfhNumber,
      <PfhNumberTextFields
        label={t('monthlyReportTab.pfhNumber')}
        value={fields[FilterFieldsKeys.PfhNumber]}
        onChange={(e) => updateField(FilterFieldsKeys.PfhNumber, e.target.value)}
      />,
    ],
    [
      FilterFieldsKeys.ProgramName,
      <AgentProgramDisplayNameSingleSelection
        value={fields[FilterFieldsKeys.ProgramName]}
        onChange={(e) => updateField(FilterFieldsKeys.ProgramName, e.target.value)}
        onClear={() => updateField(FilterFieldsKeys.ProgramName, '')}
      />,
    ],
  ]);

  return (
    <>
      <FilterGrid>
        <Filters filterFields={filterFields} />
      </FilterGrid>
      <FilterActions>
        <SearchButton onSearch={onSearch} />
        <ResetButton onReset={onReset} />
      </FilterActions>
    </>
  );
};

export default FilterSection;
