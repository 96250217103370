import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';
import { IlistResult } from '../types';

const getAll = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get<any, IlistResult<any>>(
    AgentPortal.EptSetting.distributorAgentProgram.getAll,
    input
  );
};
const getExport = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get<any, IlistResult<any>>(
    AgentPortal.EptSetting.distributorAgentProgram.export,
    input
  );
};

const postAssignProgram = async (
  params: {
    distributorAgentId: string;
    programName: string;
    rebateMode: number;
    daRebateRate: string | number;
  },
  config?: any
) => {
  const input = {
    ...params,
    customerRebateRate: '0',
    ...config,
  };
  return await axios.post(AgentPortal.EptSetting.distributorAgentProgram.assignProgram, input);
};
const postEditProgram = async (
  params: {
    distributorAgentId: string;
    programName: string;
    rebateMode: number;
    customerRebateRate: string | number;
    daRebateRate: string | number;
    status: number;
  },
  config?: any
) => {
  const input = {
    ...params,
    customerRebateRate: params.customerRebateRate || '0',
    ...config,
  };
  return await axios.post(AgentPortal.EptSetting.distributorAgentProgram.editProgram, input);
};

export default { getAll, getExport, postAssignProgram, postEditProgram };
