import { useAlerting, useTranslation } from '../../../../../hooks';
import { useAppSelector } from '../../../../../reducer/hooks';
import { selectProfile } from '../../../../../reducer/profileSlice';
import { downloadFiles, getFullApiResponse } from '../../../../../utils';
import { useZusTranslatePrefixStore } from '../../../../../zustand/store';
import { convertToExportRows } from '../../../../filterTable/helpers/convertToExportRows';

interface IProps {
  fields: any;
  getListParams: (fields: any) => any;
  exportTableApiFunc: (params: any, { signal }: { signal: any }) => any;
}

const useExport = <ApiResultListType>(props: IProps) => {
  const { fields, getListParams, exportTableApiFunc } = props;

  const { alerting } = useAlerting();
  const { translatePrefix } = useZusTranslatePrefixStore();
  const { lang: selectedLang } = useAppSelector(selectProfile);
  const { t, tc } = useTranslation(translatePrefix);

  const onExport = async (fileName: string, totalCounts: number, convertToTableRows: any) => {
    const params = getListParams(fields);
    const { currentTime, ...cleanParams } = params;

    if (totalCounts === 0) {
      return alerting('error', tc('no_data_export'));
    }

    const apiFn = (page: number, pageSize: number, signal: any) =>
      exportTableApiFunc({ ...cleanParams, page, pageSize }, { signal });

    const rawRes = await getFullApiResponse(apiFn, totalCounts, true);

    if (rawRes.length === 0) return;

    const omitKeys = ['id', 'rawData'];
    const tableRows = convertToTableRows({ rows: rawRes } as ApiResultListType);
    const exportRows = convertToExportRows({
      data: tableRows,
      omitKeys,
      translatePrefix,
      selectedLang,
    });

    downloadFiles(fileName, exportRows, {});
  };

  return { onExport };
};

export default useExport;
