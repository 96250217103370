import { useTranslation } from '../hooks';
import { useGetProgramEnum } from '../hooks/useProgram';
import { SingleSelection } from './';
import { SelectChangeEvent } from './MuiGenerals';

export default function AgentProgramNameSelection({
  onChange,
  value,
  clearSelect,
  ...props
}: {
  onChange: (e: SelectChangeEvent) => void;
  value: string;
  clearSelect: () => void;
} & ({ mode: 'all' } | { mode: 'assignableOnly'; distributorAgentId: string })) {
  const { tc } = useTranslation();
  const distributorAgentId = props.mode === 'assignableOnly' ? props.distributorAgentId : undefined;
  const { EnumAllProgram, EnumAssignableProgram } = useGetProgramEnum(distributorAgentId);

  const enumData = props.mode === 'all' ? EnumAllProgram : EnumAssignableProgram;

  return (
    <SingleSelection
      label={tc('phSelection', { fieldName: tc('program_name') })}
      value={value}
      onChange={onChange}
      enumData={enumData}
      clearSelect={clearSelect}
      nameFn={(name) => name}
    />
  );
}
