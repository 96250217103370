
import { useTranslation } from '../hooks';
import { SingleSelection } from '.';
import { SelectChangeEvent } from './MuiGenerals';
import { EnumIdvMethod } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

export default function IdvMethodSelection({
  onChange,
  value,
  clearSelect,
}: {
  onChange: (e: SelectChangeEvent) => void;
  value: string;
  clearSelect: () => void;
}) {
  const { tc, t } = useTranslation('EnumIdvMethod');

  return (
    <SingleSelection
      label={tc('phSelection', { fieldName: tc('idv_method') })}
      value={value}
      onChange={onChange}
      enumData={EnumIdvMethod}
      clearSelect={clearSelect}
      nameFn={(item) => t(item)}
    />
  );
}
