import { EXPECTED_TX_AMOUNT_PER_MONTH } from '../constants/TcspExpectedTxAmountPerMonth';

export const getTcspExpectedTxAmountPerMonthDisplayValue = (
  expectedTxAmountPerMonth: string,
  translateFunc: (key: string) => string
) => {
  const value = EXPECTED_TX_AMOUNT_PER_MONTH[String(expectedTxAmountPerMonth)];

  if (!value) return '';

  return translateFunc(`TcspExpectedTxAmountPerMonth.${value}`);
};
