import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import api from '../../../api';
import { ApiResult } from '../../../api/types';
import EditConfigCell from '../../../common/filterTable/components/Cells/EditConfigCell';
import Table from '../../../common/filterTable/components/Table';
import { convertToExportRows } from '../../../common/filterTable/helpers/convertToExportRows';
import FilterContainer from '../../../common/filterTable/layout/FilterContainer';
import FilterTableLayoutContainer from '../../../common/filterTable/layout/FilterTableLayoutContainer';
import TableContainer from '../../../common/filterTable/layout/TableContainer';
import { LoadingDialog } from '../../../components';
import { ExportBtn } from '../../../components/Button';
import { DivideLine } from '../../../components/Layout';
import { useAlerting, usePermission, useTranslation } from '../../../hooks';
import useTabs from '../../../hooks/useTabs';
import { useAppSelector } from '../../../reducer/hooks';
import { selectProfile } from '../../../reducer/profileSlice';
import { downloadFiles, getFullApiResponse } from '../../../utils';
import { useZusTranslatePrefixStore } from '../../../zustand/store';
import EditConfigDialog from './components/Dialog/EditConfigDialog';
import FilterSection from './components/FilterSection';
import useGetListParams from './helpers/useGetListParams';
import useTableColumns from './helpers/useTableColumns';
import useTableRows from './helpers/useTableRows';
import { FilterFields, FilterFieldsKeys } from './types/FilterFields';
import { GetListRes } from './types/GetListRes';
import { TableRow } from './types/TableRow';

const initFields = {
  [FilterFieldsKeys.programName]: '',
  [FilterFieldsKeys.from]: undefined,
  [FilterFieldsKeys.adjustmentType]: [],
};

// use corresponding api and permission
const translatePrefix = 'exchangeRateConfiguration';

const exportBtnPermission = AgentPortalFeatureCode.Setting.ExchangeRateConfiguration.Export;
const editRecordPermission = AgentPortalFeatureCode.Setting.ExchangeRateConfiguration.EditRecord;
// const createRecordPermission =
//   AgentPortalFeatureCode.Setting.ExchangeRateConfiguration.CreateRecord;

const getTableApiFunc = api.setting.exchangeRateConfiguration.getAll;
const exportApiFunc = api.setting.exchangeRateConfiguration.getExport;

const ExchangeRateConfiguration = () => {
  const { getListParams } = useGetListParams();

  const [page, setPage] = useState(0);
  const [fields, setFields] = useState<FilterFields>(initFields);
  const [listParams, setListParams] = useState(getListParams(fields));
  const [list, setList] = useState<ApiResult<GetListRes>>({ rows: [], count: 0 });
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [isEditConfigDialogOpen, setIsEditConfigDialogOpen] = useState(false);
  const [isCreateRecordDialogOpen, setIsCreateRecordDialogOpen] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const { lang: selectedLang } = useAppSelector(selectProfile);

  const columns = useTableColumns({ renderOperationsCell });
  const { rows, totalCounts, convertToTableRows } = useTableRows({ apiList: list });

  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();
  const { t, tc } = useTranslation(translatePrefix);
  const { setTranslatePrefix } = useZusTranslatePrefixStore();
  const { Tabs } = useTabs([{ name: tc('table'), value: '' }]);

  const isShowExportBtn = hasPermission(exportBtnPermission);

  function renderOperationsCell({ row }: { row: TableRow }) {
    return (
      <EditConfigCell
        onClick={() => {
          setSelectedRow(row);
          setIsEditConfigDialogOpen(true);
        }}
        isDisabled={false}
        isHide={!hasPermission(editRecordPermission)}
      />
    );
  }

  const handleSettled = () => {
    setIsShowLoading(false);
  };

  const handleSuccess = (data: ApiResult<GetListRes>) => {
    if (!data) return;

    setList(data);
  };

  const handleQuery = () => {
    setIsShowLoading(true);
    const { currentTime, ...cleanParams } = listParams;

    return getTableApiFunc(cleanParams);
  };

  useQuery(['getExchangeRateConfiguration', listParams], handleQuery, {
    onSettled: handleSettled,
    onSuccess: handleSuccess,
  });

  const onExport = async () => {
    const params = getListParams(fields);
    const { currentTime, ...cleanParams } = params;

    if (totalCounts === 0) {
      return alerting('error', tc('no_data_export'));
    }

    const apiFn = (page: number, pageSize: number, signal: any) =>
      exportApiFunc({ ...cleanParams, page, pageSize }, { signal });

    const rawRes = await getFullApiResponse(apiFn, totalCounts, true);

    if (rawRes.length === 0) return;

    const omitKeys = ['id', 'rawData'];
    const tableRows = convertToTableRows({ rows: rawRes } as ApiResult<GetListRes>);
    const exportRows = convertToExportRows({
      data: tableRows,
      omitKeys,
      translatePrefix,
      selectedLang,
    });

    downloadFiles(`Exchange Rate Configuration`, exportRows, {});
  };

  useEffect(() => setTranslatePrefix(translatePrefix), []);

  const onPageChange = (page: number) => {
    setPage(page);
    setListParams({ ...listParams, page });
  };

  const onFilterSearch = () => {
    setPage(0);
    setListParams({
      ...getListParams(fields),
      page: 0,
      currentTime: Date.now(),
    });
  };

  const onFilterReset = () => {
    setFields(initFields);
  };

  const refreshTable = () => {
    setListParams({ ...listParams, currentTime: Date.now() });
  };

  return (
    <FilterTableLayoutContainer>
      <LoadingDialog forceOpen={isShowLoading} />

      <EditConfigDialog
        isOpen={isEditConfigDialogOpen}
        selectedRow={selectedRow}
        closeDialog={() => setIsEditConfigDialogOpen(false)}
        refreshTable={refreshTable}
      />

      {/* <CreateRecordDialog
        isOpen={isCreateRecordDialogOpen}
        closeDialog={() => setIsCreateRecordDialogOpen(false)}
        refreshTable={refreshTable}
        allRecords={list.rows}
      /> */}

      <FilterContainer>
        {/* <FilterTopActionRow>
          <FilterBarCreateRecordButton
            onClick={() => setIsCreateRecordDialogOpen(true)}
            isShow={hasPermission(createRecordPermission)}
          />
        </FilterTopActionRow> */}

        <FilterSection
          fields={fields}
          setFields={setFields}
          onSearch={onFilterSearch}
          onReset={onFilterReset}
        />
      </FilterContainer>

      <DivideLine />

      <TableContainer>
        <ExportBtn onExport={onExport} isShow={isShowExportBtn} />
        <Tabs>
          <Table
            columns={columns}
            rows={rows}
            rowCount={totalCounts}
            page={page}
            onPageChange={onPageChange}
          />
        </Tabs>
      </TableContainer>
    </FilterTableLayoutContainer>
  );
};

export default ExchangeRateConfiguration;
