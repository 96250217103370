export const cleanParams = (params: any) => {
  const cleanedParams = params;

  Object.keys(cleanedParams).forEach((key) => {
    if (cleanedParams[key] === undefined || cleanedParams[key] === '') {
      delete cleanedParams[key];
    }
  });

  return cleanedParams;
};
