import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/Table';

const useTableColumns = () => {
  const columns = [
    useGenGridCol(TableColumnKey.TaskId),
    useGenGridCol(TableColumnKey.OrderId),
    useGenGridCol(TableColumnKey.Sequence),
    useGenGridCol(TableColumnKey.Month),
    useGenGridCol(TableColumnKey.ProgramName, { width: 150 }),
    useGenGridCol(TableColumnKey.rebateType, { width: 150 }),
    useGenGridCol(TableColumnKey.From, { width: 150 }),
    useGenGridCol(TableColumnKey.To, { width: 150 }),
    useGenGridCol(TableColumnKey.Currency),
    useGenGridCol(TableColumnKey.Amount),
    useGenGridCol(TableColumnKey.RebateCurrency, { width: 150 }),
    useGenGridCol(TableColumnKey.RebateExchangeRate),
    useGenGridCol(TableColumnKey.RebateAmount),
    useGenGridCol(TableColumnKey.Status),
    useGenGridCol(TableColumnKey.CreationTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
