import {
  getBitWiseRepresentation,
  joinArrayToString,
} from '../../../../../../../../utils/bitWiseRepresentation';
import { SOURCE_OF_WEALTH } from '../constants/SourceOfWealth';

export const getSourceOfWealthDisplayValue = (
  bitWiseValue: number,
  translateFunc: (key: string) => string
) => {
  const sourceOfWealthWithTranslate = SOURCE_OF_WEALTH.map((item) => {
    return translateFunc(`tcspAccountApplication.sourceOfWealth.${item}`);
  });

  const representationArr = getBitWiseRepresentation(sourceOfWealthWithTranslate, bitWiseValue);

  return joinArrayToString(representationArr);
};
