import { TcspApplicationAnswer } from '../..';
import Column from '../../../../../../../../../common/approval/components/DetailView/Column';
import RowItem from '../../../../../../../../../common/approval/components/DetailView/RowItem';
import Section from '../../../../../../../../../common/approval/components/DetailView/Section';
import { useTranslation } from '../../../../../../../../../hooks';
import PersonPurportingToActOnColumn from './PersonPurportingToActOnColumn';

const PersonalInfo2 = ({ data }: { data: TcspApplicationAnswer }) => {
  const { t } = useTranslation('tcspAccountApplication');

  const isDefaultTrustAccountReason = data.trustAccountReason === 0;

  const trustAccountReason = isDefaultTrustAccountReason
    ? t('reasonForTrustAccount')
    : data.trustAccountReasonOthers;

  return (
    <Section>
      <Column withDivider>
        <RowItem title={t('reasonForSettingUpTrustAcc')}>{trustAccountReason}</RowItem>
      </Column>

      <PersonPurportingToActOnColumn
        isHavePersonPurportingToActOn={data.authorizedToThirdParty}
        reason={data.authorizedToThirdPartyReason}
      />
    </Section>
  );
};

export default PersonalInfo2;
