import React from 'react';
import { MpTextField, MpTextFieldNumberOnly } from '../../../../components';
import { useTranslation } from '../../../../hooks';

interface IProps {
  label?: string;
  value: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  isDisabled?: boolean;
}

const NextExchangeRateTextField = (props: IProps) => {
  const { label, value, onChange, isDisabled } = props;
  const { tc, t } = useTranslation('exchangeRate');

  return (
    <MpTextFieldNumberOnly
      label={label || tc('phInputField', { fieldName: t('nextExchangeRate') })}
      value={value}
      onChange={onChange}
      disabled={isDisabled}
      fullWidth
      showDecimalKeyboard
    />
  );
};

export default NextExchangeRateTextField;
