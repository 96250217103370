export enum TableColumnKey {
  Month = 'month',
  ProgramName = 'programName',
  DistributorAgentId = 'distributorAgentId',
  Currency = 'currency',
  DistributorAgentRebateAmount = 'distributorAgentRebateAmount',
  ClientRebateAmount = 'clientRebateAmount',
  NetDistributorAgentRebateAmount = 'netDistributorAgentRebateAmount',
}

export interface TableRow {
  id: number;
  [TableColumnKey.Month]: string;
  [TableColumnKey.ProgramName]: string;
  [TableColumnKey.DistributorAgentId]: string;
  [TableColumnKey.Currency]: string;
  [TableColumnKey.DistributorAgentRebateAmount]: string;
  [TableColumnKey.ClientRebateAmount]: string;
  [TableColumnKey.NetDistributorAgentRebateAmount]: string;
  rawData: any;
}
