import { EnumMerchantExchangeRateAdjustType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { MultipleSelection } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';

interface IProps {
  label?: string;
  value: string[];
  onChange: (e: string[]) => void;
  onClear: () => void;
}

const ExchangeRateConfigAdjustmentTypeSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { t, tc } = useTranslation('exchangeRateConfiguration');

  return (
    <MultipleSelection
      label={label || tc('phSelection', { fieldName: t('adjustmentType') })}
      value={value}
      onChange={onChange}
      clearSelect={onClear}
      enumData={EnumMerchantExchangeRateAdjustType}
    />
  );
};

export default ExchangeRateConfigAdjustmentTypeSingleSelection;
