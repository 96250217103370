import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';
import { IgetAllProgram, IgetAssignableProgram, IlistResult } from '../types';

const getAllProgram = async (config?: any) => {
  const input = {
    ...config,
  };
  return await axios.get<any, IlistResult<IgetAllProgram>>(
    AgentPortal.EptQuery.agent.programs,
    input
  );
};
const getAssignableProgram = async (
  params: { distributorProgramAgentId: string },
  config?: any
) => {
  const input = {
    params,
    ...config,
  };
  return await axios.get<any, IgetAssignableProgram>(
    AgentPortal.EptQuery.programs.assignableProgram,
    input
  );
};

export default { getAllProgram, getAssignableProgram };
