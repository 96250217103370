import AgentCurrencySingleSelection from '../../../../../common/filterTable/components/Filters/AgentCurrencySingleSelection';
import { useTranslation } from '../../../../../hooks';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

const ExchangeRateFromCurrencySingleSelection = (props: IProps) => {
  const { t, tc } = useTranslation('exchangeRateConfiguration');

  return (
    <AgentCurrencySingleSelection
      label={props.label || tc('phSelection', { fieldName: tc('fromCurrency') })}
      value={props.value}
      onChange={props.onChange}
      onClear={props.onClear}
    />
  );
};

export default ExchangeRateFromCurrencySingleSelection;
