import { EnumRebateTaskStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/AgentRebate';
import { SingleSelection } from '../../../../components';
import { useTranslation } from '../../../../hooks';

interface IProps {
    label?: string;
    value: any;
    onChange: (e: any) => void;
    onClear: () => void;
}

const RebateTaskStatusSingleSelection = (props: IProps) => {
    const { label, value, onChange, onClear } = props;
    const { tc, t } = useTranslation("EnumRebateTaskStatus");

    return (
        <SingleSelection
            label={label || tc('phSelection', { fieldName: tc('rebateTaskStatus') })}
            value={value || ''}
            onChange={onChange}
            clearSelect={onClear}
            enumData={EnumRebateTaskStatus}
            nameFn={(item) => t(item)}
        />
    );
};

export default RebateTaskStatusSingleSelection;
