import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import { IapiGetAllRes } from '../../views/Setting/DistributorAgentList/types';
import axios from '../axiosInstance';
import { IlistResult } from '../types';

const getAll = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get<any, IlistResult<IapiGetAllRes>>(
    AgentPortal.EptSetting.distributorAgent.getAll,
    input
  );
};

const getExport = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get<any, IlistResult<IapiGetAllRes>>(
    AgentPortal.EptSetting.distributorAgent.export,
    input
  );
};

const postCreateAgent = async (
  params: {
    distributorAgentId: string;
    name: string;
  },
  config?: any
) => {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(AgentPortal.EptSetting.distributorAgent.create, input);
};

export default { getAll, getExport, postCreateAgent };
