import { v4 as createUuid } from 'uuid';
import { encrypt, decrypt } from '../../../../utils/encryption/aes256';

const getDeviceId = (): string => {
  let deviceId = localStorage.getItem('deviceId');

  // If deviceId was not already exist, create and store encrypted deviceId
  if (!deviceId) {
    const uuid = createUuid();
    deviceId = encrypt(uuid);

    // Stop recursion if encryption fails
    if (!deviceId) return '';

    localStorage.setItem('deviceId', deviceId);
  }

  const decryptedDeviceId = decrypt(deviceId);

  // Retry if decryption fails
  // Handle case when CIPHER_KEY changes causing decryption fails
  if (!decryptedDeviceId) {
    localStorage.removeItem('deviceId');
    return getDeviceId();
  }

  return decryptedDeviceId;
};

export default getDeviceId;
