import {
  EnumRiskLevel,
  EnumTransactionStatus
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { IdateFilter, Ipage } from '../../../../../api/types';
import { useListMappingTransform, useTranslation } from '../../../../../hooks';
import useSystemCurrency from '../../../../../hooks/useSystemCurrency';
import { displayAmountCurrying, toDisplayTime } from '../../../../../utils';
import { initZusParams } from '../../../../../utils/config';
import { createZusInstance } from '../../../../../zustand/store';
import { filterTableTranslateKey as TK } from '../mappings/translate';
import { IapiGetAllRes } from '../types';

export interface IsearchParams extends IdateFilter {
  clientId: string;
  customerNumber: string;
  currency: string;
  fromAddress: string;
  toAddress: string;
  txHash: string;
  refNo: string;
  lastLedgerTransactionId: string;
  fromClientId: string;
  toClientId: string;
}

type ApiParam = Ipage & Partial<IsearchParams>;

const useZusParams = createZusInstance<ApiParam>(initZusParams);

const useListMapping = (mode: 'export' | 'table') => {
  const { t, tc } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);
  const { CurrencyDecimalsMapping } = useSystemCurrency();

  const listMapping = (array: IapiGetAllRes[], omitKeys: string[] = []) => {
    const res = array.map((item) => {
      const deciamls = CurrencyDecimalsMapping[item.currency] || 0;
      const displayAmount = displayAmountCurrying(deciamls);
      const mapping: Array<[string, IapiGetAllRes | string | number]> = [
        ['rawData', item],
        [TK.ledgerClientId, item.clientId],
        [TK.pfhNumber, item.customerNumber],
        [TK.currency, item.currency],
        [TK.amount, displayAmount(item.amount)],
        [TK.frozenBalance, displayAmount(item.frozenBalance)],
        [TK.postBalance, displayAmount(item.postBalance)],
        [TK.lastLedgerTransactionId, item.lastLedgerTransactionId],
        [TK.sendingAddress, item.fromAddress],
        [TK.receivingAddress, item.toAddress],
        [TK.transactionHash, item.txHash],
        [TK.referenceNumber, item.refNo],
        [TK.riskLevel, t(EnumRiskLevel[item.riskLevel])],
        [TK.transactionStatus, t(EnumTransactionStatus[item.txStatus])],
        // [TK.transactionType, t([item.txType])],
        [TK.sendingClientId, item.fromClientId],
        [TK.receivingClientId, item.toClientId],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ];

      const mappedResult = mapping.filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export { useZusParams, useListMapping };
