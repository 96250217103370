import React from 'react';
import { Card, CardContent, Skeleton, Grid, Box } from '@mui/material';

import DashboardFooter from './components/DashboardFooter';
import DashboardHeader from './components/DashboardHeader';
import { COLOR_THEME } from '../../../style/colorTheme';
import NoRecordContent from '../DashboardContent/NoRecordContent';

interface IDashboardGridProps {
  header?: React.ReactNode;
  title?: string;
  content: React.ReactNode;
  footer?: React.ReactNode;
  height?: number;
  rowSpan?: number;
  sx?: any;
  xs?: number;
  md?: number;
  isLoading?: boolean;
  isNoRecord?: boolean;
}

const DashboardGrid: React.FC<IDashboardGridProps> = ({
  header,
  title,
  content,
  sx,
  footer,
  xs,
  md,
  height,
  rowSpan = 1,
  isLoading = true,
  isNoRecord = false,
}) => {
  return (
    <Grid item xs={xs} md={md} height={height}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          borderRadius: 2,
          backgroundColor: COLOR_THEME.MUI.MuiCard.Background,
          ...sx,
        }}
      >
        {isLoading ? (
          <Skeleton variant="rounded" width={'100%'} height={50} />
        ) : header ? (
          header
        ) : (
          <DashboardHeader title={title} />
        )}
        {isLoading ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Skeleton variant="rounded" width={'90%'} height={'80%'} />
          </Box>
        ) : isNoRecord ? (
          <NoRecordContent />
        ) : (
          <CardContent
            sx={{
              flex: 2,
              paddingTop: 0.5,
              height: '100%',
            }}
          >
            {content}
          </CardContent>
        )}
        {footer ? <DashboardFooter>{footer}</DashboardFooter> : <></>}
      </Card>
    </Grid>
  );
};

export default DashboardGrid;
