import DialogInOne, { IdialogInOneProps } from '../../../../../components/DialogInOne';
import { useTranslation } from '../../../../../hooks';
import { TableRow } from '../../types/Table';
import Content from './components/Content';

type IProps = {
  isOpen: boolean;
  selectedRow: TableRow | undefined;
  closeDialog: () => void;
  refreshTable: () => void;
};

export default function ViewAssignedToDialog(props: IProps) {
  const { isOpen, selectedRow, closeDialog } = props;

  const { t, tc } = useTranslation('programList');

  const handleCloseDialog = () => {
    closeDialog();
  };

  const handleConfirm = async () => {};

  const dialogConfig: IdialogInOneProps = {
    title: t('assignedTo'),
    self: {
      open: isOpen,
      onClose: handleCloseDialog,
    },
    content: <Content selectedRow={selectedRow} />,
    isConfirmHidden: true,
    onConfirm: handleConfirm,
    onCancel: handleCloseDialog,
    size: 'md',
    dialogHeight: {
      height: '60dvh',
      maxHeight: '600px',
    },
    cancelButtonText: tc('close'),
  };

  return <DialogInOne {...dialogConfig} />;
}
