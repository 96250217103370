export enum TableColumnKey {
  Operation = 'operation',
  CreationTime = 'creationTime',
  LastModifiedTime = 'lastModifiedTime',
  // PfhNumber = 'pfhNumber',
  Currency = 'currency',
  Balance = 'balance',
  FrozenBalance = 'frozenBalance',
}

export interface TableRow {
  id: number;
  [TableColumnKey.Currency]: string;
  [TableColumnKey.Balance]: string;
  [TableColumnKey.FrozenBalance]: string;
  [TableColumnKey.CreationTime]: string;
  [TableColumnKey.LastModifiedTime]: string | undefined;

  rawData: any;
}
