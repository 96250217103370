import { useCallback, useEffect, useState } from 'react';

import { SelectChangeEvent } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';
import AgentProgramCurrencySingleSelection from '../../../../common/filterTable/components/Filters/AgentProgramCurrencySingleSelection';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const ToCurrencySingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc } = useTranslation();

  return (
    <AgentProgramCurrencySingleSelection
      label={label || tc('phSelection', { fieldName: tc('currency') })}
      value={value}
      onChange={onChange}
      onClear={onClear}
    />
  );
};

export default ToCurrencySingleSelection;
