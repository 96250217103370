import React from 'react';

const Title = ({ value }: { value: string }) => {
  return (
    <div
      style={{
        fontSize: '1.5rem',
        fontWeight: '700',
      }}
    >
      {value}
    </div>
  );
};

export default Title;
