import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';
import { IOverviewResponse } from '../types';

const get = async (params?: any, config?: any) => {
  const input = {
    params,
    ...config,
  };

  return await axios.get<any, IOverviewResponse>(
    AgentPortal.EptDashboard.dashboard.paDailyPurchase,
    input
  );
};

export default { get };
