import { DialogInOne } from '../../../../../components';
import { IdialogInOneProps } from '../../../../../components/DialogInOne';
import { GridBox } from '../../../../../components/Layout';
import { useAlerting, useTranslation } from '../../../../../hooks';
import { Iprefix } from '../../../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../../../zustand/store';
import { IapiGetAllRes } from '../../types';
import { retryDialogTranslateKey as RetryTK } from '../../mappings/translate';
import { Box } from '../../../../../components/MuiGenerals';
import { EnumCreditAllocationType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';
import { useZusParams } from '../../hooks';
import APIs from '../../../../../api';
import { displayAmountCurrying } from '../../../../../utils';

const CreditAllocationTypeBox = ({ type }: { type: number }) => {
  const { t } = useTranslation('enumConstants');
  return <Box>{t(EnumCreditAllocationType[type as number])}</Box>;
};

const RetryDialog = () => {
  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();
  const translatePrefix: Iprefix = 'creditAllocationRecord';
  const { t } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();

  const {
    id = '',
    targetTransferOrderId = '',
    type = '',
    fromWalletClientId = '',
    toWalletClientId = '',
    creditCurrency = '',
    creditAmount = '',
    creditCurrencyDecimals = 0,
  } = (zusDialog.meta as IapiGetAllRes | undefined) || {};

  const toDisplayAmount = displayAmountCurrying(creditCurrencyDecimals);

  const onConfirm = async () => {
    const res = await APIs.report.creditAllocationRecord.postRetry({
      creditAllocationId: id,
    });
    if (!res) {
      return;
    }
    await onClose();
    alerting('success', t(RetryTK.retrySuccessfully));
    zusParams.refetch();
  };

  const onClose = async () => {
    await zusDialog.close();
  };

  const labelElePairArr: [string, JSX.Element][] = [
    [RetryTK.transferOrderId, <Box>{targetTransferOrderId}</Box>],
    [RetryTK.allocationType, <CreditAllocationTypeBox type={type as number} />],
    [RetryTK.from, <Box>{fromWalletClientId}</Box>],
    [RetryTK.to, <Box>{toWalletClientId}</Box>],
    [RetryTK.creditCurrency, <Box>{creditCurrency}</Box>],
    [RetryTK.creditAmount, <Box>{toDisplayAmount(creditAmount)}</Box>],
  ];

  const dialogConfig: IdialogInOneProps = {
    title: t(RetryTK.confirmToRetryTheRecord),
    self: {
      open: zusDialog.match('retryDialog'),
      onClose: onClose,
    },
    content: <GridBox labelElePairArr={labelElePairArr} />,
    onConfirm,
    onCancel: onClose,
  };

  return <DialogInOne {...dialogConfig} />;
};

export default RetryDialog;
