import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import PermissionTab from '../../../components/Layout/PermissionTab';
import { useTranslation } from '../../../hooks';
import { Iprefix } from '../../../hooks/useTranslation';
import BalanceTab from './BalanceTab';
import { getTabTranslatePrefix } from './helper';
import HistoryTab from './HistoryTab';

export default function CustodyWallet() {
  const translatePrefix: Iprefix = 'custodyWallet';
  const { t } = useTranslation(translatePrefix);

  return (
    <PermissionTab
      contentArr={[
        { translatePrefix: getTabTranslatePrefix('balanceTab'), element: <BalanceTab /> },
        { translatePrefix: getTabTranslatePrefix('historyTab'), element: <HistoryTab /> },
      ]}
      tabsArr={[
        {
          name: t('balanceTab.title'),
          value: getTabTranslatePrefix('balanceTab'),
          permissionCode:
            AgentPortalFeatureCode.ProgramAgentManagement.CustodyWallet.BalanceTab.prefix,
        },
        {
          name: t('historyTab.title'),
          value: getTabTranslatePrefix('historyTab'),
          permissionCode:
            AgentPortalFeatureCode.ProgramAgentManagement.CustodyWallet.HistoryTab.prefix,
        },
      ]}
    />
  );
}
