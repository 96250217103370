import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';
import { ITotalCustodyAsset } from '../types';

const get = async (config?: any) => {
  const input = {
    ...config,
  };

  return await axios.get<any, ITotalCustodyAsset>(
    AgentPortal.EptDashboard.dashboard.totalCustodyAsset,
    input
  );
};

export default { get };
